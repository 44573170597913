import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';

import { User } from '@iz_sports/models/User';
import { ToastService } from '@iz_shared/services/toast';
import { RegistrationService } from '@iz_shared/views/registration/services/registration';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  public step: string = 'first';
  public user: User;
  public error: any = {};
  public isLoading = false;
  @ViewChild('modalTermsOfUse', { static: true }) private modal: any;

  constructor(
    private router: Router,
    public toast: ToastService,
    private registration: RegistrationService,
    private slimLoadingBarService: SlimLoadingBarService,
    private registrationService: RegistrationService,
  ) {}

  public ngOnInit() {
    this.user = this.registration.getUser();
    localStorage.clear();
  }

  public onChangeStep(user: User, step: string) {
    this.step = step;
    this.user = user;
  }

  public onSuccess(user: User) {
    this.user = user;

    if (this.user.terms_of_use) {
      this.error = null;
      this.slimLoadingBarService.start();
      this.isLoading = true;
      this.registration.sendRegistration().subscribe(
        () => {
          this.isLoading = false;
          this.registration.clearUser();
          this.router.navigate(['login']);
          this.toast.setMessage('Registration successful', 'success');
        },
        (err: any) => {
          this.isLoading = false;
          if (err.status === 201) {
            this.registration.clearUser();
            this.router.navigate(['login']);
            this.toast.setMessage('Registration successful', 'success');
          } else {
            this.step = 'third';
            this.error = err.error;
            this.toast.setMessage(
              Object.keys(err.error)
                .map((key) => `${key} - ${err.error[key].join('; ')}`)
                .join('\n'),
              'danger',
            );
          }
        },
        () => {
          this.slimLoadingBarService.complete();
        },
      );
    } else {
      this.step = 'third';
    }
  }

  public onTerms(shouldOpen: boolean) {
    if (shouldOpen) {
      this.modal.open();
    } else {
      this.modal.close();
    }
  }

  public ngOnDestroy(): void {
    this.registrationService.clearUser();
  }
}
