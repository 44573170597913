import { IMenuItemConfig, MenuPositions } from '@iz_shared/models/Configs';
import { MyTeamPermissionService } from '@iz_sports/pages/dashboard/services/my-team-permission';
import { UserSystemRoles } from '@iz_sports/models/User';

export const MenuConfigs: IMenuItemConfig[] = [
  {
    link: 'sports-management',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIRECTOR.toString(),
      UserSystemRoles.SUBDIRECTOR.toString(),
      UserSystemRoles.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/sports-management.svg',
      active: 'assets/images/menu/sports-management.svg',
    },
    title: 'dashboard.menu.sportsManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'email-messages',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIRECTOR.toString(),
      UserSystemRoles.SUBDIRECTOR.toString(),
      UserSystemRoles.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/email-messages.svg',
      active: 'assets/images/menu/email-messages.svg',
    },
    title: 'dashboard.menu.emailMessages',
    position: MenuPositions.TOP,
  },
  {
    link: 'reports',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIRECTOR.toString(),
      UserSystemRoles.SUBDIRECTOR.toString(),
      UserSystemRoles.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/reports.svg',
      active: 'assets/images/menu/reports.svg',
    },
    title: 'dashboard.menu.reports',
    position: MenuPositions.TOP,
  },
  {
    link: 'tier-management',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(),
      UserSystemRoles.ENTITY_ADMIN.toString()
    ],
    icon: {
      default: 'assets/images/menu/tier-management.svg',
      active: 'assets/images/menu/tier-management.svg',
    },
    title: 'dashboard.menu.tierManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'admins',
    role: [
      UserSystemRoles.SUPER_ADMIN.toString(), // TODO: remove entity admin and director from this list
      UserSystemRoles.ENTITY_ADMIN.toString(),
      UserSystemRoles.DIRECTOR.toString()
    ],
    icon: {
      default: 'assets/images/menu/admin.svg',
      active: 'assets/images/menu/admin.svg',
    },
    title: 'dashboard.menu.admin',
    position: MenuPositions.TOP,
  },
  // {
  //   link: 'teams',
  //   role: [
  //    UserSystemRoles.SUPER_ADMIN.toString(),
  //     UserSystemRoles.ENTITY_ADMIN.toString(),
  //    UserSystemRoles.DIRECTOR.toString(),
  //    UserSystemRoles.SUBDIRECTOR.toString(),
  //    UserSystemRoles.HEADUSER.toString()
  //   ],
  //   icon: {
  //     default: 'assets/images/menu/my-team.svg',
  //     active: 'assets/images/menu/my-team.svg',
  //   },
  //   title: 'dashboard.menu.team',
  //   position: MenuPositions.BOTTOM,
  //   access: {
  //     service: MyTeamPermissionService,
  //     checker: (myTeam: MyTeamPermissionService) => myTeam.checkAccess(),
  //   },
  // },
  {
    link: 'super-admin',
    role: [UserSystemRoles.SUPER_ADMIN.toString()],
    icon: {
      default: 'assets/images/menu/super-admin.svg',
      active: 'assets/images/menu/super-admin.svg',
    },
    title: 'dashboard.menu.superAdmin',
    position: MenuPositions.BOTTOM,
  },
];
