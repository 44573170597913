import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleBoxComponent } from './title-box.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TitleBoxComponent],
  exports: [TitleBoxComponent],
})
export class TitleBoxModule {}
