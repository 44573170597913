import { Injectable } from '@angular/core';
import { User } from '@iz_shared/models/User';
import { Role } from '@iz_shared/models/Role';

@Injectable()
export class PermissionService {

  constructor() {
  }

  public getUserRole(user: User, roles: Role[]) {
    if (roles) {
      const role = roles.find((r) => r.user === user.id);
      return role ? role.role : '';
    }
    return '';
  }

  public editAccess(me, user, roleInGroup, userRoleInGroup) {
    if (me.role !== 'player' && me.role !== 'manager') {
      return true;
    }
    if (me.id === user.id) {
      return true;
    }
    if (roleInGroup === 'headcoach') {
      return true;
    }
    if (roleInGroup === 'coach' && userRoleInGroup !== 'headcoach') {
      return true;
    }
    return false;
  }
}
