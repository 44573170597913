import { AfterViewInit, Directive, ElementRef, EventEmitter } from '@angular/core';

/* Vendors */
import * as Detector from 'element-resize-detector';

@Directive({
  selector: '[resize]',
  outputs: ['onResize'],
  host: {
    '(resize)': 'onResize($event)',
  },
})
export class ResizeDirective implements AfterViewInit {
  public onResize = new EventEmitter<any>();

  constructor(
    private elm: ElementRef,
  ) {}

  public ngAfterViewInit() {
    const detect = new Detector();
    detect.listenTo(this.elm.nativeElement, this.onResizer.bind(this));
  }

  public onResizer(event) {
    this.onResize.emit(event);
  }
}
