import { Component } from '@angular/core';

@Component({
  selector: 'app-table-rows',
  templateUrl: './table-rows.component.html',
  styleUrls: ['./table-rows.component.scss'],
})
export class TableRowsComponent {

  constructor() { }

}
