import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
/* Services */
import { ToastService } from '@iz_shared/services/toast';
/* Modules */
import { User } from '@iz_sports/models/User';
import { ForgotService } from '@iz_shared/views/login/services/forgot';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss'],
})
export class LoginForgotUsernameComponent {
  public user: User;
  public form: FormGroup;
  public email: AbstractControl;
  public submitted = false;
  public wrongEmail: string;
  public error: string = '';
  public isGetUsernameLoading: boolean = false;

  constructor(
    fb: FormBuilder,
    private forgotUsername: ForgotService,
    private router: Router,
    public toast: ToastService,
  ) {
    this.form = fb.group({
      email: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });
    this.email = this.form.controls.email;
    this.user = this.forgotUsername.getUser();
  }

  public onEditEmail() {
    this.form.controls.email.markAsPristine();
  }

  public onSubmit(values: object): void {
    this.submitted = true;

    if (this.form.valid) {
      this.isGetUsernameLoading = true;
      this.error = '';
      this.forgotUsername.sendRecoveryUsername((values as any).email).subscribe(
        () => {
          this.toast.setMessage('Please check your email and click the link to reset your password', 'success');
          this.router.navigate(['/login']);
          this.isGetUsernameLoading = false;
        },
        (err) => {
          if (err.status === 200) {
            this.toast.setMessage('Please check your email and click the link to reset your password', 'success');
            this.router.navigate(['/login']);
          } else {
            this.form.controls.email.setErrors({
              invalidEmail: true,
            });
            this.error = err.error.email.join('');
            this.toast.setMessage(this.error, 'danger');
          }
          this.isGetUsernameLoading = false;
        },
      );
    } else {
      this.error = 'Email address is incorrect';
      this.isGetUsernameLoading = false;
    }
  }
}
