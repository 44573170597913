import { NgModule } from '@angular/core';
import { IzsSharedComponentsModule } from '@iz_sports/common/components';
import { IzsSharedDirectivesModule } from '@iz_sports/common/directives';

@NgModule({
  imports: [IzsSharedComponentsModule, IzsSharedDirectivesModule],
  declarations: [],
  providers: [],
  exports: [IzsSharedComponentsModule, IzsSharedDirectivesModule],
})
export class IzsCommonModule {}
