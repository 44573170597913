import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateModule } from '@ngx-translate/core';

import { TitleComponent } from './title';
import { FieldsComponent } from './fields';
import { ExpandableComponent } from './expandable';
import { DashboardTitleComponent } from './dashboard-title';
import { EllipsisComponentComponent } from './ellipsis-component/';
import { PieChartComponent } from './pier-chart';
import { TableModule } from './table';
import { DropdownMenuModule } from './dropdown-menu';
import { SurveyQuestionModule } from './survey-question';
import { DropdownModule } from './dropdown';
import { OldBrowserMessageComponent } from './old-browser-message';
import { PortalModule } from './portal';
import { ToastComponent } from './toast';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar-observables';
import { ButtonModule } from './button';
import { InputModule } from './input';
import { FormFieldModule } from '@iz_shared/components/form-field/form-field.module';
import { ToggleModule } from './toggle/';
import { LineupsComponent } from '@iz_shared/components/lineups/lineups.component';
import { FormDynamicContentModule } from '@iz_shared/components/form-dynamic-content';
import { LoadingModule } from '@iz_shared/components/loading';
import { SmartDropdownModule } from './smart-dropdown';
import { ButtonToggleModule } from './button-toggle';
import { CopyMoveModule } from '@iz_shared/components/copy-move-user';
import { CheckboxModule } from './checkbox';

import { CardModule } from './card';
import { TitleBoxModule } from './title-box';

@NgModule({
  imports: [
    InputModule,
    TableModule,
    FormsModule,
    CommonModule,
    ToggleModule,
    ButtonModule,
    PortalModule,
    LoadingModule,
    DropdownModule,
    FormFieldModule,
    TranslateModule,
    DropdownMenuModule,
    LazyLoadImageModule,
    AngularSvgIconModule.forRoot(),
    SurveyQuestionModule,
    SlimLoadingBarModule,
    FormDynamicContentModule,
    SmartDropdownModule,
    ButtonToggleModule,
  ],
  declarations: [
    ToastComponent,
    TitleComponent,
    FieldsComponent,
    LineupsComponent,
    PieChartComponent,
    ExpandableComponent,
    DashboardTitleComponent,
    EllipsisComponentComponent,
    OldBrowserMessageComponent,
  ],
  exports: [
    TableModule,
    InputModule,
    ToggleModule,
    PortalModule,
    ButtonModule,
    LoadingModule,
    ToastComponent,
    DropdownModule,
    TitleComponent,
    FormFieldModule,
    FieldsComponent,
    LineupsComponent,
    PieChartComponent,
    DropdownMenuModule,
    LazyLoadImageModule,
    ExpandableComponent,
    AngularSvgIconModule,
    SurveyQuestionModule,
    DashboardTitleComponent,
    FormDynamicContentModule,
    OldBrowserMessageComponent,
    EllipsisComponentComponent,
    SmartDropdownModule,
    ButtonToggleModule,
    CopyMoveModule,
    CardModule,
    TitleBoxModule,
    CheckboxModule,
  ],
})
export class IzComponentsModule {}
