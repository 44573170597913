import {Component, EventEmitter, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
/* Services */

/* Modules */
import {User} from '@iz_sports/models/User';
import { ForgotService } from '@iz_shared/views/login/services/forgot';
/* Vendor */

@Component({
  selector: 'app-forgot-password-username-step',
  templateUrl: './username-step.component.html',
  styleUrls: ['./username-step.component.scss'],
})
export class ForgotPasswordUsernameStepComponent {
  public form: FormGroup;
  public username: AbstractControl;
  public submitted = false;
  public wrongPassword: string;
  public user: User;
  public error: string = '';
  public isResetPassLoading: boolean = false;

  @Output() public onVoted = new EventEmitter<string>();

  constructor(fb: FormBuilder, private forgotPassword: ForgotService) {
    this.form = fb.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });
    this.username = this.form.controls.username;
    this.user = this.forgotPassword.getUser();
  }

  public onEditUsername() {
    this.form.controls.username.markAsPristine();
  }

  public onSubmit(values: object): void {
    this.submitted = true;
    this.error = '';
    if (this.form.valid) {
      this.isResetPassLoading = true;
      this.forgotPassword.sendRecoveryPassword((values as any).username).subscribe(() => {
        this.isResetPassLoading = false;
        this.onVoted.emit('check-step');
        }, (err) => {
        if (err.status === 200) {
          this.onVoted.emit('check-step');
        } else {
          this.form.controls.username.setErrors({
            invalidUsername: true,
          });
          this.error = err.error.detail;
        }
        this.isResetPassLoading = false;
      },
      );
    } else {
      this.error = 'The user name is required and must have a length of 6 characters';
    }
  }
}
