import { Component, EventEmitter, Input, Output } from '@angular/core';
/* Models */
import { User } from '@iz_sports/models/User';
@Component({
  selector: 'app-confirm-terms-of-service',
  templateUrl: './confirm-terms-of-service.component.html',
  styleUrls: ['./confirm-terms-of-service.component.scss'],
})
export class ConfirmTermsOfServiceComponent {
  @Input() public user: User;
  @Input() public isLoading: boolean = false;
  @Output() public onSuccess = new EventEmitter<User>();
  @Output() public onBack = new EventEmitter<User>();

  constructor() {}

  public onConfirm() {
    this.isLoading = true;
    this.user.terms_of_use = new Date().toISOString();
    this.onSuccess.emit(this.user);
    this.isLoading = false;
  }
}
