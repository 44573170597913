import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User, UserGroup } from '@iz_shared/models/User';
import { IApiRes } from '@iz_shared/models/Response';
import { Profile } from '@iz_shared/models/Profile';
import { normalizeParams } from '@iz_shared/services/utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface IUsersFilterParam {
  fullname?: string;
  survey_completed?: string;
  role?: string[] | string;
  project?: string;
  group?: number;
  entity?: number;
  order_by?: string;
  disable_pagination?: string;
  category?: number;
}

@Injectable()
export class UserApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  public getList(filter?: IUsersFilterParam) {
    return this.http.get<IApiRes<User[]>>('/api/users/', {
      params: normalizeParams({
        ...filter,
        disable_pagination: 'true',
      }),
    });
  }

  public getRelation(id1: number, id2: number) {
    return this.http
      .get(`/api/users/${id1}/get_relationship_summary/`, {
        params: {
          user: String(id2),
        },
      }).pipe(
      map((data: any) => {
        return data.summary.replace(/\n/g, '<br>');
      }));
  }

  public get(id: number) {
    return this.http.get<User>(`/api/users/${id}/`);
  }

  public getDescendantNamesWithUser(userGroupId: number): Observable<any> {
    return this.http.get(`/api/user_groups/${userGroupId}/descendant_names_with_user/`);
  }

  public create(user: User) {
    return this.http.post<User>(`/api/users/`, user);
  }

  public createUserGroups(user: UserGroup) {
    return this.http.post<User>(`/api/user_groups/`, user);
  }

  public getGroupCategories(projectName) {
    return this.http.get('/api/group_categories/', {
      params: normalizeParams({
        project: projectName,
      }),
    });
  }

  public update(id: number, user: Partial<User>) {
    return this.http.patch<User>(`/api/users/${id}/`, user);
  }

  public updateUserGroups(id: number, user: UserGroup) {
    return this.http.patch<User>(`/api/user_groups/${id}/`, user);
  }

  public topMatchesList(userID: number, groupID: number) {
    return this.http.get<User>(`/api/users/${userID}/top_matches/`, {
      params: normalizeParams({
        group: groupID,
      }),
    });
  }

  public remove(id: number) {
    return this.http.delete(`/api/users/${id}/`);
  }

  public removeUserRelationship(id: number) {
    return this.http.delete(`/api/user_groups/${id}/`);
  }

  public getRoles() {
    return this.http
      .get<{ roles: string[] }>('/api/users/get_roles/', {
        params: {
          disable_pagination: 'true',
        },
      }).pipe(
      map((data: any) => {
        return data;
      }));
  }

  public getRoleNameMap() {
    return this.http.get<{ [name: string]: string[] }>('/api/users/roles_mapping/');
  }

  public getProfile(id: string) {
    return this.http.get<Profile>(`/api/users/${id}/get_profile/?splited=true`);
  }

  public getMe() {
    return this.http.get('/api/users/me/');
  }

  public recoveryPassword(username: string) {
    return this.http.post('/api/users/password_recovery/', {
      username,
      project: 'commerce',
    });
  }

  public usernameValidation(username: string): Observable<boolean> {
    return this.http.post('/api/users/username_validation/', { username }).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          return of(false);
        }
        return throwError(error);
      }),
    );
  }

  public recoveryUsername(email): Observable<any> {
    return this.http.post('/api/users/username_recovery/', {
      email,
      project: 'commerce',
    });
  }

  public resetPassword(data): Observable<any> {
    return this.http.post('/api/users/set_password/', data);
  }

  public setGuardianAccept(data: any) {
    return this.http.post(`/api/users/guardian_acceptance/`, data);
  }

  public registration(user: any) {
    return this.http.post('/api/users/registration/', {
      ...user,
      project: 'commerce',
    });
  }
}
