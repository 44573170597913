import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {
  public name: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public setName(data) {
    this.name.next(data);
  }

  public get getName() {
    return this.name.asObservable();
  }

}
