import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(private router: Router) {
  }

  public goToHome() {
    if (window.history) {
      if (window.history.length === 1) {
        this.router.navigate(['/login']);
        return;
      }
    }
    (this.router as any).location.back();
  }
}
