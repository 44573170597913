import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { User } from '@iz_shared/models/User';
/* Vendor */
export type FilterType = 'percent' | 'number';

export interface ISetFilterEvent {
  type: FilterType;
  value: number;
}

export enum ListTypes {
  ACTIVE,
  EXCLUDED,
}

@Component({
  selector: 'iz-d-top-matches-list',
  templateUrl: './d-top-matches-list.component.html',
  styleUrls: ['./d-top-matches-list.component.scss'],
})
export class DTopMatchesListComponent implements OnInit, OnChanges {
  public active: User[] = [];
  @Input()
  public filter?: ISetFilterEvent;
  @Input()
  public excludeUsers: User[] = [];
  @Input()
  public activeUsers: User[] = [];
  @Input()
  public concat: boolean = false;
  @Input()
  public loading: boolean = false;

  @Output()
  public setFilter = new EventEmitter<ISetFilterEvent>();
  @Output()
  public onExclude = new EventEmitter();
  @Output()
  public onRestore = new EventEmitter();
  @Output()
  public onSelect = new EventEmitter();
  @Output()
  public onFilter = new EventEmitter();

  public _activeList = ListTypes.ACTIVE;
  public _listTypes = ListTypes;

  constructor() {}

  public ngOnChanges() {
    if (this._activeList === this._listTypes.EXCLUDED) {
      if (this.excludeUsers.length === 0) {
        this._activeList = this._listTypes.ACTIVE;
      }
    }
    if (this.activeUsers) {
      this.active = [...this.activeUsers];
      this.getFilterList(this.filter || {});
    }
  }

  public ngOnInit() {}

  public onChangeInput(event: any, type: FilterType) {
    if (event && event.target.value) {
      const n = Number(event.target.value);
      if (type === 'percent') {
        if (n > 100) {
          event.target.value = '100';
        }
      }
      if (n < 0) {
        event.target.value = '0';
      }

      this.filter = {
        type,
        value: Number(event.target.value),
      };
      this.setFilter.emit(this.filter);
      this.getFilterList(this.filter);
    } else {
      this.filter = {
        type: null,
        value: null,
      };
      this.setFilter.emit(this.filter);
      this.getFilterList(this.filter);
    }
  }

  public deleteUser(user) {
    this.onExclude.emit(user);
    this.getFilterList(this.filter);
  }

  public getValue(type: FilterType) {
    if (this.filter && this.filter.type === type) {
      return this.filter.value;
    }
    return void 0;
  }

  public getFilterList(filter) {
    if (this.activeUsers) {
      if (filter.type === 'number') {
        this.active = this.activeUsers.slice(0, filter.value);
      } else if (filter.type === 'percent') {
        this.active = this.activeUsers.slice(0, Math.round(this.activeUsers.length * (filter.value / 100)));
      } else if (filter.type === null) {
        this.active = [...this.activeUsers];
      }
      this.onFilter.emit(this.active);
    }
  }
}
