import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDataService } from './user-data';
import { SportsDataService } from './sports-data';
import { GroupDataService } from './group-data';
import { EmailDataService } from './email-data';

import { DashboardService } from './dashboard';
import { DashboardActivateService } from './dashboard-activate';
import { TeamLogoService } from './team-logo';
import { EntityService } from './entity';
import { FilterService } from './filter';
import { GlobalSearchService } from './global-search';

import { IzUserFormsService } from './user-forms';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    UserDataService,
    FilterService,
    TeamLogoService,
    SportsDataService,
    GroupDataService,
    EmailDataService,
    GlobalSearchService,
    DashboardService,
    DashboardActivateService,
    EntityService,
    IzUserFormsService,
  ],
})
export class IzDashboardServicesModule { }
