import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Components */
import { DropdownMenuComponent } from './dropdown-menu.component';
import { DropdownMenuItemComponent } from './components/dropdown-menu-item';
/* Modules */
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  imports: [
    CommonModule,
    NgScrollbarModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    DropdownMenuComponent,
    DropdownMenuItemComponent,
  ],
  exports: [
    DropdownMenuComponent,
    DropdownMenuItemComponent,
  ],
})
export class DropdownMenuModule {
}
