import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[loading]',
})
export class LoadingDirective implements OnChanges {
  @Input() public loading: boolean = false;
  private innerHtml: string = '';
  private loadSvg = `<svg width=20px height=20px xmlns="http://www.w3.org/2000/svg" margin-top: 1px
  viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-rolling"
  style="animation-play-state: running; animation-delay: 0s;">
  <circle cx="50" cy="50" fill="none" ng-attr-stroke="{{config.color}}"
  ng-attr-stroke-width="{{config.width}}" ng-attr-r="{{config.radius}}"
  ng-attr-stroke-dasharray="{{config.dasharray}}" stroke="#b9c8cb" stroke-width="10" r="35"
  stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(18.4615 50 50)"
  style="animation-play-state: running; animation-delay: 0s;"><animateTransform
  attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50"
  keyTimes="0;1" dur="1.3s" begin="0s" repeatCount="indefinite"
  style="animation-play-state: running; animation-delay: 0s;"></animateTransform></circle></svg>`;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnChanges() {
    if (Boolean(this.loading)) {
      this.innerHtml = this.el.nativeElement.innerHTML;
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerHTML',
        this.loadSvg,
      );
      this.renderer.setAttribute(
        this.el.nativeElement,
        'disabled',
        'true',
      );
    } else if (this.innerHtml) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.innerHtml);
      this.renderer.removeAttribute(
        this.el.nativeElement,
        'disabled',
      );
    }
  }
}
