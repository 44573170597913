import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* Main component */
import { DashboardComponent } from './dashboard.component';
/* Components */
import { MenuComponent } from './components/menu';
import { PlayerListSubMenuComponent } from './components/player-list-sub-menu';
import { PlayerBoardSubMenuComponent } from './components/player-board-sub-menu';
import { ManagerBoardSubMenuComponent } from './components/manager-board-sub-menu';
import { PermissionService } from './services/permission/permission.service';

/* Modules */
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzsCommonModule } from '@iz_sports/common';
import { NavbarModule } from './components/navbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DashboardRoutingModule } from './dashboard.routing';
import { TranslateModule } from '@ngx-translate/core';
import { MyTeamPermissionService } from './services/my-team-permission';

/* Services */
import { IzDashboardModule, IzDashboardServicesModule } from '@iz_shared/views/dashboard';

@NgModule({
  imports: [
    NavbarModule,
    CommonModule,
    IzCommonModule,
    NgScrollbarModule,
    IzsCommonModule,
    TranslateModule,
    IzDashboardModule,
    DashboardRoutingModule,
    IzDashboardServicesModule,
  ],
  declarations: [
    MenuComponent,
    DashboardComponent,
    PlayerListSubMenuComponent,
    PlayerBoardSubMenuComponent,
    ManagerBoardSubMenuComponent,
  ],
  providers: [PermissionService, MyTeamPermissionService],
})
export class DashboardModule {}
