import { Component } from '@angular/core';
import { Router } from '@angular/router';

import * as Month from './models/Month';
import { UserSystemRoles } from '@iz_sports/models/User';
import { ToastService } from '@iz_shared/services/toast';
import { ProfileService } from '@iz_shared/services/profile';
import { ParentalConsentService } from '@iz_shared/views/login/services/parental-consent';

@Component({
  selector: 'app-date-of-birth-login',
  templateUrl: './date-of-birth.component.html',
  styleUrls: ['./date-of-birth.component.scss'],
})
export class LoginDateOfBirthComponent {
  public submitted: boolean = false;
  public isContinueLoading: boolean = false;

  public month = Month.default;
  public day: number[] = [];
  public year: number[] = [];

  public date = {
    day: null,
    month: null,
    year: null,
  };

  private fullDate = new Date();
  private user: any;

  constructor(
    private router: Router,
    public toast: ToastService,
    private profile: ProfileService,
    private content: ParentalConsentService,
  ) {
    this.profile.getUser().subscribe((user) => {
      this.user = user;
    });

    const now = new Date().getFullYear();

    this.year = Array.from(new Array(150), (_, index) => {
      return now - index;
    });

    this.day = Array.from(new Array(31), (_, index) => {
      return index + 1;
    });
  }

  public onSubmit(form: any): void {
    form.control.controls.day.markAsPristine();
    form.control.controls.month.markAsPristine();
    form.control.controls.year.markAsPristine();
    const fullDate = new Date(this.fullDate);
    const date = new Date();
    if (!this.user) {
      this.router.navigate(['/login']);
      this.toast.setMessage('Please login in again', 'danger');
    }
    if (this.date.day && this.date.year && this.date.month && this.fullDate) {
      if (fullDate > date) {
        this.toast.setMessage('You cannot be born in the future', 'danger');
      } else {
        this.isContinueLoading = true;
        this.content.updateBirth(this.user.id, this.fullDate).subscribe(() => {
          const range = ((new Date() as any) - (this.fullDate as any)) / 1000 / 60 / 60 / 24 / 366;

          this.user.birth_date = this.fullDate.toISOString();
          this.profile.setUser(this.user);
          this.isContinueLoading = false;
          if (
            range < 18 &&
            !this.user.guardian_acceptance &&
            (this.user.role === UserSystemRoles.USER.toString() ||
              this.user.role === UserSystemRoles.HEADUSER.toString())
          ) {
            this.router.navigate(['/parental-consent']);
          } else {
            if (this.user.survey) {
              this.router.navigate(['/dashboard']);
            } else {
              this.router.navigate(['/dashboard/questionnaire']);
            }
          }
        });
      }
    } else {
      if (!this.date.day) {
        form.control.controls.day.setErrors({
          invalid: true,
        });
      }
      if (!this.date.month) {
        form.control.controls.month.setErrors({
          invalid: true,
        });
      }
      if (!this.date.year) {
        form.control.controls.year.setErrors({
          invalid: true,
        });
      }
    }
  }

  public setField(value: any, field: string) {
    this.date[field] = value.value || value.name;
    this.setDaysInMonth(this.date.month, this.date.year);

    // reset the day if its number is more than the number of days in selected month/year pair
    if (this.date.day && this.date.day > this.day.length) {
      this.date.day = void 0;
    }

    if (this.date.day && this.date.month && this.date.year) {
      this.fullDate = new Date(this.date.year, this.date.month - 1, this.date.day);
    }
  }

  private setDaysInMonth(month, year) {
    const days = new Date(year, month, 0).getDate();
    this.day = Array.from(new Array(days || 31), (_, index) => index + 1);
  }
}
