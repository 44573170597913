import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[onlyNumber] , onlyNumber',
})
export class OnlyNumberDirective {
  @Input() public onlyNumber?: boolean;
  @Input() public onlyInteger?: boolean = false; // use <input [onlyInteger]="true" /> to forbid decimal dot
  constructor() { }
  @HostListener('keydown', ['$event']) public onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.onlyNumber) {
      if ([46, 8, 9, 27, 13, 110].includes(e.keyCode) ||
        (e.keyCode === 190 && !this.onlyInteger) ||
        (e.keyCode === 65 && e.ctrlKey) ||
        (e.keyCode === 67 && e.ctrlKey) ||
        (e.keyCode === 88 && e.ctrlKey) ||
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        return;
      }

      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57))
        && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }
}
