import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartDropdownComponent } from './smart-dropdown.component';
import { ButtonModule } from '@iz_shared/components/button';
import { FormFieldModule } from '@iz_shared/components/form-field';
import { DropdownModule } from '@iz_shared/components/dropdown';
import { InputModule } from '@iz_shared/components/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormFieldModule,
    DropdownModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [SmartDropdownComponent],
  exports: [SmartDropdownComponent],
})
export class SmartDropdownModule {}
