import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApiRes } from '@iz_shared/models/Response';
import { ISport } from '@iz_shared/models/Sport';
import { Group } from '@iz_shared/models/Group';
import { Observable } from 'rxjs';
import { normalizeParams } from '@iz_shared/services/utils';
import { GroupFull } from '@iz_shared/models/GroupFull';

export interface IGroupListParams {
  entity?: number;
  section?: string;
}

export interface IGroupParams {
  included_fields?: string;
  report?: boolean;
}

@Injectable()
export class GroupApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  public getList(params: IGroupListParams) {
    return this.http.get('/api/groups/', {
      params: normalizeParams({
        ...params,
        disable_pagination: 'true',
      }),
    });
  }

  public get(id: number, params?: IGroupParams) {
    return this.http.get<GroupFull>(`/api/groups/${id}/`, {
      params: normalizeParams(params),
    });
  }

  public update(id: number, team: any, params?: IGroupParams) {
    return this.http.patch<Group>(`/api/groups/${id}/`, team, {
      params: normalizeParams(params),
    });
  }

  public create(data: any, params?: { entity: number, included_fields?: string }) {
    return this.http.post<Group>(`/api/groups/`, data, {
      params: normalizeParams(params),
    });
  }

  public delete(id: number) {
    return this.http.delete(`/api/groups/${id}/`);
  }

  public sports() {
    return this.http.get<IApiRes<ISport[]>>('/api/sports/', {
      params: {
        disable_pagination: 'true',
      },
    });
  }

  public report(id: number, params?: IGroupParams) {
    return this.http.get(`/api/groups/${id}/report/`, {
      params: normalizeParams(params),
    });
  }
  public updateReport(id: number, data: any, params?: IGroupParams) {
    return this.http.patch(`/api/groups/${id}/report/`, data, {
      params: normalizeParams(params),
    });
  }

  public getTemplate(id: number) {
    return this.http.get(`/api/groups/${id}/export_template/`, {
      responseType: 'blob',
    });
  }

  public uploadExcel(file: any, id): Observable<any> {
    return this.http.post(`/api/groups/${id}/mass_import/`, file);
  }

  public getExportedUsers(groupId: string): Observable<any> {
    return this.http.get(`/api/groups/${groupId}/export_users/`, {
      params: normalizeParams({include_subgroups: true}),
      responseType: 'blob',
    });
  }
}
