import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash-es/lang';
import { EntityService } from '../entity';

@Injectable()
export class FilterService<FT = any> {
  protected STORAGE_KEY = 'filter';
  protected init = false;

  protected filter$: BehaviorSubject<FT> = new BehaviorSubject<FT>(cloneDeep(this.getFromLocalStorage()));
  protected entity: EntityService = this.inject.get(EntityService);

  constructor(private inject: Injector) {
    this.entity.entityId.subscribe(() => {
      if (this.init) {
        this.clearLocalStorage();
        this.setFilter(cloneDeep(this.defaultFilter));
      }
      this.init = true;
    });
  }

  public get defaultFilter() {
    return {};
  }

  public get filter() {
    return this.filter$.asObservable();
  }

  public setFilter(filter: FT) {
    this.saveToLocalStorage(filter);
    this.filter$.next(filter);
  }

  protected saveToLocalStorage(filter: FT) {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(filter));
  }

  protected getFromLocalStorage() {
    const saveFilter = localStorage.getItem(this.STORAGE_KEY);
    if (saveFilter) {
      return JSON.parse(saveFilter);
    }
    return this.defaultFilter;
  }

  protected clearLocalStorage() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}
