import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IzDComponentsModule } from './components';

const exp = [IzDComponentsModule];

@NgModule({
  imports: [CommonModule, ...exp],
  declarations: [],
  exports: exp,
})
export class IzDashboardModule {}
