import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingService } from '@iz_shared/components/loading/service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnDestroy {
  @Input() public name: string;
  public subscriptions: Subscription[] = [];
  public url: string = 'assets/images/common/rolling-';

  constructor(private loadingService: LoadingService) {
    this.subscriptions.push(
      this.loadingService.getName.subscribe((name) => {
        this.url = this.url + name;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
