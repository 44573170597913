import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
})
export class ExpandableComponent {
  @ViewChild('plusButton', { static: true }) public plusButton: ElementRef;
  @Output() public onPlusBtn = new EventEmitter<any>();
  @Input() public url: string = '';
  @Input() public name: string = '';
  public marginTop: string;
  public overflowHidden: string;

  get isExpanded() {
    return this.expanded;
  }

  set isExpanded(value: boolean) {
    this.expanded = (value !== null && `${value}` !== 'false') ? true : false;
  }

  @Output()
  public onExpanded = new EventEmitter<boolean>();
  private expanded: boolean = false;

  constructor() {
  }

  public onClick(event) {
    if (this.plusButton.nativeElement.contains(event.target)) {
      return;
    }
    this.isExpanded = !this.isExpanded;
    this.updateMarginTop();
  }

  public addData() {
    this.onPlusBtn.emit();
  }

  private updateMarginTop() {
    if (this.isExpanded) {
      this.marginTop = '0';
      this.overflowHidden = 'hidden';
    }
    this.onExpanded.emit(this.isExpanded);
  }
}
