import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
/* Services */
import { ToastService } from '@iz_shared/services/toast';
/* Modules */
import { User } from '@iz_sports/models/User';
/* Validators */
import { ForgotService } from '@iz_shared/views/login/services/forgot';
import { EqualPasswordsValidator } from '@iz_shared/validators/equalPasswords.validator';

@Component({
  selector: 'app-forgot-password-reset-password',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class ForgotPasswordResetPasswordComponent {
  public form: FormGroup;
  public password: AbstractControl;
  public verifyPassword: AbstractControl;
  public submitted = false;
  public user: User;
  public error: string = '';
  public isPassResetContinue: boolean = false;
  private token: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private passwordReset: ForgotService,
    private activatedRoute: ActivatedRoute,
    public toast: ToastService,
  ) {
    this.form = fb.group(
      {
        password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
        verifyPassword: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      },
      { validator: EqualPasswordsValidator.validate('password', 'verifyPassword') },
    );

    this.password = this.form.controls.password;
    this.verifyPassword = this.form.controls.verifyPassword;
    this.user = this.passwordReset.getUser();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.token = params.token;
    });
  }

  public onSubmit(values: object): void {
    this.submitted = true;
    this.error = '';
    if (this.form.valid) {
      this.isPassResetContinue = true;
      this.passwordReset
        .sendResetPassword({
          token: this.token,
          new_password: (values as any).password,
        })
        .subscribe(
          () => {
            this.isPassResetContinue = false;
            this.router.navigate(['/login']);
            this.toast.setMessage('Password reset', 'success');
        },
          (err) => {
            if (err.status === 200) {
              this.router.navigate(['/login']);
              this.toast.setMessage('Password reset', 'success');
            } else {
              this.error = err.error.new_password[0];
              this.toast.setMessage(this.error, 'danger');
            }
            this.isPassResetContinue = false;
          },
        );
    } else {
      this.error = '';
    }
  }

  public getError(control: AbstractControl) {
    const errorsDict = {
      minlength: 'Minimum length of 4 characters',
      required: 'Required',
      invalidPassword: 'New Password and Repeat Password does not match',
    };
    if (Object.keys(control.errors).length === 0) {
      return [];
    }
    return Object.keys(control.errors)
      .map((key) => errorsDict[key])
      .join('; ');
  }
}
