import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
/* Services */
import { PdfApiService } from '@iz_shared/services/api/pdf-api';
import { GroupDataService } from '@iz_shared/views/dashboard/services/group-data';
import { GroupFull } from '@iz_commerce/models/GroupFull';

@Injectable()
export class IzReportsService {
  public filter$ = new BehaviorSubject({
    role: null,
    category: null,
    categoryId: null,
  });
  public filteredData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  public pairData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  private teamId: number;
  private configColors = [];

  constructor(public groupData: GroupDataService, private pdfApi: PdfApiService) {}

  public get filteredTeam() {
    return combineLatest(this.groupData.groupData$, this.filter$, () => {
      return this.filteredData$.getValue();
    });
  }

  public setFiltered(updated) {
    this.filteredData$.next(updated);
  }

  public setPair(pair) {
    this.pairData$.next(pair);
  }

  public get getPair() {
    return this.pairData$.asObservable();
  }

  public setConfig(config) {
    this.filter$ = new BehaviorSubject({
      role: config.filters.role,
      category: config.filters.category,
      categoryId: config.filters.categoryId,
    });
    this.configColors = config.colors;
  }

  public getConfigColors() {
    return this.configColors;
  }

  public getTeamId() {
    return this.teamId;
  }

  public setTeamId(id: number) {
    this.teamId = id;
  }

  public setFilter(filter) {
    this.filter$.next(filter);
  }

  public saveToPdfList() {
    const role = this.filter$.getValue().role;
    const category = this.filter$.getValue().categoryId;
    return this.pdfApi.organizationList('Organization List', {
      category,
      filter: role,
      group: this.teamId,
    });
  }

  public saveToPdfSummary() {
    const role = this.filter$.getValue().role;
    const category = this.filter$.getValue().categoryId;
    return this.pdfApi.organizationSummary('Organization Summary', {
      category,
      filter: role,
      group: this.teamId,
    });
  }
}
