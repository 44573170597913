import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IOptionalOptions {
  font?: string;
  fontSize?: string;
  fontWeight?: string;
  width?: string;
  wordBreak?: string;
}

interface IOptions {
  font: string;
  fontSize: string;
  fontWeight: string;
  width: string;
  wordBreak: string;
}

@Injectable()
export class CalculateSizeService {
  private field: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public setSizeField(size) {
    this.field.next([size]);
  }

  public getSizeFieldValue() {
    return this.field.getValue();
  }

  public createDummyElement(text: string, options: IOptions): HTMLElement {
    const element = document.createElement('div');
    const textNode = document.createTextNode(text);

    element.appendChild(textNode);

    element.style.fontFamily = options.font;
    element.style.fontSize = options.fontSize;
    element.style.fontWeight = options.fontWeight;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.left = '-999px';
    element.style.top = '-999px';
    element.style.width = options.width;
    element.style.height = 'auto';
    element.style.wordBreak = options.wordBreak;

    document.body.appendChild(element);

    return element;
  }

  public destroyElement(element: HTMLElement): void {
    element.parentNode.removeChild(element);
  }

  public calculateSize(text: string, options: IOptionalOptions = {}) {
    const cache = {};
    const cacheKey = JSON.stringify({text, options});

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    options.font = options.font || 'Times';
    options.fontSize = options.fontSize || '14px';
    options.fontWeight = options.fontWeight || 'normal';
    options.width = options.width || 'auto';
    options.wordBreak = options.wordBreak || 'normal';

    const element = this.createDummyElement(text, options as IOptions);

    const size = {
      width: element.offsetWidth,
      height: element.offsetHeight,
    };

    this.destroyElement(element);

    cache[cacheKey] = size;

    return size;
  }
}
