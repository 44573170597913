import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* Components */
import { SurveyQuestionComponent } from './survey-question.component';
import { SurveyQuestionChoiceComponent } from './components/survey-question-choice';
/* Modules */
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    SurveyQuestionComponent,
    SurveyQuestionChoiceComponent,
  ],
  exports: [SurveyQuestionComponent],
})
export class SurveyQuestionModule { }
