import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Email, NewEmail } from '@iz_shared/models/Email';
import { IApiRes } from '@iz_shared/models/Response';
import { normalizeParams } from '@iz_shared/services/utils';
import { EmailTemplate } from '@iz_shared/models/EmailTemplate';

export interface ITemplateFetchParams {
  entity?: number;
}

@Injectable()
export class EmailApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  public getHistory(id: number) {
    return this.http.get<IApiRes<Email[]>>('/api/emails/', {
      params: normalizeParams({
        group: id,
        disable_pagination: 'true',
      }),
    });
  }

  public createEmail(email: Partial<NewEmail>, sender?) {
    return this.http.post<Email>(`/api/emails/`, {
      ...email,
      sender,
    });
  }

  public resendEmail(id: number, user) {
    return this.http.put(`/api/resend-email/${id}`, user);
  }

  public getTemplates(params?: ITemplateFetchParams) {
    return this.http.get<IApiRes<EmailTemplate[]>>(`/api/email_templates/`, {
      params: normalizeParams({ ...params, disable_pagination: 'true' }),
    });
  }

  public getTemplate(id) {
    return this.http.get<EmailTemplate>(`/api/email_templates/${id}/`);
  }

  public updateTemplate(id: number, template) {
    return this.http.patch<EmailTemplate>(`/api/email_templates/${id}/`, template);
  }

  public createTemplate(template) {
    return this.http.post<EmailTemplate>(`/api/email_templates/`, template);
  }

  public deleteTemplate(id: number) {
    return this.http.delete(`/api/email_templates/${id}/`);
  }
}
