import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

export interface IFilter<T = any> {
  name: T;
  sport: T;
  season: T;
}

@Injectable()
export class DashboardService {
  private _isLoading = new BehaviorSubject<boolean>(false);

  public get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  public setLoading(state: boolean) {
    this._isLoading.next(state);
  }
}
