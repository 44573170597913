import { FieldsType } from './Fields';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, isNil } from 'lodash-es/lang';

const getValue = (field: FieldsType, model: any = {}) => {
  if (!isNil(model[field.key])) {
    return model[field.key];
  }
  if (!isNil(field.value)) {
    return field.value;
  }
  return null;
};

const getValidators = (field: FieldsType) => {
  let result = [];
  if (!field.disable_validators) {
    result = field.validators || [];
  }
  return result;
};

export interface IFormExtendItem {
  field: FieldsType;
  afterField?: string;
}

export class ModelForm {
  public static Field<T>(options: FieldsType<T>) {
    return options;
  }

  public static getFormGroup(formModel: ModelForm, model: any = {}) {
    const group: any = {};
    formModel.fields.forEach((field) => {
      group[field.key] = new FormControl(getValue(field, model), Validators.compose(getValidators(field)));
    });

    return new FormGroup(group);
  }

  public static getOrderedFields(formModel: ModelForm) {
    const fieldMaps = formModel.fields.reduce(
      (result, field) => {
        result[field.key] = field;
        return result;
      },
      {} as any,
    );
    return formModel.orders.map((key) => fieldMaps[key]).filter(Boolean) as FieldsType[];
  }

  public static setFormValue(form: FormGroup, formModel: ModelForm, values: any) {
    formModel.fields.forEach((field) => {
      form.controls[field.key].setValue(getValue(field, values));
    });
  }

  public static changeField(form: FormGroup, modelForm: ModelForm, key: string, setting: Partial<FieldsType>) {
    modelForm.fields.map((field) => {
      if (field.key !== key) {
        return field;
      }
      if (!(setting.disable_validators === undefined)) {
        if (setting.disable_validators !== field.disable_validators) {
          let validators = field.validators;
          if (setting.disable_validators === true) {
            validators = void 0;
          }
          form.setControl(field.key, new FormControl(getValue(field, form.value), Validators.compose(validators)));
        }
      }
      return Object.assign(field, setting);
    });

    return modelForm;
  }

  /**
   * Form model extension. The method is immutable (creates a new object)
   * @param {ModelForm} modelForm - form model
   * @param {FieldsType} field - added field
   * @param {string} afterField - after which field to add, if not specified, it will be added to the end
   * if null specified - to the beginning/back to top/return
   * @returns {ModelForm} - new form model
   */
  public static extend(modelForm: ModelForm, fields: IFormExtendItem[]): ModelForm {
    const newModelForm = cloneDeep(modelForm);
    fields.forEach((extendField) => ModelForm.extendByField(newModelForm, extendField.field, extendField.afterField));
    return newModelForm;
  }

  private static extendByField(modelForm: ModelForm, field: FieldsType, afterField?: string) {
    modelForm.fields.push(field);
    if (afterField) {
      const placeIndex = modelForm.orders.indexOf(afterField) + 1;
      if (placeIndex) {
        modelForm.orders = [
          ...modelForm.orders.slice(0, placeIndex),
          field.key,
          ...modelForm.orders.slice(placeIndex),
        ];
      } else {
        modelForm.orders.unshift(field.key);
      }
    } else {
      modelForm.orders.push(field.key);
    }
    return modelForm;
  }

  constructor(public fields: FieldsType[] = [], public orders: string[] = []) {
  }
}
