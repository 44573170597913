import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CommonService } from './common-service';
import { AuthenticationService } from './authentication';
import { APP_INTERCEPTORS } from './interceptors';
import { ToastService } from './toast';
import {AuthGuard} from './auth-guard';

// services from components
import { PortalModule } from '../components/portal';
import { CalculateSizeService } from '@iz_shared/services/calculate-size';
import { ProfileService } from './profile';
import { EventBusService } from './event-bus';
import { BackBoardService } from './back-board';

import { IzApiModule } from './api';
import { PermissionService } from '@iz_shared/services/permission/permission.service';
import { UserGuardService } from '@iz_shared/services/user-guard';

@NgModule({
  imports: [PortalModule.forRoot(), IzApiModule],
  providers: [
    CommonService,
    AuthenticationService,
    ToastService,
    ProfileService,
    BackBoardService,
    AuthGuard,
    EventBusService, // deprecated
    ...APP_INTERCEPTORS,
    CalculateSizeService,
    PermissionService,
    UserGuardService,
  ],
})
export class IzServicesModule {}
