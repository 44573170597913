import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { User, UserGroup } from '@iz_shared/models/User';
import { UserApiService } from '@iz_shared/services/api/user-api';
import { IUsersFilterParam } from '@iz_shared/services/api/user-api/user-api.service';
import { SportsDataService } from '@iz_shared/views/dashboard/services/sports-data';
import { Group } from '@iz_shared/models/Group';
import { EntityService } from '@iz_shared/views/dashboard/services/entity';

@Injectable()
export class UserDataService {
  protected projectName: string = 'innerzone';
  protected _userList$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  protected _userListTopMatches$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  protected _roleList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  protected _roleMap$: BehaviorSubject<{ [roleKey: string]: string[] }> = new BehaviorSubject({});
  protected categories: BehaviorSubject<object> = new BehaviorSubject<Group[]>([]);
  protected userApi: UserApiService = this.inject.get(UserApiService);
  protected sportsDataService: SportsDataService = this.inject.get(SportsDataService);

  constructor(private inject: Injector, public entityService: EntityService) {
    this.entityService.getEntityObs().subscribe((entity: any) => {
      if (entity) {
        this.projectName = entity.project;
      }
    });
  }

  public fetchUsers(filter?: IUsersFilterParam) {
    return this.userApi.getList(filter).pipe(map((data) => {
      this._userList$.next(data.results);
      return data;
    }));
  }

  public removeUser(id: number, relationship?: boolean, idLocalRemove?: number) {
    if (relationship) {
      return this.userApi.removeUserRelationship(id).pipe(map((data) => {
        return this.checkAfterRemove(data, idLocalRemove);
      }));
    } else {
      return this.userApi.remove(id).pipe(map((data) => {
        return this.checkAfterRemove(data, id);
      }));
    }
  }

  public checkAfterRemove(data, id) {
    const oldList = this._userList$.getValue();
    this._userList$.next(oldList.filter((el) => el.id !== id));
    this.sportsDataService.updatePlayersData([id], 'delete-user');
    return data;
  }

  public update(user: UserGroup, place?) {
    return this.userApi.updateUserGroups(user.id, user).pipe(tap((data) => {
      if (place) {
        this.sportsDataService.updatePlayersData(this.sportsDataService.scopeChangePlayers(data), 'update-user');
      }
      this._userList$.next(
        this._userList$.getValue().map((u) => {
          if (u.id === data.id) {
            return data;
          }
          return u;
        }),
      );
    }));
  }

  public updateUser(user: User) {
    return this.userApi.update(user.id, user).pipe(tap((data) => {
      this._userList$.next(
        this._userList$.getValue().map((u) => {
          if (u.id === data.id) {
            return data;
          }
          return u;
        }),
      );
    }));
  }

  public getTopMatchesList(userID, groupID) {
    return this.userApi.topMatchesList(userID, groupID).pipe(tap((data: any) => {
      this._userListTopMatches$.next(data.summary);
    }));
  }

  public createUser(user: User) {
    return this.userApi.create(user).pipe(tap((u) => {
      this.sportsDataService.updatePlayersData(user, 'create-user');
      this._userList$.next([...this._userList$.getValue(), u]);
    }));
  }

  public createUserGroups(user: UserGroup) {
    return this.userApi.createUserGroups(user).pipe(tap((u) => {
      this.sportsDataService.updatePlayersData(user, 'create-user');
      this._userList$.next([...this._userList$.getValue(), u]);
    }));
  }

  public requestGroupCategories() {
    return this.userApi.getGroupCategories(this.projectName).subscribe((data) => {
      this.categories.next(data);
    });
  }

  public getRoles() {
    return this.userApi.getRoles().pipe(tap((data) => {
      this._roleList$.next(data.roles);
    }));
  }

  public getRoleMaps() {
    return this.userApi.getRoleNameMap().pipe(tap((data: any) => {
      this._roleMap$.next(data);
    }));
  }

  public get users() {
    return this._userList$.asObservable();
  }

  public get categories$() {
    return this.categories.asObservable();
  }

  public getCategories() {
    return this.categories.getValue();
  }

  public get topMatchesUsers() {
    return this._userListTopMatches$.asObservable();
  }

  public get roles() {
    return this._roleList$.asObservable();
  }

  public get roleMap() {
    return this._roleMap$.asObservable();
  }

  public selectUserById(id: User['id']) {
    return this._userList$.getValue().find((u) => u.id === id);
  }
}
