import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyMixin, componentDestroyed } from '@w11k/ngx-componentdestroyed';

import { groupBy } from 'lodash-es/collection';
import { User } from '@iz_shared/models/User';
import { settings } from '../../configs/menu.configs';
import { IMenuItemConfig, MenuPositions } from '@iz_shared/models/Configs';

@Component({
  selector: 'iz-d-menu',
  templateUrl: './d-menu.component.html',
  styleUrls: ['./d-menu.component.scss'],
  host: {
    '[class.short]': 'short',
  },
})
export class DMenuComponent extends OnDestroyMixin implements OnInit, OnChanges, OnDestroy {
  @Input() public user: User = {} as any;
  @Input() public short: boolean;
  @Input() public settings = settings;
  @Output() public active = new EventEmitter<string>();
  public items: IMenuItemConfig[] = [];
  public selectedMenuItem: any;
  public menuPositions = MenuPositions;

  constructor(private router: Router, private _injector: Injector) { super(); }

  public ngOnInit() {
    this.items = this.getMenuItemsByUserRole();
    this.router.events.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
      this.selectedMenuItem = null;
      this.findAndSetActiveMenu();
    });
  }

  public ngOnChanges(changes) {
    if (changes.user && this.user && this.user.role) {
      this.selectedMenuItem = null;
      this.items = this.getMenuItemsByUserRole();
      this.findAndSetActiveMenu();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setActive(item: any) {
    this.selectedMenuItem = item;
    this.active.emit(item.link);
  }

  public trackByFn(_, item: IMenuItemConfig) {
    return item.link;
  }

  public getChecker(item: IMenuItemConfig) {
    if (item.access) {
      const service = this._injector.get(item.access.service);
      return item.access.checker(service);
    }
    return observableOf(true);
  }

  private getMenuItemsByUserRole() {
    return this.orderedByPosition(this.settings.filter((f) => f.role.includes(this.user.role))).filter(Boolean);
  }

  private orderedByPosition(list: IMenuItemConfig[]) {
    if (list && list.length > 0) {
      const groupByPosition = groupBy(list, 'position');
      if (groupByPosition[MenuPositions.BOTTOM]) {
        return [...groupByPosition[MenuPositions.TOP], ...groupByPosition[MenuPositions.BOTTOM]];
      } else {
        return [...groupByPosition[MenuPositions.TOP]];
      }
    }
    return [];
  }

  private findAndSetActiveMenu() {
    this.items.forEach((item) => {
      if (this.router.url.includes(`/${item.link}`)) {
        this.selectedMenuItem = item;
        return true;
      }
    });
  }
}
