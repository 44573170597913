import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DMenuComponent } from './d-menu/d-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { DFormModule } from './d-form';
import { DPairModule } from '@iz_shared/views/dashboard/components/d-pair';

const exp = [DMenuComponent];
@NgModule({
  imports: [
    DFormModule,
    DPairModule,
    CommonModule,
    TranslateModule,
  ],
  declarations: exp,
  exports: [
    ...exp,
    DFormModule,
    DPairModule,
  ],
})
export class IzDComponentsModule {}
