import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';

import { IEnvironment } from '../../../environment.type';
import { ENV_CONF_TOKEN } from '../../../iz-tokens.di';

import { ToastService } from '../../toast';
import { AuthenticationService } from '../../authentication';

@Injectable()
export class ErrorHandlerInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(ENV_CONF_TOKEN) private environment: IEnvironment,
    public auth: AuthenticationService,
    public toast: ToastService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      (event: HttpEvent<any>) => {
        return event;
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.auth.requestToken();
            this.toast.setMessage(err.error.detail, 'danger');
          } else if (err.status === 404) {
            this.toast.setMessage(err.error.detail, 'danger');
          } else if (err.status === 500) {
            this.toast.setMessage('internal server error', 'danger');
          } else if (err.status === 503) {
            this.toast.setMessage(err.error.detail, 'danger');
          } else {
            if (
              err.status === 400 &&
              err.error.non_field_errors &&
              err.error.non_field_errors[0] === 'Could not determine user\'s project'
            ) {
              this.toast.setMessage('Oh... You are not authorized', 'danger');
            } else {
              this.toast.setMessage(err.error.detail || err.statusText, 'danger');
            }
          }
        }
      },
    ));
  }
}
