import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

/* Models */
import { User } from '@iz_commerce/models/User';
import { GroupFull } from '@iz_commerce/models/GroupFull';
import { ILineupUpdateState } from '@iz_shared/models/Trans';

/* Vendors */
import { assign, cloneDeep, filter, find, map, merge, orderBy, remove, union } from 'lodash-es';

/* Service */
import { EntityService } from '../entity';
import { ProfileService } from '@iz_shared/services/profile';
import { FilterService } from '@iz_shared/views/dashboard/services/filter';

@Injectable()
export class SportsDataService {
  public maxUnfilterLevel$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public shadowStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public needSetHc$ = new Subject<boolean>();
  public addableUsersInField: Map<number, any> = new Map();
  protected usersMassStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected updateLineup: BehaviorSubject<ILineupUpdateState>
    = new BehaviorSubject<ILineupUpdateState>({id: null, status: false});
  protected user: any;
  protected group: any;
  protected usersGroup: any;
  protected usersGroupLocation: any;

  protected agreedState: BehaviorSubject<any[]> = new BehaviorSubject([]);
  protected relationshipWithUsersAndRolesInGroup: BehaviorSubject<any[]> = new BehaviorSubject([]);
  protected relationshipUser: BehaviorSubject<User[]> = new BehaviorSubject([]);
  protected changePlayers$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  protected changeUsers$: BehaviorSubject<GroupFull[]> = new BehaviorSubject<GroupFull[]>([]);
  protected replicaUsers$: any;

  protected entity: EntityService = this.inject.get(EntityService);
  protected filterService: FilterService = this.inject.get(FilterService);
  protected profile: ProfileService = this.inject.get(ProfileService);

  constructor(private inject: Injector) {
    this.profile.getUser().subscribe((user) => {
      this.user = user;
    });
    this.entity.getEntityObs().subscribe(() => {
      this.setPlayersSubject([]);
    });
    this.filterService.filter.subscribe((e) => {
      this.addableUsersInField.clear();
      this.setAgreedState([]);
      this.setPlayersSubject([]);
      this.setShadowState(false);
    });
  }

  public setUpdateLineup(state: ILineupUpdateState) {
    this.updateLineup.next(state);
  }

  public get getUpdateLineup() {
    return this.updateLineup.asObservable();
  }

  public setAgreedState(data) {
    this.agreedState.next(data);
  }

  public setRelationshipWithUsersAndRolesInGroup(data) {
    this.relationshipWithUsersAndRolesInGroup.next(data);
  }

  public createRelationshipUser(id) {
    this.relationshipWithUsersAndRolesInGroup.getValue().map((u) => {
      if (u.id === id) {
        this.relationshipUser.next(u);
      }
    });
  }

  public get getRelationshipUser() {
    return this.relationshipUser.asObservable();
  }

  public get getAgreedState() {
    return this.agreedState.asObservable();
  }

  public getAgreedValue() {
    return this.agreedState.getValue();
  }

  public replicaGroup(data: any) {
    this.replicaUsers$ = cloneDeep(data.users);
  }

  public getReplicaGroup() {
    return this.replicaUsers$;
  }

  public setShadowState(state) {
    this.shadowStateSubject.next(state);
  }

  public setUploadMassUsersStatus(status) {
    this.usersMassStatus.next(status);
  }

  public getUploadMassUsersStatus() {
    return this.usersMassStatus.asObservable();
  }

  public getSender() {
    return this.user.id;
  }

  public setUsersWithGroup(user, location?) {
    this.usersGroup = user;
    this.relationshipUser.next(user);
    this.usersGroupLocation = location;
  }

  public scopeChangePlayers(data) {
    const additional = {
      profile_percentages: this.usersGroup.profile_percentages,
      primarily: this.usersGroup.primarily,
      profile: this.usersGroup.profile,
      detail: this.usersGroup.detail,
      role: this.usersGroup.role,
    };
    if (this.usersGroupLocation === 'field') {
      const update = [merge(additional, data)];
      const arrResult = map(this.getPlayerSubjectValue(), (obj) => {
        return assign(
          obj,
          find(update, {
            id: obj.id,
          }),
        );
      });
      this.usersGroupLocation = 'change';
      this.setPlayersSubject(arrResult);
    }
    return merge(additional, data);
  }

  public setUsersSubject(data) {
    this.changeUsers$.next(data);
  }

  public get getUsersSubject() {
    return this.changeUsers$.asObservable();
  }

  public getUsersSubjectValue() {
    return this.changeUsers$.getValue();
  }

  public setPlayersSubject(data) {
    this.changePlayers$.next(data);
  }

  public getPlayerSubjectValue() {
    return this.changePlayers$.getValue();
  }

  public get getPlayersSubject() {
    return this.changePlayers$.asObservable();
  }

  public updatePlayersData(data, state?) {
    this.group = this.getUsersSubjectValue();
    switch (state) {
      case 'create-user':
        this.createUser(this.group, data);
        break;
      case 'update-user':
        this.updateUser(this.group, data);
        break;
      case 'delete-user':
        this.deleteUser(this.group, data);
        break;
      case 'pair-user-state':
        this.pairUserState(this.group, data);
        break;
      case 'return-pair-user':
        this.returnPairUser(this.group, data);
        break;
      case 'remove-player':
        this.removePlayer(this.group, data);
        break;
      case 'return-players':
        this.returnPlayers(this.group, data);
        break;
    }
  }

  public createUser(groupUsers, data) {
    if (groupUsers.users && Array.isArray(groupUsers.users)) {
      groupUsers.users.push(data);
      this.changeUsers$.next(groupUsers);
    }
  }

  public updateUser(groupUsers, data) {
    groupUsers.users = groupUsers.users.map((obj) => [data].find((o) => o.id === obj.id) || obj);
    this.changeUsers$.next(groupUsers);
  }

  public deleteUser(groupUsers, data) {
    const toDelete = new Set(data);
    groupUsers.users = filter(groupUsers.users, (obj) => !toDelete.has(obj.id));
    this.changeUsers$.next(groupUsers);
  }

  public clearFieldLineup() {
    this.changePlayers$.next([]);
  }

  public setReplicaGroup() {
    const group: any = this.getUsersSubjectValue();
    group.users = this.getReplicaGroup();
    this.changeUsers$.next(group);
  }

  public pairUserState(groupUsers, data) {
    const toReturn = new Set(data);
    const pair = filter(this.getReplicaGroup(), (obj) => !toReturn.has(obj.id));
    groupUsers.users = orderBy(pair, ['last_name'], ['asc']);

    const toDelete = new Set(data);
    groupUsers.users = filter(groupUsers.users, (obj) => !toDelete.has(obj.id));

    this.changeUsers$.next(groupUsers);
  }

  public returnPairUser(groupUsers, data) {
    const toReturn = new Set(data);
    const pair = filter(this.getReplicaGroup(), (obj) => toReturn.has(obj.id));
    groupUsers.users = orderBy(union(groupUsers.users, pair), ['last_name'], ['asc']);
    this.changeUsers$.next(groupUsers);
  }

  public returnPlayers(groupUsers, data) {
    groupUsers.users = orderBy(union(groupUsers.users, data), ['last_name'], ['asc']);
    this.changeUsers$.next(groupUsers);
  }

  public removePlayer(groupUsers, data) {
    const players = this.changePlayers$.getValue();
    const update = remove(players, (n) => {
      return n.id !== data.id;
    });

    this.changePlayers$.next(update);
  }

  public setMaxUnfilterLevel(level: string) {
    const currentLevel = this.maxUnfilterLevel$.getValue();
    if (level === 'division') {
      this.maxUnfilterLevel$.next('division');
    } else if (currentLevel !== 'division' && level === 'department') {
      this.maxUnfilterLevel$.next('department');
    } else if (currentLevel !== 'division' && currentLevel !== 'department' && level === 'workgroup') {
      this.maxUnfilterLevel$.next('workgroup');
    }
  }

  public get getMaxUnfilterLevel() {
    return this.maxUnfilterLevel$.asObservable();
  }

  public getMaxUnfilterLevelValue() {
    return this.maxUnfilterLevel$.getValue();
  }
}
