import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CommonService {
  public menuStateSubject: Subject<any> = new Subject<any>();

  constructor() {}

  public getMenuStatusObserver() {
    return this.menuStateSubject.asObservable();
  }

  public getColorForSports(role) {
    return role === 'player' || role === 'user' ? '#000' : '#ba0014';
  }

  public getColorForBgCommerce(role) {
    switch (role) {
      case 'applicant':
      case 'workgroupmanager':
      case 'departmentmanager':
      case 'divisionmanager':
      case 'entityadmin':
        return '#fff';
      case 'employee':
        return '#313a4b';
      default:
        return '#313a4b';
    }
  }

  public getBorderForCommerce(role) {
    switch (role) {
      case 'applicant':
        return '#91a3b0';
      case 'workgroupmanager':
      case 'departmentmanager':
      case 'divisionmanager':
      case 'entityadmin':
        return '#313a4b';
      case 'employee':
        return '#fff';
      default:
        return '#fff';
    }
  }
}
