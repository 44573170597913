import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'iz-lineups',
  templateUrl: './lineups.component.html',
  styleUrls: ['./lineups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineupsComponent {
  @ViewChild('svg', { static: true }) public svg: ElementRef;
  @Input() public data: any;
  @Input() public width: any;
  @Input() public height: any;
  @Input() public switch?: any;
  @Input() public params?: any;
}
