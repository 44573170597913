import { Component } from '@angular/core';
/* Steps */
import { ResendEmailFormStepComponent } from './form-step/form-step.component';
import { ResendEmailWaitStepComponent } from './wait-step/wait-step.component';

@Component({
  selector: 'app-resend-email-login',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss'],
  entryComponents: [ResendEmailFormStepComponent, ResendEmailWaitStepComponent],
})
export class LoginResendEmailComponent {
  public agreed: string = 'form-step';

  constructor() {}

  public onVoted(agreed: string) {
    this.agreed = agreed;
  }
}
