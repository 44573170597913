import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
/* Configure */
import { default as Menu } from './configure/UserMenu.configure';
/* Services */
import { ProfileService } from '@iz_shared/services/profile';
import { EntityService } from '@iz_shared/views/dashboard/services/entity';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnChanges {
  @Input() public user: any;
  public menu: any;
  public isMenuActive: boolean = false;
  @ViewChild('profileMenu', { static: true }) private containerElement: ElementRef;

  constructor(private router: Router, private entity: EntityService, private profile: ProfileService) {}

  public onHandleOutsideClick = (e: Event) => {
    if (
      this.containerElement.nativeElement !== e.target &&
      !this.containerElement.nativeElement.contains(e.target as any)
    ) {
      this.isMenuActive = false;
      this.addOrRemoveListener();
    }
  }

  public ngOnChanges() {
    if (this.user && this.user.role) {
      this.menu = Menu[this.user.role];
    }
  }

  public toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
    this.addOrRemoveListener();
  }

  public addOrRemoveListener() {
    if (this.isMenuActive) {
      document.addEventListener('click', this.onHandleOutsideClick);
    } else {
      document.removeEventListener('click', this.onHandleOutsideClick);
    }
  }

  public redirectTo(link: string) {
    if (link === '/login') {
      // TODO: Review the entity reset policy (we run .setEntity and .logout() oninit of the login.component too)
      this.entity.setEntity();
      this.profile.setGuard(true);
      this.isMenuActive = false;
      location.assign(link);
    } else {
      this.profile.setGuard(true);
      this.isMenuActive = false;
      this.router.navigate([link]);
    }
  }
}
