import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[izCardholder]',
})
export class CardholderDirective {

  constructor(private el: ElementRef) {}

  public get input() {
    return this.el.nativeElement as HTMLInputElement;
  }

  @HostListener('input')
  private onInput() {
    this.input.value = String(this.input.value)
      .toLocaleUpperCase()
      .replace(/[^A-Z\.\s]/ig, '')
      .replace(/\s{2,}/ig, ' ')
      .replace(/\.{2,}/ig, '.');
  }

}
