import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

class Token {
  public access_token = '';
  public refresh_token = '';

  constructor() {
  }
}

@Injectable()
export class AuthenticationService {
  private token = new Token();

  constructor(private router: Router, private inject: Injector) {
  }

  public setToken(token?: Token, time?: boolean) {
    this.token = token;
    if (token && time) {
      localStorage.setItem('access_token', JSON.stringify(token));
    } else {
      localStorage.removeItem('access_token');
    }
  }

  public getToken(): Token {
    if (this.token && !(this.token.refresh_token && this.token.access_token)) {
      this.token = JSON.parse(localStorage.getItem('access_token'));
    }

    return this.token;
  }

  public requestToken() {
    const httpClient = this.inject.get(HttpClient);

    if (this.token && this.token.access_token) {
      httpClient.post('/api/refresh', this.token).subscribe(
        (data: Token) => {
          this.setToken(data);
        },
        () => {
          localStorage.clear();
          // FIXME: on ngZone
          location.href = `${window.location.href.split('/#/')[0]}/#/login`;
          location.reload();
        },
      );
    } else {
      if (this.router.url === '/registration' || this.router.url.includes('signup-by-link')) {
        return;
      } else {
        this.router.navigate(['login']);
      }
      localStorage.clear();
    }
  }
}
