import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-title',
  templateUrl: './dashboard-title.component.html',
  styleUrls: ['./dashboard-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTitleComponent implements AfterViewInit {
  @Input() public tabs?: any = [];
  @Input() public title: string = '';
  public selectedTab: any;

  constructor(
    private router: Router,
  ) {}

  public ngAfterViewInit() {
    this.tabs.forEach((tab: any) => {
      if (this.router.url.includes(tab.link)) {
        this.selectedTab = tab;
        return true;
      }
    });
  }

  public onSelectTab(tab: any) {
    this.router.navigate([tab.link]);
  }

  public trackByFn(index, _) {
    return index;
  }
}
