import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
/* Vendors */
import { get, max } from 'lodash-es';
/* Services */
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common-service';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  @Input() public linkElement: ElementRef;
  @Input() public containerElement: ElementRef;
  @Input() public disableByDown = false;
  @Input() public width?: string = 'auto';
  @Input() public heightHistory?: string;
  @Input() public maxWidth?: string;
  @Input() public position?: string = 'right';
  @Input() public orientation?: string = 'down';
  @Input() public statusByService = false;
  @ViewChild('containerMenu') public containerMenu: ElementRef;
  public left: string;
  public rightEdge: string;
  public historyArrow: number = null;
  public padding: any;
  public bottom: string;
  public setInitClass: any;
  public bottomArrow: boolean = false;
  public bottomArrowFromField: boolean = false;
  public deviceInfo = null;
  public isActiveMenu: boolean = false;
  private isDownHandle = false;
  private statusSubscription: Subscription;

  constructor(private zone: NgZone,
              private renderer: Renderer2,
              private element: ElementRef,
              private commonService: CommonService,
              private deviceService: DeviceDetectorService) {
    this.epicFunction();
  }

  public onHandleOutsideClick = (event: MouseEvent) => {
    this.zone.run(() => {
      this.checkStateDropdown(event);
      if (
        (this.containerElement as any) !== event.target &&
        !(this.containerElement as any).contains(event.target as any)
      ) {
        this.isActiveMenu = false;
        this.addOrRemoveListener(event);
      }
    });
  }

  public onHandleOutsideWheel = (event: MouseWheelEvent) => {
    this.zone.run(() => {
      this.checkStateDropdown(event);
      if (
        (this.containerElement as any) !== event.target &&
        !(this.containerElement as any).contains(event.target as any)
      ) {
        this.isActiveMenu = false;
        this.addOrRemoveListener(event);
      }
    });
  }

  public epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo().browser;
  }

  public checkStateDropdown(event) {
    this.setInitClass = event.target as any;
    switch (event.type) {
      case 'wheel' &&
      this.setInitClass.classList.contains('resend-container') ||
      this.setInitClass.classList.contains('menu-item-container') ||
      this.setInitClass.classList.contains('resend-btn'):
        this.isActiveMenu = true;
        break;
      case 'click' && this.isActiveMenu:
        this.isActiveMenu = false;
        break;
    }
    event.stopPropagation();
  }

  public ngOnInit() {
    if (this.statusByService) {
      this.statusSubscription = this.commonService.getMenuStatusObserver().subscribe((state) => {
        this.isActiveMenu = state;
      });
    }

    if (this.linkElement) {
      this.renderer.listen(this.linkElement, 'click', this.toggle.bind(this));
      if (this.disableByDown) {
        this.renderer.listen(this.linkElement, 'mousedown', this.onDown.bind(this));
      }
    }
  }

  public ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  public onDown() {
    if (this.isActiveMenu) {
      this.isActiveMenu = false;
      this.isDownHandle = true;
    } else {
      this.isDownHandle = false;
    }
  }

  private addOrRemoveListener(event?) {
    if (event !== undefined) {
      switch (event.type) {
        case 'wheel' && this.isActiveMenu:
          document.addEventListener('wheel', this.onHandleOutsideWheel);
          break;
        case 'wheel':
          document.removeEventListener('wheel', this.onHandleOutsideWheel);
          break;
        case 'click' && this.isActiveMenu:
          document.removeEventListener('click', this.onHandleOutsideClick);
          break;
        case 'click':
          document.removeEventListener('click', this.onHandleOutsideClick);
          break;
      }
    } else {
      document.addEventListener('wheel', this.onHandleOutsideWheel);
      document.addEventListener('click', this.onHandleOutsideClick);
    }
  }

  private toggle() {
    if (this.isDownHandle) {
      return;
    }
    this.isActiveMenu = !this.isActiveMenu;
    this.bottom = '';
    this.addOrRemoveListener();
    if (this.isActiveMenu) {
      const rect = (this.linkElement as any).getClientRects()[0];
      setTimeout(() => {
        const offsetLeft = get(this.linkElement, 'offsetLeft', 0);
        const clientWidth = get(this.element, 'nativeElement.children[0].clientWidth', 0);
        const baseClientWidth = get(this.linkElement, 'clientWidth', 0);
        const baseClientHeight = get(this.linkElement, 'clientHeight', 0);
        const parentElement = get(this.element, 'nativeElement.parentNode', 0);
        if (this.deviceInfo === 'MS-Edge') {
          this.left = offsetLeft + 3 + max([baseClientWidth, 40]) - 10 - clientWidth + 'px';

          this.bottom = rect.bottom - 30 + 26 + 'px';

          if (this.position === 'center') {
            this.left = offsetLeft + baseClientWidth / 2 + 43 - 10 - clientWidth / 2 + 'px';
          }

          if (this.orientation === 'up') {
            this.bottom = rect.bottom - 30 - baseClientHeight + 'px';
          }
          if (parentElement.className === 'table-filter-btn') {
            this.bottom = 'auto';
            this.padding = '30px';
          }
        } else {
          this.left = offsetLeft + max([baseClientWidth, 43]) - clientWidth + 'px';

          this.bottom = rect.y + 26 + 'px';

          if (this.position === 'center') {
            this.left = offsetLeft + baseClientWidth / 2 + 43 - clientWidth / 2 + 'px';
          }

          if (this.orientation === 'up') {
            this.bottom = rect.y - baseClientHeight + 'px';
          }
          if (parentElement.className === 'table-filter-btn') {
            this.bottom = rect.y + 50 + 'px';
          }
          if (this.setInitClass.classList.contains('email-history-btn')) {
            this.left = offsetLeft + max([baseClientWidth]) - 22 - clientWidth + 'px';
          }
          if (parentElement.className.split(' ')[0] === 'right-state-sports') {
            this.bottom = baseClientHeight + 4 + 'px';
            this.left = 'auto';
            this.rightEdge = '-97px';
          }
          if (parentElement.className.split(' ')[0] === 'right-state-commerce') {
            this.bottom = baseClientHeight + 16 + 'px';
            this.left = 'auto';
            this.rightEdge = '-97px';
          }
        }
        const containerHeight = this.containerMenu.nativeElement.offsetHeight;
        const screenHeight = window.innerHeight - containerHeight;
        const bottom = rect.bottom - 30 + baseClientHeight;
        if (bottom > screenHeight) {
          if (parentElement.className.split(' ')[0] === 'number-state') {
            this.bottomArrowFromField = true;
          } else {
            this.bottomArrowFromField = false;
          }
          if (parentElement.className.split(' ')[0] === 'right-state-sports'
            || parentElement.className.split(' ')[0] === 'right-state-commerce') {
            this.bottom = '-170px';
          }
          if (this.setInitClass.classList.contains('ellipsis')
            || this.setInitClass.classList.contains('ellipsis__icon')) {
            this.left = offsetLeft + max([baseClientWidth, 43]) - clientWidth + 'px';
            this.bottom = rect.y - containerHeight - 10 + 'px';
          }
          if (this.setInitClass.classList.contains('email-history-btn')) {
            this.left = offsetLeft + max([baseClientWidth, 43]) - clientWidth + 'px';
            this.bottom = rect.y - containerHeight - 10 + 'px';
            this.historyArrow = 237;
          }
          this.bottomArrow = true;
        } else {
          if (this.deviceInfo === 'MS-Edge') {
            this.left = offsetLeft + 3 + max([baseClientWidth, 40]) - clientWidth + 'px';
          }
          this.bottomArrow = false;
          this.bottomArrowFromField = false;
        }
      }, 0);
    }
  }
}
