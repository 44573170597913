import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip as observableZip } from 'rxjs';
/* Models */
import { User } from '../../models/User';
/* Services */
import { AuthenticationService } from '../authentication';

@Injectable()
export class ProfileService {
  private user: User;
  private requestUser: any;
  private isSession: boolean = false;
  private activeGuardProfile: boolean = true;
  private userProfile: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private profileRole: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private request: HttpClient, private auth: AuthenticationService) {
    this.isSession = localStorage.getItem('session') === 'true';
    this.getUserRequest();
  }

  public setGuard(state) {
    this.activeGuardProfile = state;
  }

  public getGuard() {
    return this.activeGuardProfile;
  }

  public checkSession() {
    const token = this.auth.getToken();
    return !!token;
  }

  public getUserRequest() {
    this.requestUser = this.request.get('/api/users/me/');

    this.requestUser.subscribe((data) => {
      this.requestUser = null;
      this.setUser(data);
      this.setSignProfileRole(data.role);
    });
  }

  public getUser(): Observable<User> {
    return this.userProfile.asObservable();
  }

  public setSurvey(date) {
    this.user.survey = date;
  }

  public getUserProfileById(id: string) {
    return observableZip(
      this.request.get(`/api/users/${id}/get_profile/?splited=true`),
      this.request.get(`/api/users/${id}/`),
    );
  }

  public updateTermsOfUse(date) {
    return this.request.patch(`/api/users/${this.user.id}/`, {
      terms_of_use: date,
    });
  }

  public logOut() {
    this.setSession(false);
    this.setUser(void 0);
    this.profileRole.next('');
  }

  public setSession(isSession: boolean) {
    this.isSession = Boolean(isSession);
    localStorage.setItem('session', String(Boolean(isSession)));
  }

  public setUser(user?: User) {
    this.user = user;

    if (user) {
      this.userProfile.next(this.user);
    } else {
      this.setSignProfileRole('');
      this.auth.setToken(null);
      this.userProfile.next(null);
    }
  }

  public get signProfile$() {
    return this.profileRole.asObservable();
  }

  public setSignProfileRole(role) {
    return this.profileRole.next(role);
  }

  public getSignProfileRole() {
    return this.profileRole.getValue();
  }
}
