import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Router} from '@angular/router';
/* Configure */
import {default as Menu} from '../../configure/Menu.configure';
/* Models */
import {User} from '@iz_sports/models/User';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnChanges {
  @Input() public user: User;
  @Input() public short: boolean;
  @Output() public active = new EventEmitter<string>();
  public items: any;
  public superAdmin: any;
  public selectedMenuItem: any;

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      this.selectedMenuItem = null;
      [...this.items, this.superAdmin].forEach((item: any) => {
        if (this.router.url.includes(item.link)) {
          this.selectedMenuItem = item;
          return true;
        }
      });
    });
  }

  public ngOnChanges() {
    if (this.user && this.user.role) {
      this.items = Menu[this.user.role];
      this.superAdmin = Menu.superAdmin;

      this.selectedMenuItem = null;
      [...this.items, this.superAdmin].forEach((item: any) => {
        if (this.router.url.includes(item.link)) {
          this.selectedMenuItem = item;
          return true;
        }
      });
    }
  }

  public setActive(item: any) {
    this.selectedMenuItem = item;
    this.active.emit(item.link);
  }

  public trackByFn(index, _) {
    return index;
  }
}
