import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/* Components */
import { TableComponent } from './table.component';
import { DropdownMenuModule } from '../dropdown-menu';
import { TableRowComponent } from './components/table-row';
import { TableRowsComponent } from './components/table-rows';
import { TableFilterComponent } from './components/table-filter';
import { TableColumnComponent } from './components/table-column';
import { TableColumnsComponent } from './components/table-columns';

/* Scrollbar */
import { NgScrollbarModule } from 'ngx-scrollbar';

/* Modules */
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgScrollbarModule,
    DropdownMenuModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    TableComponent,
    TableRowComponent,
    TableRowsComponent,
    TableFilterComponent,
    TableColumnComponent,
    TableColumnsComponent,
  ],
  exports: [
    TableComponent,
    TableRowComponent,
    TableRowsComponent,
    TableFilterComponent,
    TableColumnComponent,
    TableColumnsComponent,
  ],
})
export class TableModule {
}
