import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'iz-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @HostBinding('attr.theme')
  @Input()
  public theme = 'default';

  @HostBinding('attr.size')
  @Input()
  public size = 'default';

  @Input()
  public name: string;

  @HostBinding('class.checked')
  @Input()
  public checked = false;

  @HostBinding('class.disabled')
  @Input()
  public disabled = false;

  @Input()
  public required = false;

  @Output()
  public change = new EventEmitter<boolean>();
  @Output()
  public checkedChange = new EventEmitter<boolean>();

  constructor(private _cd: ChangeDetectorRef) {
  }

  public onChange(event: Event) {
    event.stopPropagation();
    this.checked = !this.checked;
    this.change.emit(this.checked);
    this.checkedChange.emit(this.checked);
    this._cb(this.checked);
  }

  public writeValue(value: any) {
    if (this.checked !== !!value) {
      this._cd.markForCheck();
    }
    this.checked = !!value;
    this.change.emit(this.checked);
    this.checkedChange.emit(this.checked);
  }

  public registerOnChange(cb) {
    this._cb = cb;
  }

  public registerOnTouched(cb) {
  }

  private _cb = (value: any) => {
  }
}
