import {Injectable} from '@angular/core';
/* Vendor */
import { pick } from 'lodash-es';
import {UserApiService} from '@iz_shared/services/api/user-api';
import {EmailApiService} from '@iz_shared/services/api/email-api';

@Injectable()
export class ParentalConsentService {

  constructor(
    private userApi: UserApiService,
    private emailApi: EmailApiService) {}

  public updateParent(userId, parent) {
    const data = pick(parent, [
      'guardian_email',
      'guardian_last_name',
      'guardian_first_name',
    ]);
    return this.userApi.update(userId, data);
  }

  public updateBirth(userId, birth) {
    const data = {
      birth_date: birth.toISOString(),
    };
    return this.userApi.update(userId, data);
  }

  public updateAccess(data) {
    return this.userApi.setGuardianAccept(data);
  }

  public resendEmail(id) {
    return this.emailApi.createEmail({
      sender: id,
      receivers: [id],
      send_to_guardian: true,
    });
  }
}
