import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
/* Services */
/* Vendor */
import { merge } from 'lodash-es';
/* Models */
import { User } from '@iz_sports/models/User';
import { RegistrationService } from '@iz_shared/views/registration/services/registration';

@Component({
  selector: 'app-registration-second-step',
  templateUrl: './registration-second-step.component.html',
  styleUrls: ['./registration-second-step.component.scss'],
})
export class RegistrationSecondStepComponent {
  @Input() public user: User;
  @Output() public onNext = new EventEmitter<User>();
  @Output() public onBack = new EventEmitter<User>();
  public error: any;
  public isNextLoading: boolean = false;

  constructor(private registration: RegistrationService, private slimLoadingBarService: SlimLoadingBarService) {}

  public onSubmit(form: any) {
    if (form.valid) {
      this.isNextLoading = true;
      this.onNext.emit(merge(this.user, form.value));
      this.slimLoadingBarService.start();
    }
    this.isNextLoading = false;
  }

  public onEditEmail(form: any) {
    form.control.controls.email.markAsPristine();
  }

  public toBackStep(form: any) {
    this.onBack.emit(merge(this.user, form.value));
  }
}
