import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* Main component */
import { RegistrationComponent } from './registration.component';
/* Components */
import { RegistrationStepsComponent } from './components/registration-steps';
import { RegistrationTitleComponent } from './components/registration-title';
import { RegistrationFirstStepComponent } from './components/registration-first-step';
import { RegistrationThirdStepComponent } from './components/registration-third-step';
import { ConfirmTermsOfServiceComponent } from './components/confirm-terms-of-service';
import { RegistrationSecondStepComponent } from './components/registration-second-step';
/* Modules */
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzsCommonModule } from '@iz_sports/common';
import { TranslateModule } from '@ngx-translate/core';
import { IzRegistrationModule, IzRegistrationServicesModule } from '@iz_shared/views/registration';
import { TermsOfServiceComponent } from '@iz_sports/pages/terms-of-service';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    IzsCommonModule,
    IzCommonModule,
    ReactiveFormsModule,
    TranslateModule,
    IzRegistrationServicesModule,
    IzRegistrationModule,
  ],
  declarations: [
    RegistrationComponent,
    RegistrationTitleComponent,
    RegistrationStepsComponent,
    ConfirmTermsOfServiceComponent,
    RegistrationFirstStepComponent,
    RegistrationThirdStepComponent,
    RegistrationSecondStepComponent,
    TermsOfServiceComponent,
  ],
  bootstrap: [RegistrationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegistrationModule {}
