import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '@iz_shared/services/profile';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class UserGuardService implements CanActivate {
  protected router: Router = this.inject.get(Router);

  constructor(private inject: Injector, private profileService: ProfileService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const roles = route.data.accessRoles;
    return this.profileService.signProfile$.pipe(
      tap((role: string) => {
        if (!role.length) {
          this.profileService.getUserRequest();
        }
      }),
      filter((role: string) => {
        return Boolean(role.length);
      }),
      map((role: string) => {
        return roles.some((r) => r === role);
      }),
    );
  }
}
