import { Directive, ElementRef, OnDestroy } from '@angular/core';

import * as types from 'creditcards-types';
import * as Card from 'creditcards/card';

@Directive({
  selector: '[izCardNumberMask]',
})
export class CardNumberMaskDirective implements OnDestroy {
  private readonly element: HTMLInputElement;

  constructor(el: ElementRef) {
    this.onValueChange = this.onValueChange.bind(this);

    this.element = el.nativeElement;
    this.element.addEventListener('input', this.onValueChange);
  }

  public ngOnDestroy() {
    this.element.removeEventListener('input', this.onValueChange);
  }

  private onValueChange() {
    const v = this.element.value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (v.length) {
      try {
        const card = this.getCard(v);
        this.element.value = card.format(v);
      } catch (e) {
        console.warn('unknown credit card format');
      }
    }
  }

  private getCard(cardNumber: string) {
    const cardType = types.find((type) => type.test(cardNumber, true));
    return Card([cardType]);
  }
}
