import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SurveyResultApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  public get(params: any) {
    return this.http.get(`/api/survey_results/`, {
      params,
    });
  }

  public create(data: any) {
    return this.http.post('/api/survey_results/', data);
  }

  public update(id: any, accuracy: any) {
    return this.http.patch(`/api/survey_results/${id}/`, {
      accuracy,
    });
  }

  public export(id: any) {
    return this.http.get(`/api/survey_results/${id}/export/`, {
      responseType: 'blob',
    });
  }
  public basic() {
    return this.http.get('/api/surveys/basic/');
  }
}
