import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/* Vendors */
import { pick } from 'lodash-es';

/* Models */
import { User } from '@iz_commerce/models/User';

@Injectable()
export class RegistrationService {
  private user = new User();

  constructor(private request: HttpClient) {}

  public clearUser() {
    this.user = new User();
  }

  public setUser(user: User) {
    this.user = user;
  }

  public getUser(): User {
    return this.user;
  }

  public checkEntity(entityName: string) {
    return this.request.post('/api/entities/check_unique/', {
      entity_name: entityName,
      project: 'commerce',
    });
  }

  public sendRegistration(): any {
    const user = pick(this.user, [
      'entity_name',
      'title',
      'first_name',
      'last_name',
      'address',
      'city',
      'state',
      'country',
      'zip_code',
      'email',
      'phone',
      'username',
      'password',
      'terms_of_use',
    ]);

    user.terms_of_use = new Date().toISOString();

    return this.request.post('/api/users/registration/', {
      ...user,
      project: 'commerce',
    });
  }
}
