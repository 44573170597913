import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/* Components */
import { DropdownComponent } from './dropdown.component';
import { ButtonModule } from '../button';

/* Modules */
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaceLineBreaks } from '@iz_shared/components/dropdown/dropdown.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgScrollbarModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule,
    ButtonModule],
  declarations: [DropdownComponent, ReplaceLineBreaks],
  exports: [DropdownComponent],
})
export class DropdownModule {}
