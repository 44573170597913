import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { last } from 'rxjs/operators';

@Injectable()
export class BackBoardService {
  private boardsEmitter;

  constructor(private http: HttpClient) {
    this.boardsEmitter = Observable.create((observer) => {
      this._getBoards().subscribe((data: any) => {
        observer.next(data.results.map((item) => {
          return {
            name: item.name,
            url: item.web_field,
          };
        }));
      });
    });
  }

  public getBoards() {
    if (!this.boardsEmitter.pipe(last())) {
      this.boardsEmitter.retry();
    }
    return this.boardsEmitter;
  }

  private _getBoards() {
    return this.http.get('/api/sports/');
  }

}
