import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'iz-copy-move-user',
  templateUrl: './copy-move-user.component.html',
  styleUrls: ['./copy-move-user.component.scss'],
})
export class CopyMoveUserComponent {
  @Output() public onToggle = new EventEmitter();
  public toggle: boolean = false;
  public isCopyMoveLoading: boolean = false;
  constructor() { }

  public turnToggle() {
    this.isCopyMoveLoading = true;
    this.toggle = !this.toggle;
    this.onToggle.emit(this.toggle);
    this.isCopyMoveLoading = false;
  }
}
