import { Injectable, Injector, Provider } from '@angular/core';
import { EmailApiService } from '@iz_shared/services/api/email-api';

@Injectable()
export class IzsEmailApiService extends EmailApiService {
  public static di: Provider = {
    provide: EmailApiService,
    useExisting: IzsEmailApiService,
  };

  constructor(inject: Injector) {
    super(inject);
  }
}
