import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserApiService } from '@iz_shared/services/api/user-api';
import { EmailApiService } from '@iz_shared/services/api/email-api';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private userApi: UserApiService, private emailApi: EmailApiService) {}

  public login(credentials): Observable<any> {
    return this.http.post('/api/login', credentials);
  }

  public loginByToken(token: string): Observable<any> {
    return this.http.post('/api/login', { token });
  }

  public resendEmail(user): Observable<any> {
    return this.emailApi.resendEmail(user.id, user);
  }

  public recoveryPassword(username): Observable<any> {
    return this.userApi.recoveryPassword(username);
  }

  public recoveryUsername(email): Observable<any> {
    return this.userApi.recoveryUsername(email);
  }

  public resetPassword(data): Observable<any> {
    return this.userApi.resetPassword(data);
  }

  public sendDate(user): Observable<any> {
    const params = new HttpParams();
    params.append('id', user.id);

    return this.http.put(`/api/date-birth/:id`, user, {
      params,
    });
  }
}
