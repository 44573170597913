import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService, UserService } from './authentication';
import { ForgotService } from './forgot';
import { ParentalConsentService } from './parental-consent';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [AuthService, UserService, ForgotService, ParentalConsentService],
})
export class IzLoginServicesModule {
}
