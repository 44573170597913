import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'd-pair-info',
  templateUrl: './d-pair-info.component.html',
  styleUrls: ['./d-pair-info.component.scss'],
})
export class DPairInfoComponent implements OnChanges {
  @Input() public title: string = '';
  @Input() public user: any;
  @Input() public left: boolean = false;
  @Input() public findBtnAvailabel = false;
  @Input() public isDroppable = true;
  @Input() public disableClearBtn = false;

  @Output() public onFind = new EventEmitter();
  @Output() public onDrop = new EventEmitter<any>();
  @Output() public onClear = new EventEmitter<any>();
  public name: any;

  constructor() {}

  public ngOnChanges() {
    if (this.user) {
      this.name = this.user.fullname || '';
      this.user.description = (this.user.description || '').replace(this.name, '');
    }
  }

  public drop($event: any) {
    if (this.isDroppable) {
      this.onDrop.emit($event);
    }
  }
}
