import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Entity } from '@iz_shared/models/Entity';
import { IGroupListParams } from '@iz_shared/services/api/group-api';
import { normalizeParams } from '@iz_shared/services/utils';
import { Group } from '@iz_shared/models/Group';
import { EntityService } from '@iz_shared/views/dashboard/services/entity';

@Injectable()
export class CopyMoveService {
  public projectName: string = null;
  protected http: HttpClient = this.inject.get(HttpClient);
  protected entity: BehaviorSubject<Entity[]> = new BehaviorSubject([]);
  protected entities: BehaviorSubject<Entity[]> = new BehaviorSubject([]);
  protected groupList$: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  constructor(private inject: Injector, public entityService: EntityService) {
    this.entityService.getEntityObs().subscribe((entity: any) => {
      if (entity) {
        this.projectName = entity.project;
      }
    });
  }

  public setEntity(entity?: any, state?: boolean) {
    if (this.entity.getValue() !== entity || state) {
      this.entity.next(entity);
      this.getList({ entity: entity.id }).subscribe((data: Group[]) => {
        this.groupList$.next((data as any).results);
      });
    }
  }

  public setGroupList(list: Group[]) {
    this.groupList$.next(list);
  }

  public getEntityObs() {
    return this.entity.asObservable().pipe(distinctUntilChanged<Entity[]>((o, n) => o === n));
  }

  public get list$() {
    return this.groupList$.asObservable();
  }

  public getList(params: IGroupListParams) {
    return this.http.get('/api/groups/', {
      params: normalizeParams({
        ...params,
        disable_pagination: 'true',
      }),
    });
  }

  public fetchEntities() {
    return this.http
      .get('/api/entities/', {
        params: {
          disable_pagination: 'true',
        },
      })
      .subscribe((data) => {
        this.entities.next((data as any).results);
      });
  }

  public copyUser(user: any, groupID: any, position?: any) {
    const data: any = { user: user.id, group: groupID, role: user.copyRole, category: user.category_id };
    if (user.copyRole === 'player') {
      data.number = user.number;
    }
    if (position) {
      data.category = position.value || position.id;
    }
    return this.http.post('/api/user_groups/', {
      ...data,
    });
  }

  public moveUser(user: any, groupID: any, position: any) {
    const data: any = { group: groupID };
    if (user.copyRole !== 'coach') {
      data.category = position.value || position.id;
    }
    return this.http.patch(`/api/user_groups/${user.role_id}/`, {
      ...data,
    });
  }
}
