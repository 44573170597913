import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';

import { InputComponent } from '@iz_shared/components/input';

@Component({
  selector: 'iz-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  host: {
    '[class.without-error]': 'disableError',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {
  @Input() public disableError = false;
  @Input() public isProcessing = false;
  @ContentChild(InputComponent) public input: InputComponent;

  constructor() {}

}
