import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-old-browser-message',
  templateUrl: './old-browser-message.component.html',
  styleUrls: ['./old-browser-message.component.scss'],
})
export class OldBrowserMessageComponent implements OnInit {
  public browsers = [
    {
      link: 'https://www.google.com/chrome/',
      image: 'assets/images/browser_logo/chrome_logo.svg',
      name: 'Chrome',
    },
    {
      link: 'https://www.mozilla.org/',
      image: 'assets/images/browser_logo/ff_logo.svg',
      name: 'FireFox',
    },
    {
      link: 'https://www.opera.com/',
      image: 'assets/images/browser_logo/opera_logo.svg',
      name: 'Opera',
    },
  ];
  @Input() public browserName = '';
  @Output() public onClose = new EventEmitter();
  public browser: any = null;

  constructor() { }

  public ngOnInit() {
    this.setBrowserLink();

  }

  public getTranslateParams() {
    return {
      link: this.browser.link,
    };
  }

  public setBrowserLink() {
    this.browser = this.browsers
      .find((el) => el.name.toLowerCase() === this.browserName.toLowerCase());
  }

}
