import { NgModule } from '@angular/core';
import { EqualPasswordsValidator } from './equalPasswords.validator';
import { WhitespaceDirective } from './whitespace.directive';

@NgModule({
  providers: [EqualPasswordsValidator],
  declarations: [WhitespaceDirective],
  exports: [WhitespaceDirective],
})
export class IzValidatorsModule {}
