import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalPointComponent } from './portal-point';
import { PortalComponent } from './portal';
import { PortalService } from './portal.service';

@NgModule({
  imports: [CommonModule],
  declarations: [PortalPointComponent, PortalComponent],
  exports: [PortalPointComponent, PortalComponent],
})
export class PortalModule {
  public static forRoot(): ModuleWithProviders<PortalModule> {
    return {
      ngModule: PortalModule,
      providers: [PortalService],
    };
  }
}
