export default {
  user: [
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
  ],
  headuser: [
    {
      url: 'assets/images/user-menu/questionnaire.svg',
      title: 'dashboard.userMenu.questionnaire',
      link: '/dashboard/innerzone-profile',
      conditional: 'survey',
    },
    {
      url: 'assets/images/user-menu/my-account.svg',
      title: 'My Account',
      link: '/dashboard/my-account',
    },
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
  ],
  manager: [
    {
      url: 'assets/images/user-menu/questionnaire.svg',
      title: 'dashboard.userMenu.questionnaire',
      link: '/dashboard/innerzone-profile',
      conditional: 'survey',
    },
    {
      url: 'assets/images/user-menu/my-account.svg',
      title: 'My Account',
      link: '/dashboard/my-account',
    },
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
  ],
  headmanager: [
    {
      url: 'assets/images/user-menu/questionnaire.svg',
      title: 'dashboard.userMenu.questionnaire',
      link: '/dashboard/innerzone-profile',
      conditional: 'survey',
    },
    {
      url: 'assets/images/user-menu/my-account.svg',
      title: 'My Account',
      link: '/dashboard/my-account',
    },
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
  ],
  entityadmin: [
    {
      url: 'assets/images/user-menu/questionnaire.svg',
      title: 'dashboard.userMenu.questionnaire',
      link: '/dashboard/innerzone-profile',
      conditional: 'survey',
    },
    {
      url: 'assets/images/user-menu/my-account.svg',
      title: 'My Account',
      link: '/dashboard/my-account',
    },
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
  ],
  admin: [
    {
      url: 'assets/images/user-menu/logout.svg',
      title: 'dashboard.userMenu.logout',
      link: '/login',
    },
    {
      url: 'assets/images/user-menu/my-account.svg',
      title: 'My Account',
      link: '/dashboard/my-account',
    },
  ],
};
