import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnChanges {
  @Input() public data: any;
  public fields = ['bridge_builder', 'energizer', 'bottom_liner', 'thinker'];
  public convert: {
    [key: string]: {
      p100: number;
      delimiter: number;
      float: number;
      angle: number;
    };
  } = {
    thinker: {
      p100: 0,
      delimiter: 0,
      float: 0,
      angle: 0,
    },
    energizer: {
      p100: 0,
      delimiter: 0,
      float: 0,
      angle: 0,
    },
    bridge_builder: {
      p100: 0,
      delimiter: 0,
      float: 0,
      angle: 0,
    },
    bottom_liner: {
      p100: 0,
      delimiter: 0,
      float: 0,
      angle: 0,
    },
  } as any;
  public all: number;

  public ngOnChanges() {
    this.getCirce(this.data);
  }

  public getCirce(data) {
    if (data) {
      let prevSum: number = 0;
      this.fields.forEach((field, i) => {
        if (this.fields[i - 1]) {
          prevSum += this.convert[this.fields[i - 1]].p100;
        }
        if (!this.convert[field]) {
          this.convert[field] = {} as any;
        }
        this.convert[field].float = data[field];
        this.convert[field].p100 = data[field];
        this.convert[field].delimiter = prevSum;
        this.convert[field].angle = this.calculateAngle(field);
      });
    }
  }

  private calculateAngle(field: string) {
    return (
      (this.convert[field].delimiter + this.convert[field].p100 / 2 + 25 + (this.convert[field].float < 10 ? 0.5 : 0)) /
      100
    );
  }
}
