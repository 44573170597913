import { Component } from '@angular/core';

@Component({
  selector: 'app-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss'],
})
export class TableColumnsComponent {

  constructor() { }

}
