import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PortalService } from '../portal.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit, OnDestroy {
  @Input() public portalTarget: string = '';
  @ViewChild('portalContent', { static: true }) public portalContent: ElementRef;

  constructor(private portalService: PortalService) {
  }

  public ngOnInit() {
    if (this.portalTarget) {
      this.portalService.addRef(this.portalTarget, this.portalContent);
    }
  }

  public ngOnDestroy() {
    if (this.portalTarget) {
      this.portalService.removerRef(this.portalTarget, this.portalContent);
    }
  }
}
