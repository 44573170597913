import {
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { debounce } from 'lodash-es';

import { GlobalSearchService } from '@iz_shared/views/dashboard/services/global-search';
import { EntityService } from '@iz_shared/views/dashboard/services/entity';
import { Entity } from '@iz_shared/models/Entity';
import { FilterService } from '@iz_shared/views/dashboard/services/filter/filter.service';

interface IGlobalSearchUser {
  id: number;
  role: string;
  email: string;
  username: string;
  last_name: string;
  first_name: string;
  entity_name: string;
  entity_id: number;
  team: string;
  sport: string;
  season: string;
  group_id: string;
  group_role: string;
}

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss'],
})

export class SearchUserComponent implements OnDestroy {
  public search: FormControl = new FormControl('');
  public isFocused: boolean = false;
  public isLoading: boolean = false;
  public noResult: boolean = false;
  public noCondition: boolean = false;
  public isFetching: any;
  public initEntityProjectName: string;
  public result: IGlobalSearchUser[] = [];
  private lastValue: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private containerElement: ElementRef,
    private entityService: EntityService,
    private filterService: FilterService,
    private globalSearchService: GlobalSearchService,
  ) {
    this.subscriptions.push(
      this.entityService.getEntityObs().subscribe((entity: Entity) => {
        if (entity) {
          this.initEntityProjectName = entity.project;
        }
      }),
    );
  }

  public ngOnInit() {
    const onchange = debounce((value) => this.onChange(value), 300);
    this.subscriptions.push(this.search.valueChanges.subscribe(onchange));
  }

  public onChange(value: string) {
    const check = /^\s+/.test(value) || /\s{2,}/.test(value);
    value = value.replace(/^\s+/g, '').replace(/\s{2,}/g, ' ');

    if (check) {
      this.search.setValue(value);
    } else  {
      value = value.replace(/\s+$/g, '');
      this.request(value);
    }
  }

  public request(value) {
    if (value && (value.length > 2)) {
      if (this.lastValue !== value) {
        if (this.isFetching) {
          this.isFetching.unsubscribe();
        } else {
          this.toDefault();
        }
        this.isLoading = true;
        this.lastValue = value;
        this.isFetching = this.globalSearchService.fetchSearch(value).subscribe((data: any) => {
          this.result = data.results;
          this.noResult = !Boolean(this.result.length);
          this.isLoading = false;
          this.isFetching = null;
          this.addOrRemoveListener();
        }, () => {
          this.isLoading = false;
          this.isFetching = null;
          this.addOrRemoveListener();
        });
      }
    } else {
      this.result = [];
      this.isLoading = false;
      this.addOrRemoveListener();
      if ((value && (value.length) >= 1) && (value && (value.length) < 3)) {
        this.noCondition = true;
        this.noResult = false;
      }
      if (value.length <= 1) {
        this.toDefault();
      }
    }
  }

  public toDefault() {
    this.noResult = false;
    this.noCondition = false;
  }

  public getGroup(user: IGlobalSearchUser): string {
    return [user.team, user.season, user.sport].filter(Boolean).join('/');
  }

  public getRole(role: string): string {
    const user = [
      {name: 'Player', value: 'player'},
      {name: 'Coach', value: 'coach'},
      {name: 'Head Coach', value: 'headcoach'},
    ].filter((data) => role === data.value)[0] || {name: 'Player', value: 'player'};

    return user.name;
  }

  public onSelect(selected: IGlobalSearchUser) {
    if (selected) {
      this.result = [];
      this.search.setValue('');
      this.addOrRemoveListener();
      this.entityService.setEntity({
        id: selected.entity_id,
        name: selected.entity_name,
        project: this.initEntityProjectName,
      });
      this.router.navigate(['/dashboard/sports-management/board/field'], {
        queryParams: {id: selected.group_id},
      });
      this.filterService.setFilter({
        name: selected.team,
        season: selected.season,
        sport: selected.sport,
      });
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private addOrRemoveListener() {
    if (this.result.length) {
      document.addEventListener('click', this.onHandleOutsideClick);
    } else {
      document.removeEventListener('click', this.onHandleOutsideClick);
    }
  }

  private onHandleOutsideClick = (e: Event) => {
    if (
      this.containerElement.nativeElement !== e.target &&
      !this.containerElement.nativeElement.contains(e.target as any)
    ) {
      this.search.setValue('');
      this.addOrRemoveListener();
    }
  }
}
