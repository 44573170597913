import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[spacetrim] , spacetrim',
})
export class SpaceTrimDirective {
  // left this property just to keep compatibility with previous version of directive
  @Input() public spacetrim?: any;

  constructor(private el: ElementRef) {}

  private get input() {
    return this.el.nativeElement as HTMLInputElement;
  }

  @HostListener('blur')
  public onBlur() {
    this.input.value = String(this.input.value).trim();
  }
}
