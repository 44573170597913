import { ApiPrefixService } from './api-prefix';
import { ApiTokenService } from './api-token';
import { ErrorHandlerInterceptorService } from './error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export const APP_INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiTokenService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiPrefixService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptorService,
    multi: true,
  },
];
