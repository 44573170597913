import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldsComponent implements OnInit {
  @Input() public src: string = '';
  @Input() public src_low: string = '';
  @Input() public logo?: string;
  @Input() public no_lazy = false;
  public loadingField: boolean = true;

  constructor() {}

  public ngOnInit() {
    this.loadingField = !this.no_lazy;
  }

  public loadedImg() {
    this.loadingField = false;
  }
}
