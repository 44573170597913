import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input, Output,
  ViewChild,
} from '@angular/core';
import { CalculateSizeService } from '@iz_shared/services/calculate-size';

@Component({
  selector: 'd-pair-user-chart',
  templateUrl: './d-pair-user-chart.component.html',
  styleUrls: ['./d-pair-user-chart.component.scss'],
})
export class DPairUserChartComponent implements AfterViewChecked {
  @Input() public user: any = {};
  @Input() public index: number;
  @Input() public findBtnAvailabel = false;

  @Output() public onFind = new EventEmitter();
  @ViewChild('container_name', { static: true }) public container_name: ElementRef;
  public width: any;

  constructor(private calculateSize: CalculateSizeService, private cdRef: ChangeDetectorRef) {}

  public checkWidth() {
    const width = this.container_name.nativeElement.offsetWidth;
    const size: any = this.calculateSize.calculateSize(
      this.user.fullname || '',
      {
        font: 'DIN Condensed',
        fontSize: '17px',
      } as any);
    if (width < size.width + 5) {
      this.width = this.container_name.nativeElement.offsetWidth;
    }
    this.cdRef.detectChanges();
  }

  public ngAfterViewChecked() {
    this.checkWidth();
  }

  public getPrimarily(title: string = '') {
    return title.replace(/\_/i, ' ');
  }
}
