import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SurveyResultApiService } from './survey-result-api';
import { UtilsApiService } from './utils-api';
import { UserApiService } from './user-api';
import { PdfApiService } from './pdf-api';
import { LineupApiService } from './lineup-api';
import { GroupApiService } from './group-api';
import { EmailApiService } from './email-api';
import { TeamApiService } from './team-api';

@NgModule({
  imports: [CommonModule],
  providers: [
    SurveyResultApiService,
    UtilsApiService,
    UserApiService,
    PdfApiService,
    LineupApiService,
    GroupApiService,
    EmailApiService,
    TeamApiService,
  ],
  declarations: [],
})
export class IzApiModule {}
