import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IDropdownItem {
  value: any;
  name: string;
}

@Component({
  selector: 'iz-smart-dropdown',
  templateUrl: './smart-dropdown.component.html',
  styleUrls: ['./smart-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SmartDropdownComponent),
    },
  ],
})
export class SmartDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() public for = '';
  @Input() public items: IDropdownItem[] = [];
  @Input() public selected?: IDropdownItem;
  @Input() public disabled = false;
  @Input() public isEdit = false;
  @Input() public buttonTitle = '';
  @Input() public dropdownTitle = '';
  @Input() public placeholder = '';
  @Input() public addTitle = '';
  @Input() public isProcessing = false;
  @Input() public withAddReplacingDropdown = true;
  @Input() public isCleared = true;
  @Input() public maxlength = 99999;

  @Output() public onAdd = new EventEmitter<string>();
  @Output() public onActiveInput = new EventEmitter<string>();
  @Output() public onRemove = new EventEmitter();
  @Output() public onChange = new EventEmitter<IDropdownItem>();
  @Output() public onClear = new EventEmitter<string>();
  @Output() public onSelect = new EventEmitter<string>();

  public name: string = '';
  public value?: any;

  constructor() {}
  @Input() public checkDisableChange: (item) => boolean = () => false;

  public activeInput() {
    this.onActiveInput.emit(this.for);
    this.isEdit = true;
  }

  public toAdd() {
    this.activeInput();
    this.value = void 0;
    this.name = '';
  }

  public ngOnInit() {}

  public ngOnChanges(changes) {
    if (changes.isEdit) {
      if (!this.value) {
        this.name = '';
      }
    }
  }

  public toEdit(item: IDropdownItem) {
    this.name = item.name;
    this.value = item.value;
    this.activeInput();
  }

  public onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.type === 'click') {
      this.name = this.name.trim();
      if (this.name) {
        event.stopPropagation();
        event.preventDefault();
        if (this.value) {
          this.onChange.emit({ value: this.value, name: this.name } as IDropdownItem);
        } else {
          this.onAdd.emit(this.name);
        }
      }
    }
  }

  public writeValue(selectedValue: any) {}

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched() {}

  private onChangeCallback: (_: any) => void = () => {};
}
