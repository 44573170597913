import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonToggleComponent } from './button-toggle.component';
import { TranslateModule } from '@ngx-translate/core';
import { IzTestDiModule } from '@iz_shared/iz-test-di.module';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ButtonToggleComponent],
  exports: [ButtonToggleComponent],
})
export class ButtonToggleModule {}
