// The file contents for the current environment will overwrite these during build.
// Build configuration "fileReplacements" mappings can be found in `angular.json`.

import { IEnvironment } from '@iz_shared/environment.type';

// Used for deploying the front-end to the dev environment.
// Any branch can deploy to the dev environment in the cloud.
// See the build steps in `main.yaml` for more info.
export const environment: IEnvironment = {
  production: false,
  hmr: true,
  api: 'https://sports.dev.innerzone.com',
  server_uri: 'https://sports.dev.innerzone.com',
  project_namespace: 'sports',
  api_patch: 'api',
  static_patch: 'static',
  stripe_key: 'pk_test_WSqesDHP4Vb6AYAT8DfAXYZn',
};
