import { Entity } from './Entity';
import { Email } from '@iz_shared/models/Email';

export enum TitleType {
  MR = 'mr',
  MS = 'ms',
  MRS = 'mrs',
  MISS = 'miss',
}

export enum UserRole {
  SUPER_ADMIN = 'admin',
  ENTITY_ADMIN = 'entityadmin',
  HEAD_MANAGER = 'headmanager',
  MANAGER = 'manager',
  HEADUSER = 'headuser',
  USER = 'user',
}

export enum LinkType {
  STRONGEST = 'strongest',
  MODERATE = 'moderate',
  WEAKEST = 'weakest',
}

export class User {
  public id: number;
  public city: string;
  public birth_date: string;
  public role: any;
  public email?: string | object;
  public title: string;
  public phone: string;
  public state: string;
  public country: string;
  public address: string;
  public survey: string;
  public project: string;
  public verify: boolean;
  public zip_code: string;
  public category: string; // sport
  public username: string;
  public password: string;
  public primarily?: string[];
  public last_name: string;
  public first_name: string;
  public is_active: boolean;
  public is_promo: boolean;
  public entity_name: string;
  public terms_of_use: string;
  public verifyPassword: string;
  public number?: string; // sport
  public is_invitation_sent: string;
  public information_sharing: boolean;
  public receive_notifications: boolean;
  public updated: string;
  public guardian_action_date: string;
  public guardian_title: string;
  public guardian_email: string;
  public guardian_first_name: string;
  public guardian_last_name: string;
  public guardian_acceptance: string;
  public localRole: string;
  public profile_percentages?: any;
  public profile?: any;
  public invisible: boolean;
  public my_teams?: boolean;
  public category_id: any;
  public entity: any;
  public user: object;

  constructor() {}
}

export class UserBase {
  public is_active: boolean;
  public username: string;
  public email: string;
  public title: TitleType;
  public last_name: string;
  public first_name: string;
  public project: string;
  public role: UserRole;
  public birth_date?: string;
  public guardian_first_name?: string;
  public guardian_last_name?: string;
  public guardian_email?: string;
  public guardian_title: TitleType;
  public terms_of_use?: string;
  public address?: string;
  public city?: string;
  public state?: string;
  public country?: string;
  public zip_code?: string;
  public phone?: string;
  public receive_notifications: boolean;
  public entity?: Entity['id'];
  public is_director: boolean;
  public information_sharing: boolean;
}

export class UserGroup {
  public id: number;
  public user: User;
  public group: string;
  public role: string;
  public category: number;
  public number?: string;
  public email?: Email;
}

export class User3 extends User {
  public _selected?: boolean;
  public is_reminder?: boolean;
}

export class NewUser extends UserBase {
}
