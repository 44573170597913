import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Modal} from '@iz_shared/components/modal';
import {Subscription} from 'rxjs';
import {EntityService} from '@iz_shared/views/dashboard/services/entity';

@Component({
  selector: 'app-current-entity',
  templateUrl: './current-entity.component.html',
  styleUrls: ['./current-entity.component.scss'],
})
export class CurrentEntityComponent implements OnDestroy {
  @ViewChild('modalCurrentEntity', { static: true }) public modal: Modal;

  public isActive: boolean = true;
  public currentEntity: any;
  public isFirst = true;
  private hasField = [
    'admins',
    'reports',
    'email-messages',
    'tier-management',
    'sports-management',
    'teams',
  ];
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public entityService: EntityService) {
  }

  public ngOnInit() {
    this.isActive = this.hasField.some((state: string) => {
      return (this.activatedRoute as any)._routerState.snapshot.url.includes(
        state,
      );
    });
    this.entityService.fetchEntities();
    this.subscriptions.push(
      this.router.events.subscribe((data: NavigationEnd) => {
        this.isActive = this.hasField.some((state: string) => {
          return data.url && data.url.includes(state);
        });
      }),
      this.entityService.getEntityObs().subscribe((entity) => {
        let notNeedOpen = true;
        if (this.currentEntity && !entity) {
          notNeedOpen = false;
        }
        this.currentEntity = entity;
        if (!this.currentEntity && notNeedOpen) {
          this.modal.open();
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onChangeCurrentEntity(currentEntity) {
    this.modal.close();
    this.isFirst = false;
    this.entityService.setEntity(currentEntity);
  }

  public trackByFn(index, _) {
    return index;
  }
}
