import { NgModule } from '@angular/core';
import { IzcSharedComponentsModule } from '@iz_commerce/common/components';
import { IzcSharedDirectivesModule } from '@iz_commerce/common/directives';

@NgModule({
  imports: [IzcSharedComponentsModule, IzcSharedDirectivesModule],
  declarations: [],
  providers: [],
  exports: [IzcSharedComponentsModule, IzcSharedDirectivesModule],
})
export class IzcCommonModule {}
