import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';

import { UserSystemRoles } from '@iz_sports/models/User';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Output() public toggle = new EventEmitter<any>();
  @Input() public user: any;

  public visible = {
    common: null,
    toggle: null,
    isAdmin: null,
  };

  constructor(private zone: NgZone) {
    Object.defineProperties(this.visible, {
      common: {
        get: () => {
          return Boolean(this.user);
        },
      },
      toggle: {
        get: () => {
          return this.user && (this.user.role === UserSystemRoles.SUPER_ADMIN.toString() ||
            (this.user.role !== UserSystemRoles.USER.toString() && this.user.survey));
        },
      },
      isAdmin: {
        get: () => {
          return this.user && this.user.role === UserSystemRoles.SUPER_ADMIN.toString();
        },
      },
    });
  }
}
