import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-manager-board-sub-menu',
  templateUrl: './manager-board-sub-menu.component.html',
  styleUrls: ['./manager-board-sub-menu.component.scss'],
})
export class ManagerBoardSubMenuComponent {
  @Input() public user: any = {
    additional_fields: {
      primarily: 'primarily',
      category: 'category',
    },
  };
  @Output() public edit = new EventEmitter<any>();
  @Output() public return = new EventEmitter<any>();
  @Output() public delete = new EventEmitter<any>();
  @Output() public profile = new EventEmitter<any>();

  constructor() {
  }

  public emit(elm: any, emitter: EventEmitter<any>) {
    elm.click();
    emitter.emit();
  }
}
