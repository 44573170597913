import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-table-column',
  templateUrl: './table-column.component.html',
  styleUrls: ['./table-column.component.scss'],
})
export class TableColumnComponent implements OnInit {
  @Input() public label: string = '';
  @Input() public color?: string = '#1e1e1e';
  @Input() public hasFilter?: boolean = false;
  @Input() public hasCheckbox?: boolean = false;
  @Input() public expanded?: boolean = true;
  @Input() public arrow: boolean = false;
  @Input() public hideArrow: boolean = false;
  @Input() public selectAll: boolean = false;
  @Input() public center: boolean = false;
  public hideClear: boolean = true;
  @Input() public filter: string = '';
  @Input() public noContent?: boolean = false;
  @Output() public onFiltered = new Subject<string>();
  @Output() public onSelectAll = new EventEmitter<any>();
  @Output() public expandedTable = new EventEmitter<boolean>();

  public checkbox: any = 'assets/images/common/check-mark.svg';
  public nullCheckbox: any = 'assets/images/common/empty.svg';

  public isShowFilter: boolean = false;

  constructor() {
    this.expandedTable.emit(this.expanded);
    this.onFiltered.pipe(distinctUntilChanged());
  }

  public ngOnInit() {}

  public toggleFilter() {
    this.isShowFilter = !this.isShowFilter;
    this.arrow = false;
    if (!this.isShowFilter) {
      this.clearFilter();
      this.arrow = true;
      this.onClick();
    }
    if (!this.expanded) {
      this.onClick();
    }
  }

  public onClick() {
    this.expanded = !this.expanded;
    this.expandedTable.emit(this.expanded);
  }

  public onSelectedAll() {
    if (!this.noContent) {
      this.selectAll = !this.selectAll;
      this.onSelectAll.emit(this.selectAll);
    }
  }

  public onFilter() {
    this.filter = this.filter.trim();
    if (this.filter.length >= 3) {
      this.onFiltered.next(this.filter || '');
    } else if (this.filter.length >= 1) {
      this.hideClear = false;
    } else {
      this.hideClear = true;
      this.onFiltered.next('');
    }
  }

  public clearFilter() {
    this.filter = '';
    this.hideClear = true;
    this.onFiltered.next('');
  }

  public stateCheckbox() {
    if (!this.noContent) {
      if (/Edge\/\d./i.test(navigator.userAgent)) {
        this.onSelectedAll();
      }
    }
  }
}
