import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// возможно больше подойдет BehaviorSubject:
// http://reactivex.io/rxjs/manual/overview.html#behaviorsubject

@Injectable()
export class EventBusService {
  private storage = new Map<string, Subject<any>>();
  private valueStorage = new Map<string, any>();

  constructor() {}

  public fire(eventName: string, data: any) {
    if (this.storage.has(eventName)) {
      this.valueStorage.set(eventName, data);
      this.storage.get(eventName).next(data);
    }
  }

  public subscribe(event: string, callback: any, quite?: boolean) {
    const emitter = this.storage.get(event) || new Subject<any>();

    const subscription = emitter.subscribe(callback);

    if (this.valueStorage.has(event) && !quite) {
      callback(this.valueStorage.get(event));
    }
    this.storage.set(event, emitter);
    return subscription;
  }
}
