import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: '[izFormLabel]',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss'],
  host: {
    '[class.box]': '!toggle',
    '[class.toggle]': 'toggle',
    '[class.box--text]': 'withoutDecoration',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {

  @Input() public toggle: boolean = false;
  @Input() public withoutDecoration: boolean = false;
  constructor() {}
}
