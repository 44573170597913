import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { UserApiService } from '@iz_shared/services/api/user-api';
import { GroupApiService } from '@iz_shared/services/api/group-api';
import { PdfApiService } from '@iz_shared/services/api/pdf-api';

@Injectable()
export class DPairService {
  public changeFilter = new EventEmitter();
  constructor(
    private http: HttpClient,
    private userApi: UserApiService,
    private groupApi: GroupApiService,
    private pdfApi: PdfApiService,
  ) {}

  public getPairPdf(userId1: number, groupId1: number, userId2: number, groupId2: number) {
    return this.pdfApi.pair('pair', { userId1, groupId1, userId2, groupId2 });
  }

  public getTopMatchesPdf(userID: number, groupID: number, usersActive) {
    return this.pdfApi.topMatchesPdf('top_matches', { userID, groupID, usersActive });
  }

  public getRelation(id1: number, id2: number) {
    return this.userApi.getRelation(id1, id2);
  }

  public updatePairs(pairs, teamId) {
    return this.groupApi.updateReport(
      teamId,
      { pairs },
      {
        included_fields: 'pairs',
      },
    );
  }
}
