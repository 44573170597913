import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'iz-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ToggleComponent,
      multi: true,
    },
  ],
})
export class ToggleComponent implements OnInit, ControlValueAccessor {
  @Input()
  public value = false;
  @Input()
  public byParent = false;
  @Input()
  public trueLabel = 'Yes';
  @Input()
  public falseLabel = 'No';
  @Input()
  public disableLabel = false;
  @Input()
  public disabled = false;
  @Output()
  public onToggle = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public getValue() {
    return Boolean(this.value);
  }

  public get _value() {
    return Boolean(this.value);
  }
  public set _value(v) {
    if (this.disabled) {
      return;
    }
    if (!this.byParent) {
      this.value = v;
    }
    this.onChangeCallback(v);
    this.onToggle.emit(v);
  }

  public setValue(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this._value = !this._value;
    (event.target as any).checked = this._value;
  }

  public writeValue(selectedValue: boolean) {
    if (selectedValue === this._value) {
      return;
    }
    this._value = selectedValue;
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched() {}

  private onChangeCallback: (_: any) => void = () => {};
}
