import { Injectable, Injector } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { pick } from 'lodash-es';
import {normalizeParams} from '@iz_shared/services/utils';
import {IApiRes} from '@iz_shared/models/Response';
import {Lineups} from '@iz_shared/models/Lineups';
import { Observable } from 'rxjs';

@Injectable()
export class LineupApiService {

  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  public getList(groupId: number, step?: string, limit = 10) {
    if (step) {
      return this.http.get<IApiRes<Lineups[]>>(step);
    } else {
      return this.http.get<IApiRes<Lineups[]>>(`/api/lineups/?limit=${limit}`, {
        params: normalizeParams({
          group: groupId,
        }),
      });
    }
  }
  public getListWithOffset(groupId: number, offset: number = 0, limit = 10) {
    return this.http.get<IApiRes<Lineups[]>>(`/api/lineups/?limit=${limit}&offset=${offset}`, {
      params: normalizeParams({
        group: groupId,
      }),
    });
  }

  public get(id: string) {
    return this.http.get<Lineups>(`/api/lineups/${id}/`);
  }

  public add(data) {
    const lineup = pick(data, ['group', 'name', 'notes', 'users']);
    return this.http.post<Lineups>(`/api/lineups/`, {
      ...lineup,
    });
  }

  public edit(data: any): Observable<any> {
    const lineup = pick(data, ['id', 'name', 'notes', 'users']);
    return this.http.patch(`/api/lineups/${lineup.id}/`, {
      ...lineup,
    });
  }

  public delete(id: string) {
    return this.http.delete(`/api/lineups/${id}/`);
  }
}
