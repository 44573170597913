import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '@iz_shared/services/toast';
import { LoadingService } from '@iz_shared/components/loading/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ToastService, LoadingService],
})
export class AppComponent implements OnInit, OnDestroy {
  public openOldBrowserNotify = false;
  public browserName = '';
  public subscription: Subscription;
  public message = {body: '', type: ''};
  public file = {body: '', type: '', size: '', turn: false};
  public minBrowserVersionSupported = {
    firefox: 50,
    chrome: 60,
    msie: 11,
    safari: 11,
    opera: 45,
    edge: 40,
  };
  private subscriptions: Subscription[] = [];

  constructor(public toast: ToastService, private loader: LoadingService) {
    this.loader.setName('red');
    this.subscriptions.push(
      this.toast.messageSubject.subscribe((value) => {
        this.message.body = value.body;
        this.message.type = value.type;
      }),
      this.toast.fileSubject.subscribe((value) => {
        this.file.body = value.body;
        this.file.type = value.type;
        this.file.size = value.size;
        this.file.turn = value.turn;
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public ngOnInit() {
    const [name, version] = this.getBrowserVersion();
    const minSupport = this.minBrowserVersionSupported[name.toLowerCase()];
    if (minSupport && minSupport > Number(version)) {
      this.browserName = name;
      this.openOldBrowserNotify = true;
    } else if (name.toLowerCase() === 'ie') {
      this.openOldBrowserNotify = true;
    }
  }

  public onClose() {
    this.openOldBrowserNotify = false;
  }

  private getBrowserVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return ['IE', (tem[1] || '')];
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        tem[1] = tem[1].replace('OPR', 'Opera');
        return [tem[1], tem[2]];
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) {
      M.splice(1, 1, tem[1]);
    }
    return M;
  }
}
