import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver-es';
import { normalizeParams } from '@iz_shared/services/utils';

@Injectable()
export class PdfApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  /**
   *
   * @param {string} name - Имя файла !без расширения!
   * @param params - параметры запроса
   * @returns {Observable<void>}
   */
  public pair(name: string, params: { userId1: number; groupId1: number; userId2: number; groupId2: number }) {
    return this.pdfRequest('pair', name, {
      user_1: params.userId1,
      group_1: params.groupId1,
      user_2: params.userId2,
      group_2: params.groupId2,
    });
  }

  public topMatchesPdf(name: string, params: { userID: number; groupID: number; usersActive }) {
    return this.pdfRequest('top_matches', name, {
      user: params.userID,
      group: params.groupID,
      users_ids: [params.usersActive],
    });
  }

  /**
   *
   * @param {number} id
   * @param {string} name  Имя файла !без расширения!
   * @returns {Observable<Blob>}
   */
  public profile(name: string, id: number) {
    return this.pdfRequest('profile', name, {
      user: id,
    });
  }

  /**
   *
   * @param {string} name - Имя файла !без расширения!
   * @param params
   * @returns {Observable<any>}
   */
  public organizationList(
    name: string,
    params: {
      group: number;
      filter: string;
      category: string;
    },
  ) {
    return this.pdfRequest('organization_list', name, {
      group: params.group,
      filter: params.filter,
      category: params.category,
    });
  }

  /**
   *
   * @param {string} name  Имя файла !без расширения!
   * @param params
   * @returns {Observable<any>}
   */
  public organizationSummary(
    name: string,
    params: {
      group: number;
      filter: string;
      category: number;
    },
  ) {
    return this.pdfRequest('organization_summary', name, {
      group: params.group,
      filter: params.filter,
      category: params.category,
    });
  }

  /**
   * Внутренняя функция для выполнения запросов по PDF
   * @param {string} path
   * @param {string} name
   * @param params
   * @returns {Observable<any>}
   */
  private pdfRequest(path: string, name: string, params: any) {
    return this.http
      .get(`/api/pdfs/${path}/`, {
        responseType: 'blob',
        params: normalizeParams(params),
      }).pipe(
      tap((data) => {
        saveAs(data, `${name}.pdf`);
      }));
  }
}
