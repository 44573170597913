/**
 * @deprecated
 */
export default {
  user: [],
  headuser: [
    {
      url: 'assets/images/menu/sports-management.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'sports-management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  manager: [
    {
      url: 'assets/images/menu/sports-management.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'sports-management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  headmanager: [
    {
      url: 'assets/images/menu/sports-management.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'sports-management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
  ],
  entityadmin: [
    {
      url: 'assets/images/menu/sports-management.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'sports-management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
    {
      url: 'assets/images/menu/tier-management.svg',
      title: 'dashboard.menu.tierManagement',
      link: 'tier-management',
    },
  ],
  admin: [
    {
      url: 'assets/images/menu/sports-management.svg',
      title: 'dashboard.menu.sportsManagement',
      link: 'sports-management',
    },
    {
      url: 'assets/images/menu/email-messages.svg',
      title: 'dashboard.menu.emailMessages',
      link: 'email-messages',
    },
    {
      url: 'assets/images/menu/reports.svg',
      title: 'dashboard.menu.reports',
      link: 'reports',
    },
    {
      url: 'assets/images/menu/tier-management.svg',
      title: 'dashboard.menu.tierManagement',
      link: 'tier-management',
    },
    {
      url: 'assets/images/menu/admin.svg',
      title: 'dashboard.menu.admin',
      link: 'admins',
    },
  ],
  superAdmin: {
    url: 'assets/images/menu/super-admin.svg',
    title: 'dashboard.menu.superAdmin',
    link: 'super-admin',
  },
};
