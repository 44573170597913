import { Component } from '@angular/core';
/* Steps */
import { ForgotPasswordUsernameStepComponent } from './username-step/username-step.component';
import { ForgotPasswordCheckStepComponent } from './check-step/check-step.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  entryComponents: [ForgotPasswordUsernameStepComponent, ForgotPasswordCheckStepComponent],
})
export class LoginForgotPasswordComponent {
  public agreed: string = 'username-step';

  constructor() {}

  public onVoted(agreed: string) {
    this.agreed = agreed;
  }
}
