import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CopyMoveService } from '@iz_shared/components/copy-move-user/copy-move.service';
import { CopyMoveUserComponent } from './component/copy-move-button';
import { IzDirectivesModule } from '@iz_shared/directives';
import { ButtonModule } from '@iz_shared/components/button';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IzDirectivesModule,
    ButtonModule,
  ],
  declarations: [
    CopyMoveUserComponent,
  ],
  exports: [
    CopyMoveUserComponent,
  ],
  providers: [CopyMoveService],
})
export class CopyMoveModule {}
