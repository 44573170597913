import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'button[izButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    '[class]': 'classes',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() public theme: string = 'default';
  @Input() public size: string = 'default';
  @Input() public icon?: string;
  @Input() public iconAlt?: string;
  @Input() public onlyIcon: boolean = false;
  @Input() public iconPosition: 'left' | 'right' = 'left';
  @Input() public loading: boolean = false;
  @Input() public class: string = '';
  @Input() public isLoading = false;
  @Input() public isActive = false;

  constructor() {}

  public checkSvg() {
    if (this.icon) {
      return this.icon.endsWith('.svg');
    }
    return false;
  }

  public get isImage() {
    return Boolean(this.icon);
  }

  public get classes() {
    return [
      `theme--${this.theme}`,
      `size--${this.size}`,
      `icon_position--${this.iconPosition}`,
      this.classByCond('onlyIcon', this.onlyIcon),
      this.classByCond('loading', this.onlyIcon),
      this.classByCond('active', this.isActive),
      this.class,
    ]
      .filter(Boolean)
      .join(' ');
  }

  private classByCond(cn: string, cond: boolean) {
    return cond ? cn : '';
  }
}
