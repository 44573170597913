import { mapValues, pickBy } from 'lodash-es/object';
import { identity } from 'lodash-es/util';

export const normalizeParams = (params: any) => {
  return mapValues(pickBy(params, identity), (val) => {
    if (Array.isArray(val)) {
      return val.map(String);
    }
    return String(val);
  });
};
