import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { IEnvironment } from '../../../environment.type';
import { ENV_CONF_TOKEN } from '../../../iz-tokens.di';

@Injectable()
export class ApiPrefixService implements HttpInterceptor {
  constructor(@Inject(ENV_CONF_TOKEN) private environment: IEnvironment) {
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('api/')) {
      request = request.clone({
        url: this.getUri(
          this.environment.api_patch,
          request.url.replace('/api/', ''),
        ),
      });
    }
    if (request.url.includes('static/')) {
      request = request.clone({
        url: this.getUri(
          this.environment.static_patch,
          request.url.replace('/static/', ''),
        ),
      });
    }

    return next.handle(request);
  }

  protected getUri(type: string, path: string) {
    const { server_uri, project_namespace } = this.environment;
    const fullPath = [server_uri, type, project_namespace, path];
    return fullPath.filter(Boolean).join('/');
  }
}
