import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { filter, has, isArray, isObject, join, map, replace } from 'lodash-es';

import { UserRole } from '@iz_shared/models/User';

interface IDropdownItem extends Object {
  name: string;
}

@Pipe({ name: 'replacePipe' })
export class ReplaceLineBreaks implements PipeTransform {
  public transform(value: any): string {
    const val = ((value && value.name) || value).toString();
    return replace(val, /_/g, ' ');
  }
}

@Component({
  selector: 'dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  @Input() public items: Array<IDropdownItem | number | string | any> = [];
  @Input() public placeholder: string = '';
  @Input() public subTitle: boolean = false;
  @Input() public isChanged: boolean = false;
  @Input() public selectedItem: any;
  @Input() public isCleared = false;
  @Input() public multiselect: boolean = false;
  @Input() public useI18n: boolean = false;
  @Input() public useTitlecase = true;
  @Input() public canAddEditDelete = false;
  @Input() public addTitle = '';
  @Input() public disabled = false;

  @Output() public onChanged = new EventEmitter();
  @Output() public onSelected = new EventEmitter();
  @Output() public onEdited = new EventEmitter();
  @Output() public onDeleted = new EventEmitter();
  @Output() public onClear = new EventEmitter();
  @Output() public onAdd = new EventEmitter();

  public title: string;
  public toggle: boolean = false;
  public _selected: any;
  public checkbox: any = 'assets/images/common/check-mark.svg';
  public nullCheckbox: any = 'assets/images/common/empty.svg';

  constructor(private _link: ElementRef) {}

  @Input() public checkDisableChange = (item) => false;
  @Input() public checkDisableSelect = (item) => false;

  public onHandleOutsideClick = (e: Event) => {
    if (!this._link.nativeElement.contains(e.target as any)) {
      this.toggle = false;
      this.addOrRemoveListener();
    }
  }

  public onClick() {
    if (!this.disabled) {
      this.toggle = !this.toggle;
      this.addOrRemoveListener();
    }
  }

  public truncate(string) {
    if (string.length > 5) {
      return string.substring(0, 5) + '...';
    } else {
      return string;
    }
  }

  public addOrRemoveListener() {
    if (this.toggle) {
      document.addEventListener('mousedown', this.onHandleOutsideClick);
    } else {
      document.removeEventListener('mousedown', this.onHandleOutsideClick);
    }
  }

  public ngOnDestroy() {
    document.removeEventListener('mousedown', this.onHandleOutsideClick);
  }

  public ngOnInit() {
    this.placeholder = this.placeholder || ' - No Selection';
    this.items = map(this.items, (item: any) => {
      if (has(item, '_selected')) {
        return item;
      }

      if (isObject(item)) {
        return {
          ...item,
          _selected: false,
        };
      }

      return {
        name: item,
        _selected: false,
      };
    });

    this._selected = this.selectedItem || null;
  }

  public ngOnChanges() {
    this._selected = this.selectedItem || null;

    this.items = map(this.items, (item: any) => {
      if (has(item, '_selected')) {
        return item;
      }

      if (isObject(item)) {
        return {
          ...item,
          _selected: false,
        };
      }

      return {
        name: item,
        _selected: false,
      };
    });

    if (isArray(this._selected)) {
      this.items.forEach((i) => { i._selected = false; });
      this._selected.forEach((select) => {
        this.items.forEach((item) => {
          if (item.id === select.id) {
            item._selected = true;
          }
        });
      });
    }
    this.setTitle();
  }

  public setTitle() {
    if (this.multiselect) {
      const str = join(map(this._selected, 'name'), ', ') || '';
      if (str.length > 27) {
        this.title = str.substring(0, 27) + '...';
      } else {
        this.title = str;
      }
    } else {
      this.title = ((this._selected && this._selected.name) || this._selected || '').toString().replace(/\_/i, ' ');
    }
  }

  public onToggle() {
    this.toggle = true;
  }

  public setValue(selectedValue: any) {
    if (selectedValue && isObject(selectedValue) && !isArray(selectedValue)) {
      if (this.checkDisableSelect(selectedValue)) {
        return;
      }
      selectedValue._selected = !selectedValue._selected;
      if (this.multiselect) {
        this.onToggle();
        this._selected = filter(this.items, '_selected');
        this.onChanged.emit(this._selected);
      } else {
        this.toggle = false;
        this._selected = selectedValue;
        this.onChangeCallback(this._selected.value || this._selected);
        this.onSelected.emit(this._selected);
      }
    }
    this.setTitle();
  }

  public writeValue(selectedValue: string | string[]) {
    if (Array.isArray(selectedValue)) {
      // TODO: хз че
    } else {
      const item = this.items.find((el) => (el.value ? el.value === selectedValue : el.name === selectedValue));
      this.setValue(item);
    }
  }

  public trackByFn(index) {
    return index;
  }

  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched() {}

  private onChangeCallback: (_: any) => void = () => {};
}
