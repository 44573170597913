import { Injectable } from '@angular/core';
import { ProfileService } from '@iz_shared/services/profile';
import { map } from 'rxjs/operators';

@Injectable()
export class MyTeamPermissionService {
  constructor(private profile: ProfileService) {}

  public checkAccess() {
    return this.profile.getUser().pipe(map((p) => p.my_teams));
  }
}
