import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DPairComponent } from './d-pair.component';
import { TranslateModule } from '@ngx-translate/core';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzsCommonModule } from '@iz_sports/common';
import { NgDragDropModule } from 'ng-drag-drop';
import { IzcCommonModule } from '@iz_commerce/common';
import { DPairSubMenuComponent } from '@iz_shared/views/dashboard/components/d-pair/components/d-pair-sub-menu';
import { DPairInfoComponent } from '@iz_shared/views/dashboard/components/d-pair/components/d-pair-info';
import { DPairUserChartComponent } from '@iz_shared/views/dashboard/components/d-pair/components/d-pair-user-chart';
import { DPairService } from '@iz_shared/views/dashboard/components/d-pair/service';
import { DTopMatchesComponent } from './components/d-top-matches/d-top-matches.component';
import { DTopMatchesListComponent } from './components/d-top-matches-list/d-top-matches-list.component';

@NgModule({
  imports: [
    CommonModule,
    IzCommonModule,
    TranslateModule,
    IzsCommonModule,
    IzcCommonModule,
    NgDragDropModule.forRoot(),
  ],
  declarations: [
    DPairComponent,
    DPairInfoComponent,
    DPairSubMenuComponent,
    DPairUserChartComponent,
    DTopMatchesComponent,
    DTopMatchesListComponent,
  ],
  exports: [DPairComponent],
  providers: [DPairComponent, DPairService],
  bootstrap: [DPairComponent],
})
export class DPairModule {}
