import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: '[izDForm]',
  templateUrl: './d-form.component.html',
  styleUrls: ['./d-form.component.scss'],
})
export class DFormComponent implements OnInit {
  @Input() public title = '';
  @Input() public submitText = '';
  @Input() public isClosed = true;
  @Input() public isProcessing = false;

  @Output() public onClose = new EventEmitter();
  constructor() {}

  public ngOnInit() {}
}
