import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { combineLatest, map } from 'rxjs/operators';

import { User } from '@iz_shared/models/User';
import { UserDataService } from '@iz_shared/views/dashboard/services/user-data';
import { IzReportsService } from '@iz_shared/views/dashboard/modules/reports/service';
import {
  ISetFilterEvent,
} from '@iz_shared/views/dashboard/components/d-pair/components/d-top-matches-list/d-top-matches-list.component';

@Component({
  selector: 'iz-d-top-matches',
  templateUrl: './d-top-matches.component.html',
  styleUrls: ['./d-top-matches.component.scss'],
})
export class DTopMatchesComponent implements OnDestroy {
  @Input()
  public pair = {
    users: [],
    link: 'base',
  };

  @Output() public onSelect = new EventEmitter();
  @Output() public usersStayed = new EventEmitter();
  @Output() public onChangeActiveUsers = new EventEmitter();

  public filter: ISetFilterEvent = { type: null, value: null };

  public excludedUsers$ = new BehaviorSubject<User[]>([]);

  public activeUsers$;
  public concatActiveExclude: boolean = false;
  public loading: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(public userData: UserDataService, private reportsService: IzReportsService) {
    this.activeUsers$ = this.userData.topMatchesUsers.pipe(
      combineLatest(this.excludedUsers$),
      map(([usersID, excludeUsers]) => {
        let usersGroup = [];
        this.subscriptions.push(
          this.reportsService.filteredTeam.subscribe((group: any) => {
            if (group && group.users) {
              usersGroup = [];
              usersID.forEach((a: any) => {
                usersGroup.push(group.users.find((u) => u.id === a));
              });
            }
          }),
        );
        if (this.pair.users[0] !== null && this.pair.users[0] !== undefined) {
          const usersStayed = usersGroup.filter(
            (u) => u && !excludeUsers.includes(u) && u.id !== this.pair.users[0].id,
          );
          this.usersStayed.emit(usersStayed);
          return usersStayed;
        } else {
          this.loading = false;
        }
      }));

    this.subscriptions.push(
      this.reportsService.getPair.subscribe((pair) => {
        if (!pair.users[0]) {
          this.concatActiveExclude = true;
        }
      }),
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public addInExcludeList(user: User) {
    this.excludedUsers$.next([...this.excludedUsers$.getValue(), user]);
  }

  public removeFromExcludeList(user: User) {
    this.excludedUsers$.next(this.excludedUsers$.getValue().filter((u) => u.id !== user.id));
  }

  public saveFilter($event) {
    this.filter = $event;
  }

  public onSelectUser(user: User) {
    if (this.pair.users[0] !== null && this.pair.users[0] !== undefined) {
      this.onSelect.emit(user);
    }
  }
}
