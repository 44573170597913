import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss'],
})
export class SurveyQuestionComponent {
  @Input() public question: any = {};
  @Input() public questionOrder: number = 0;
  @Output() public onSelect = new EventEmitter<any>();

  constructor() {}

  public onSelectValue(value: any) {
    this.onSelect.emit(value);
  }
}
