import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GlobalSearchService {
  protected http: HttpClient = this.inject.get(HttpClient);

  constructor(private inject: Injector) { }

  public fetchSearch(search: string) {
    return this.http
      .get('/api/global_search/', {
        params: {
          search,
          disable_pagination: 'true',
        },
      });
  }
}
