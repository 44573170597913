import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserService } from '../authentication';
/* Models */
import { User } from '@iz_commerce/models/User';
import { HttpClient } from '@angular/common/http';

import { pick } from 'lodash-es';

/* Vendors */

@Injectable()
export class ForgotService {
  private user = new User();

  constructor(private userService: UserService, private request: HttpClient) {}

  public getUser(): User {
    return this.user;
  }

  public setUser(user: User) {
    this.user = user;
  }

  public sendRecoveryPassword(data) {
    return this.userService.recoveryPassword(data);
  }

  public sendRecoveryUsername(email) {
    return this.userService.recoveryUsername(email);
  }

  public sendResetPassword(data) {
    return this.userService.resetPassword(data);
  }

  public checkEmail(email: string): any {
    return this.request.post('/api/users/email_validation/', {
      email,
      project: 'commerce',
    });
  }

  public sendEmail(dataResendEmail) {
    const user = pick(dataResendEmail, ['first_name', 'last_name', 'email']);
    return this.userService.resendEmail(user).pipe(map((res) => res.json().map(() => {})));
  }
}
