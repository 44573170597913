import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-player-board-sub-menu',
  templateUrl: './player-board-sub-menu.component.html',
  styleUrls: ['./player-board-sub-menu.component.scss'],
})
export class PlayerBoardSubMenuComponent {
  @Output() public edit = new EventEmitter<any>();
  @Output() public return = new EventEmitter<any>();
  @Output() public delete = new EventEmitter<any>();
  @Output() public profile = new EventEmitter<any>();

  constructor() { }

  public emit(elm: any, emitter: EventEmitter<any>) {
    elm.click();
    emitter.emit();
  }
}
