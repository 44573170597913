import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@iz_shared/services/toast';

@Component({
  selector: 'app-forgot-password-check-step',
  templateUrl: './check-step.component.html',
  styleUrls: ['./check-step.component.scss'],
})
export class ForgotPasswordCheckStepComponent {
  constructor(public toast: ToastService, private router: Router) {
    this.toast.setMessage('Please check your email and click the link to reset your password', 'success');
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 5000);
  }
}
