import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IzsEmailApiService } from './email-api';

@NgModule({
  imports: [CommonModule],
  providers: [IzsEmailApiService, IzsEmailApiService.di],
  declarations: [],
})
export class IzsApiModule {}
