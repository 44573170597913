import { Injectable } from '@angular/core';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToastService {
  public message = { body: '', type: '' };
  public file = { body: '', type: '', size: '', turn: false };
  public messageSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public fileSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public cancelFileSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(private slimLoadingBarService: SlimLoadingBarService) {}

  public setMessage(body, type, time?) {
    this.message.body = body;
    this.message.type = type;
    this.messageSubject.next(this.message);
    setTimeout(() => {
      this.clearMessage();
    }, 3000 || time);
  }

  public setFile(body: any, type: any, size: any, loader: boolean) {
    this.file.turn = loader;
    this.slimLoadingBarService.start();
    this.slimLoadingBarService.events.subscribe((events) => {
      this.file.size = (events.value * size / 100).toFixed(2);
      this.fileSubject.next(this.file);
    });
    this.file.body = body;
    this.file.type = type;
    this.fileSubject.next(this.file);
  }

  public onClose() {
    this.file = { body: '', type: '', size: '', turn: false };
    this.slimLoadingBarService.complete();
  }

  public cancelRequest() {
    this.file = { body: '', type: '', size: '', turn: false };
    this.slimLoadingBarService.complete();
    this.cancelFileSubject.next(true);
  }

  public clearMessage() {
    this.messageSubject.next({ body: '', type: '' });
  }
}
