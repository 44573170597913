import { Observable } from 'rxjs';

export enum MenuPositions {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface IMenuItemConfig {
  role: string[];
  link: string;
  icon: {
    default: string;
    active?: string;
  };
  title: string;
  position: MenuPositions;
  access?: {
    service: any;
    checker: (service: any) => Observable<boolean>;
  };
}
