import {tap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { EmailApiService } from '@iz_shared/services/api/email-api';
import { BehaviorSubject } from 'rxjs';
import { Email, NewEmail } from '@iz_shared/models/Email';
import { EmailTemplate } from '@iz_shared/models/EmailTemplate';
import { ITemplateFetchParams } from '@iz_shared/services/api/email-api/email-api.service';

export interface IEmailMass {
  email_title?: string;
  email_status?: boolean;
  email_message?: string;
  email_closing?: string;
  email_signature?: string;
  email_template?: number;
  send_frequency?: string;
  frequency_type?: number;
  frequency_count?: number;
  is_reminder?: boolean;
}

@Injectable()
export class EmailDataService {
  protected emailApi: EmailApiService = this.inject.get(EmailApiService);
  protected _emailList: BehaviorSubject<Email[]> = new BehaviorSubject<Email[]>([]);

  protected _listTemplate: BehaviorSubject<EmailTemplate[]> = new BehaviorSubject<EmailTemplate[]>([]);

  protected emailMass: BehaviorSubject<IEmailMass> = new BehaviorSubject<IEmailMass>(null);

  protected reminderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private inject: Injector) {}

  public setEmailMassData(template: IEmailMass) {
    this.emailMass.next(template);
  }

  public getEmailMassData() {
    return this.emailMass.asObservable();
  }

  public updateReminderStatus(value) {
    this.reminderStatus.next(value);
  }

  public getReminderStatus() {
    return this.reminderStatus.getValue();
  }

  public get emailList() {
    return this._emailList.asObservable();
  }

  public get templateList() {
    return this._listTemplate.asObservable();
  }

  public fetchHistory(id: number) {
    return this.emailApi.getHistory(id).pipe(tap((data) => {
      this._emailList.next(data.results);
    }));
  }

  public fetchTempalates(params?: ITemplateFetchParams) {
    return this.emailApi.getTemplates(params).pipe(tap((data) => {
      this._listTemplate.next(data.results);
    }));
  }

  public getTemplate(id: number) {
    return this.emailApi.getTemplate(id);
  }

  public updateTemplate(id: number, template) {
    return this.emailApi.updateTemplate(id, template).pipe(tap((res) => {
      this._listTemplate.next(
        this._listTemplate.getValue().map((et) => {
          if (et.id === res.id) {
            return res;
          }
          return et;
        }),
      );
    }));
  }

  public createTemplate(template) {
    return this.emailApi.createTemplate(template).pipe(tap((data) => {
      this._listTemplate.next([...this._listTemplate.getValue(), data]);
    }));
  }

  public deleteTemplate(id: number) {
    return this.emailApi.deleteTemplate(id).pipe(tap(() => {
      this._listTemplate.next(this._listTemplate.getValue().filter((et) => et.id !== id));
    }));
  }

  public create(email: NewEmail, sender?) {
    return this.emailApi.createEmail(email, sender).pipe(tap((data) => {
      this._emailList.next([...this._emailList.getValue(), data]);
    }));
  }
}
