export class User {
  public id: number;
  public city: string;
  public birth_date: string;
  public role: string;
  public email: string;
  public title: string;
  public phone: string;
  public state: string;
  public country: string;
  public address: string;
  public survey: string;
  public project: string;
  public verify: boolean;
  public zip_code: string;
  public category: string;
  public username: string;
  public password: string;
  public primarily?: string[];
  public last_name: string;
  public first_name: string;
  public is_active: boolean;
  public _selected: boolean;
  public entity_name: string;
  public terms_of_use: string;
  public verifyPassword: string;
  public number?: string;
  public is_invitation_sent: string;
  public information_sharing: boolean;
  public receive_notifications: boolean;
  public updated: string;
  public guardian_action_date: string;
  public guardian_title: string;
  public guardian_email: string;
  public guardian_first_name: string;
  public guardian_last_name: string;
  public guardian_acceptance: string;
  public localRole: string;
  public profile_percentages?: any;
  public profile?: any;

  constructor() {}
}

export class User2 extends User {
  public is_reminder?: boolean;
}

export enum UserSystemRoles {
  SUPER_ADMIN = 'admin',
  ENTITY_ADMIN = 'entityadmin',
  DIRECTOR = 'headmanager',
  SUBDIRECTOR = 'manager',
  HEADUSER = 'headuser',
  USER = 'user',
}
