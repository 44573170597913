import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationService } from './registration';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [RegistrationService],
})
export class IzRegistrationServicesModule {}
