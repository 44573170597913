import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
/* Services */
import { ProfileService } from '@iz_shared/services/profile';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardActivateService implements CanActivate {

  protected profile: ProfileService = this.inject.get(ProfileService);
  protected router: Router = this.inject.get(Router);

  constructor(private inject: Injector) {}

  public canActivate(): Observable<boolean> {
    if (!this.profile.checkSession()) {
      this.router.navigate(['/login']);
      return;
    }
    return new Observable<boolean>((resolve) => {
      this.profile.getUser().subscribe((user) => {
        if (user) {
          resolve.next(true);
        }
      }, () => {
        this.router.navigate(['/login']);
        resolve.next(false);
      });
    });
  }
}
