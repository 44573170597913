import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
/* Modules */
import { User } from '@iz_shared/models/User';
/* Services */
import { ProfileService } from '@iz_shared/services/profile';
import { ParentalConsentService } from '@iz_shared/views/login/services/parental-consent';

@Component({
  selector: 'app-resend-email-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss'],
})
export class ResendEmailFormStepComponent {
  public form: FormGroup;
  public guardian_first_name: AbstractControl;
  public guardian_last_name: AbstractControl;
  public guardian_email: AbstractControl;
  public submitted = false;
  public error: any = {};
  public isResendEmailLoading: boolean = false;
  @Output() public onVoted = new EventEmitter<string>();

  private user: User;

  constructor(
    private fb: FormBuilder,
    private parentalConsent: ParentalConsentService,
    private profile: ProfileService,
  ) {
    this.form = fb.group({
      guardian_first_name: ['', Validators.compose([Validators.required])],
      guardian_last_name: ['', Validators.compose([Validators.required])],
      guardian_email: ['', Validators.compose([Validators.required])],
    });

    this.guardian_first_name = this.form.controls.guardian_first_name;
    this.guardian_last_name = this.form.controls.guardian_last_name;
    this.guardian_email = this.form.controls.guardian_email;

    this.profile.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  public onEditEmail() {
    this.form.controls.guardian_email.markAsPristine();
  }

  public onSubmit(values: object): void {
    this.submitted = true;
    if (this.form.valid) {
      this.isResendEmailLoading = true;
      this.error = null;
      this.parentalConsent.updateParent(this.user.id, values).subscribe(
        () => {
          this.onVoted.emit('wait-step');
          this.isResendEmailLoading = false;
        },
        (err) => {
          this.error = err.error;
          this.form.controls.guardian_email.setErrors({
            invalidEmail: true,
          });
          this.isResendEmailLoading = false;
        },
      );
    }
  }
}
