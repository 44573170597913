import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IzDirectivesModule } from './directives';
import { IzComponentsModule } from './components';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar-observables';
import { IzTranslationModule } from './iz-translation.module';
import { ModalModule } from '@iz_shared/components/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IzDirectivesModule,
    IzComponentsModule,
    LazyLoadImageModule,
    AngularSvgIconModule.forRoot(),
    SlimLoadingBarModule,
    IzTranslationModule,
    ModalModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IzDirectivesModule,
    IzComponentsModule,
    LazyLoadImageModule,
    AngularSvgIconModule,
    SlimLoadingBarModule,
    IzTranslationModule,
    ModalModule],
})
export class IzCommonModule {
}
