import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthenticationService } from '../../authentication';

@Injectable()
export class ApiTokenService implements HttpInterceptor {
  constructor(public auth: AuthenticationService) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token.access_token,
        },
      });
    }
    return next.handle(request);
  }
}
