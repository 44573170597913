import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDynamicContentComponent } from './form-dynamic-content.component';
import { FormFieldModule } from '@iz_shared/components/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '@iz_shared/components/dropdown';
import { InputModule } from '@iz_shared/components/input';
import { ToggleModule } from '@iz_shared/components/toggle';
import { TranslateModule } from '@ngx-translate/core';
import { IzDirectivesModule } from '@iz_shared/directives';

@NgModule({
  imports: [
    FormsModule,
    InputModule,
    ToggleModule,
    CommonModule,
    FormFieldModule,
    DropdownModule,
    TranslateModule,
    IzDirectivesModule,
    ReactiveFormsModule,
  ],
  declarations: [FormDynamicContentComponent],
  exports: [FormDynamicContentComponent],
})
export class FormDynamicContentModule {}
