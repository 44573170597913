import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IzsApiModule } from './api';

@NgModule({
  imports: [CommonModule, IzsApiModule],
  declarations: [],
})
export class IzsSharedServicesModule {
}
