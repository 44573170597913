import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DFormComponent } from './d-form.component';
import { IzCommonModule } from '@iz_shared/iz-common.module';

@NgModule({
  imports: [CommonModule, IzCommonModule],
  declarations: [DFormComponent],
  exports: [DFormComponent],
})
export class DFormModule {}
