import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
/* Vendors */
import { get, merge } from 'lodash-es';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements AfterViewChecked, OnInit, OnDestroy {
  @Input() public source: any[] = [];
  @Input() public settings: any = {};
  @Input() public hideScroll: boolean = false;
  public _settings: any = {
    width: '100%',
    height: '100%',
    columns: [],
  };
  private listenSubscribeMove: () => void;
  private listenSubscribeLeave: () => void;
  @ViewChild('gridBlock', { static: true }) private gridBlock;

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
    this.listenSubscribeMove = renderer.listen(elementRef.nativeElement, 'mousemove', ($event) => {
      this.onHover($event);
    });
    this.listenSubscribeLeave = renderer.listen(elementRef.nativeElement, 'mouseleave', () => {});
  }

  public ngOnDestroy() {
    this.listenSubscribeMove();
    this.listenSubscribeLeave();
    this.gridBlock.nativeElement.remove();
    this.gridBlock.nativeElement.innerHTML = '';
  }

  public ngAfterViewChecked() {
    const columns = get(this.settings, 'columns', []);
    if (columns.length) {
      const $rows = this.gridBlock.nativeElement.querySelectorAll(
        'app-table-rows',
      );
      const $columns = this.gridBlock.nativeElement.querySelectorAll(
        'app-table-column',
      );

      if (
        columns.length === $rows.length &&
        columns.length === $columns.length
      ) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < $rows.length; i++) {
          ($rows[i] as any).style.minWidth = columns[i].width;
          ($columns[i] as any).style.minWidth = columns[i].width;
        }
      }
    }
  }

  public ngOnInit() {
    this._settings = merge(this._settings, this.settings);
  }

  public onLeave() {
    const query = document.querySelectorAll('app-table-row');

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < query.length; i++) {
      query[i].classList.remove('tabHover');
    }
  }

  public onHover($event) {
    this.onLeave();
    const parent = this.inContainer($event.target);

    if (parent) {
      const row = get(parent, 'parentElement.parentElement', {});
      const column = get(row, 'parentElement', {});
      const table = get(
        column,
        'parentElement.parentElement.parentElement',
        {},
      );

      let index = -1;

      for (let i = 0; i < column.children.length; i++) {
        if (column.children[i] === row) {
          index = ++i;
          break;
        }
      }

      if (index !== -1) {
        const query = table.querySelectorAll(
          `app-table-row:nth-of-type(${index})`,
        );

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < query.length; i++) {
          query[i].classList.add('tabHover');
        }
      }
    }
  }

  private inContainer(target): boolean {
    const query = document.querySelectorAll('.table-container-row');
    let check;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < query.length; i++) {
      if (query[i] === target || query[i].contains(target)) {
        check = query[i];
        break;
      }
    }

    return check;
  }
}
