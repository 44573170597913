import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

/* Main component */
import { PagesComponent } from './pages.component';

/* Modules */
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzsCommonModule } from '@iz_sports/common';
import { DashboardModule } from './dashboard';
import { LoginModule } from './login/login.module';
import { RegistrationModule } from './registration';
import { PagesRoutingModule } from './pages.routing';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

/* Components */
import { PageNotFoundComponent } from './page-not-found';
import { PageTermsOfServiceComponent } from './page-terms-of-service';
import { TermsOfServiceComponent } from './terms-of-service';

/* Service */
import { GroupDataService } from '@iz_shared/views/dashboard/services/group-data';
import { UserDataService } from '@iz_shared/views/dashboard/services/user-data';
import { SportsDataService } from '@iz_shared/views/dashboard/services/sports-data';
import { IzProfileActivateService } from '@iz_shared/views/dashboard/modules/iz-profile/services/iz-activate';

@NgModule({
  imports: [
    LoginModule,
    RouterModule,
    CommonModule,
    DashboardModule,
    IzsCommonModule,
    IzCommonModule,
    NgScrollbarModule,
    PagesRoutingModule,
    RegistrationModule,
    TranslateModule,
  ],
  declarations: [
    PagesComponent,
    PagesComponent,
    PageNotFoundComponent,
    // TermsOfServiceComponent,
    PageTermsOfServiceComponent,
  ],
  providers: [
    GroupDataService,
    UserDataService,
    SportsDataService,
    IzProfileActivateService,
  ],
  bootstrap: [PagesComponent],
})
export class PagesModule {}
