import { Injectable } from '@angular/core';
import { ProfileService } from '@iz_shared/services/profile';
import { CanActivate } from '@angular/router';

@Injectable()
export class IzProfileActivateService implements CanActivate {

  constructor(private profileService: ProfileService) {}

  public canActivate() {
    if (this.profileService.getGuard()) {
      return true;
    } else {
      return false;
    }
  }
}
