import {Component, EventEmitter, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
/* Services */
import {ProfileService} from '@iz_shared/services/profile';
import { ParentalConsentService } from '@iz_shared/views/login/services/parental-consent';

@Component({
  selector: 'app-parental-consent-form-step',
  templateUrl: './form-step.component.html',
  styleUrls: ['./form-step.component.scss'],
})
export class ParentalConsentFormStepComponent {
  @Output() public onVoted = new EventEmitter<string>();
  public form: FormGroup;
  public guardian_first_name: AbstractControl;
  public guardian_last_name: AbstractControl;
  public guardian_email: AbstractControl;
  public error: any = {};
  public submitted = false;
  public isContinueLoading: boolean = false;

  private user: any;

  constructor(private fb: FormBuilder,
              private parentalContent: ParentalConsentService,
              private profile: ProfileService) {
    this.form = fb.group({
      guardian_first_name: ['', Validators.compose([Validators.required])],
      guardian_last_name: ['', Validators.compose([Validators.required])],
      guardian_email: ['', Validators.compose([Validators.required])],
    });

    this.guardian_first_name = this.form.controls.guardian_first_name;
    this.guardian_last_name = this.form.controls.guardian_last_name;
    this.guardian_email = this.form.controls.guardian_email;

    this.profile.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  public onEditEmail() {
    this.form.controls.guardian_email.markAsPristine();
  }

  public onSubmit(values: object): void {
    this.submitted = true;
    if (this.form.valid) {
      this.isContinueLoading = true;
      this.error = null;
      this.parentalContent.updateParent(this.user.id, values).subscribe(() => {
        this.onVoted.emit('wait-step');
        this.isContinueLoading = false;
      }, (err) => {
        this.error = err.error;
        this.form.controls.guardian_email.setErrors({
          invalidEmail: true,
        });
        this.isContinueLoading = false;
      });
    }
  }
}
