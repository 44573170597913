import { Injectable } from '@angular/core';
import { User } from '@iz_shared/models/User';
import { Role } from '@iz_shared/models/Role';

@Injectable()
export class PermissionService {

  constructor() {
  }

  public getUserRole(user: User, roles: Role[]) {
    if (roles) {
      const role = roles.find((r) => r.user === user.id);
      return role ? role.role : '';
    }
    return '';
  }

}
