import { IMenuItemConfig, MenuPositions } from '@iz_shared/models/Configs';
import { UserRole } from '@iz_shared/models/User';

export const settings: IMenuItemConfig[] = [
  {
    link: 'management',
    role: [
      UserRole.SUPER_ADMIN.toString(),
      UserRole.ENTITY_ADMIN.toString(),
      UserRole.HEAD_MANAGER.toString(),
      UserRole.MANAGER.toString(),
      UserRole.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/sports-management.svg',
      active: 'assets/images/menu/sports-management-active.svg',
    },
    title: 'dashboard.menu.sportsManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'email-messages',
    role: [
      UserRole.SUPER_ADMIN.toString(),
      UserRole.ENTITY_ADMIN.toString(),
      UserRole.HEAD_MANAGER.toString(),
      UserRole.MANAGER.toString(),
      UserRole.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/email-messages.svg',
      active: 'assets/images/menu/email-messages-active.svg',
    },
    title: 'dashboard.menu.emailMessages',
    position: MenuPositions.TOP,
  },
  {
    link: 'reports',
    role: [
      UserRole.SUPER_ADMIN.toString(),
      UserRole.ENTITY_ADMIN.toString(),
      UserRole.HEAD_MANAGER.toString(),
      UserRole.MANAGER.toString(),
      UserRole.HEADUSER.toString()
    ],
    icon: {
      default: 'assets/images/menu/reports.svg',
      active: 'assets/images/menu/reports-active.svg',
    },
    title: 'dashboard.menu.reports',
    position: MenuPositions.TOP,
  },
  {
    link: 'tier-management',
    role: [
      UserRole.SUPER_ADMIN.toString(),
      UserRole.ENTITY_ADMIN.toString()
    ],
    icon: {
      default: 'assets/images/menu/dept-management.svg',
      active: 'assets/images/menu/dept-management-active.svg',
    },
    title: 'dashboard.menu.tierManagement',
    position: MenuPositions.TOP,
  },
  {
    link: 'admins',
    role: [UserRole.SUPER_ADMIN.toString()],
    icon: {
      default: 'assets/images/menu/admin.svg',
      active: 'assets/images/menu/admin.svg',
    },
    title: 'dashboard.menu.admin',
    position: MenuPositions.TOP,
  },
  {
    link: 'super-admin',
    role: [UserRole.SUPER_ADMIN.toString()],
    icon: {
      default: 'assets/images/menu/super-admins.svg',
      active: 'assets/images/menu/super-admins-active.svg',
    },
    title: 'dashboard.menu.superAdmin',
    position: MenuPositions.BOTTOM,
  },
];
