import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ProfileService } from '@iz_shared/services/profile';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public profile: ProfileService, private router: Router) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.profile.getUser().pipe(
      map(Boolean),
      tap((access) => {
        if (!access) {
          this.router.navigate(['/login']);
        }
      }),
    );
  }
}
