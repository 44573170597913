import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent {
  @Input() public menu?: any[] = [];
  @Input() public activeMenu?: number = -1;
  @Input() public url: string = '';
  @Input() public name: string = '';
  @Input() public nonActive: boolean = false;
  @Input() public filterClass: string = "table-filter-btn";
  @Output() public onClick = new EventEmitter<any>();
  @Output() public onSelectMenu = new EventEmitter<any>();
  public isToggle: boolean = false;

  constructor() {
  }

  public toParent() {
    if (!this.menu.length) {
      this.isToggle = !this.isToggle;
      this.onClick.emit();
    }
  }

  public emit(elm: any, menu: any) {
    elm.click();
    if (menu.emit) {
      menu.emit();
    } else {
      this.onSelectMenu.emit(menu.title);
    }
  }
}
