import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@iz_shared/models/User';
import { IApiRes } from '@iz_shared/models/Response';
import { Group } from '@iz_shared/models/Group';
import { GroupPurchaseInfo } from '@iz_shared/models/GroupFull';
import { IStripeSuccess, Packet, PacketInfo } from '@iz_shared/models/Purchase';

@Injectable()
export class TeamApiService {
  constructor(private readonly _http: HttpClient) {
  }

  public getPackets() {
    return this._http.get<IApiRes<PacketInfo[]>>('/api/packets/');
  }

  public getCustomers() {
    return this._http.get('/api/customers/');
  }

  public getCustomer(id: User['id']) {
    return this._http.get(`/api/customers/${id}/`);
  }

  public getPurchases(id: Group['id']) {
    return this._http.get<IApiRes<Packet[]>>(`/api/purchases/?group=${id}`);
  }

  public getPurchaseInfo(id: Group['id']) {
    return this._http.get<GroupPurchaseInfo>(`/api/groups/${id}/purchase_info/`);
  }

  public createTeam(token: IStripeSuccess, packet: number, group_data: any, automatic_renewals: boolean) {
    return this._http.post('/api/purchases/packet/', {
      token,
      packet,
      group_data,
      automatic_renewals,
    });
  }

  public buyBucket(
    token: IStripeSuccess,
    packet: number,
    group: number,
    buckets_count: number,
    automatic_renewals: boolean,
  ) {
    return this._http.post('/api/purchases/bucket/', {
      token,
      packet,
      group,
      buckets_count,
      automatic_renewals,
    });
  }

  public changeCard(token: IStripeSuccess, group: number, customer: number) {
    return this._http.patch(`/api/customers/${customer}/`, {
      token,
      group,
      customer,
    });
  }

  public updatePurchase(id: number, data: any) {
    return this._http.patch(`/api/purchases/${id}/`, data);
  }

  public renew(
    purchase: number,
    packet: PacketInfo['id'],
    group: Group['id'],
    token: IStripeSuccess,
    automatic_renewals: boolean,
  ) {
    return this._http.post(`/api/purchases/${purchase}/renew/`, {
      group,
      packet,
      token,
      automatic_renewals,
    });
  }

  public buyPacket(data: any) {
    return this._http.post(`/api/purchases/packet/`, data);
  }

  public setPacketByAdmin(group: Group['id'], packet: PacketInfo['id']) {
    return this._http.patch(`/api/groups/${group}/users_limit/`, {
      packet,
    });
  }

  public createPurchase(data: any) {
    return this._http.post(`/api/purchases/`, data);
  }
}
