import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-registration-steps',
  templateUrl: './registration-steps.component.html',
  styleUrls: ['./registration-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationStepsComponent {
  @Input() public step: string = 'first';

  constructor() {}
}
