import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SlimLoadingBarService } from 'ng2-slim-loading-bar-observables';
import { ToastService } from '@iz_shared/services/toast';
import { ProfileService } from '@iz_shared/services/profile';
import { UserSystemRoles } from '@iz_sports/models/User';

@Component({
  selector: 'app-page-terms-of-service',
  templateUrl: './page-terms-of-service.component.html',
  styleUrls: ['./page-terms-of-service.component.scss'],
})
export class PageTermsOfServiceComponent {
public isConfirmLoading: boolean = false;
public isLoading: boolean = false;

  constructor(private router: Router,
              private toast: ToastService,
              private service: ProfileService,
              private slimLoadingBarService: SlimLoadingBarService) {
  }

  public onConfirm(confirm) {
    if (confirm) {
      this.isConfirmLoading = true;
      this.service.updateTermsOfUse((new Date()).toISOString()).subscribe((user: any) => {
        this.toast.setMessage('Confirm successful', 'success');
        if (user.birth_date) {
          const range = (
            (new Date() as any) - (new Date(user.birth_date.split('T')[0]) as any)
          ) / 1000 / 60 / 60 / 24 / 366;

          if (range < 18 && user.role === UserSystemRoles.USER.toString()) {
            if (user.survey) {
              if (user.role === UserSystemRoles.USER.toString()) {
                this.router.navigate(['/dashboard/innerzone-profile']);
              } else {
                this.router.navigate(['/dashboard/sports-management']);
              }
            } else {
              this.router.navigate(['/dashboard/questionnaire']);
            }
          } else {
            if (user.survey) {
              if (user.role === UserSystemRoles.USER.toString()) {
                this.router.navigate(['/dashboard/innerzone-profile']);
              } else {
                this.router.navigate(['/dashboard/sports-management']);
              }
            } else {
              this.router.navigate(['/dashboard/questionnaire']);
            }
          }
        } else {
          this.router.navigate(['/date-of-birth']);
        }
        this.isConfirmLoading = false;
      }, (err) => {
        this.toast.setMessage(err.error.non_field_errors[0], 'danger');
        this.isConfirmLoading = false;
      }, () => {
        this.slimLoadingBarService.complete();
        this.isConfirmLoading = false;
      });
    } else {
      this.isLoading = true;
      localStorage.clear();
      this.service.setUser(null);
      this.router.navigate(['/login']);
      this.isLoading = false;
    }
  }
}
