import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { UserSystemRoles } from '@iz_sports/models/User';
import { DashboardActivateService } from '@iz_shared/views/dashboard/services/dashboard-activate';
import { UserGuardService } from '@iz_shared/services/user-guard';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [DashboardActivateService],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'innerzone-profile',
      },
      {
        path: 'super-admin',
        loadChildren: () => import('./views/super-admin/super-admin.module').then((m) => m.SuperAdminModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString()],
          roles: UserSystemRoles.SUPER_ADMIN.toString(),
          color: '#BA0014',
        },
        canActivate: [UserGuardService],
      },
      // {
      //   path: 'teams',
      //   loadChildren: './views/teams/teams.module#TeamsModule',
      //   data: {
      //     accessRoles: [
      //      UserSystemRoles.SUPER_ADMIN.toString(),
      //      UserSystemRoles.ENTITY_ADMIN.toString(),
      //      UserSystemRoles.DIRECTOR.toString(),
      //      UserSystemRoles.SUBDIRECTOR.toString(),
      //      UserSystemRoles.HEADUSER.toString()
      //     ],
      //   },
      //   canActivate: [UserGuardService],
      // },
      {
        path: 'my-account',
        loadChildren: () => import('./views/my-account/my-account.module').then((m) => m.MyAccountModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'innerzone-profile',
        loadChildren: () => import('./views/innerzone-profile/innerzone-profile.module')
          .then((m) => m.InnerzoneProfileModule),
        data: {
          accessRoles: [UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString(),
                        UserSystemRoles.USER.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'questionnaire',
        loadChildren: () => import('./views/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
        data: {
          accessRoles: [UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString(),
                        UserSystemRoles.USER.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'tier-management',
        loadChildren: () => import('./views/tier-management/tier-management.module')
          .then((m) => m.TierManagementModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'admins',
        loadChildren: () => import('./views/admins/admins.module').then((m) => m.AdminsModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString()],
          roles: [UserSystemRoles.DIRECTOR.toString(), UserSystemRoles.SUBDIRECTOR.toString()],
          color: '#1e1e1e',
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'email-messages',
        loadChildren: () => import('./views/email-messanges/email-messages.module').then((m) => m.EmailMessagesModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then((m) => m.ReportsModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString()],
        },
        canActivate: [UserGuardService],
      },
      {
        path: 'sports-management',
        loadChildren: () => import('./views/sports-management/sports-management.module')
          .then((m) => m.SportsManagementModule),
        data: {
          accessRoles: [UserSystemRoles.SUPER_ADMIN.toString(),
                        UserSystemRoles.ENTITY_ADMIN.toString(),
                        UserSystemRoles.DIRECTOR.toString(),
                        UserSystemRoles.SUBDIRECTOR.toString(),
                        UserSystemRoles.HEADUSER.toString()],
        },
        canActivate: [UserGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
