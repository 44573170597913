import { Injectable } from '@angular/core';
import { UserService } from './user.service';
/* Models */
/* Vendors */
import { pick } from 'lodash-es';
import { User } from '@iz_shared/models/User';

@Injectable()
export class AuthService {
  private user = new User();

  constructor(private userService: UserService) {
  }

  public getUser(): User {
    return this.user;
  }

  public setUser(user: User) {
    this.user = user;
  }

  public login(emailAndPassword) {
    const user = pick(emailAndPassword, [
      'username',
      'password',
    ]);
    return this.userService.login(user);
  }

  public loginByToken(token: string) {
    return this.userService.loginByToken(token);
  }
}
