import { Component } from '@angular/core';

@Component({
  selector: 'iz-title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.scss'],
})
export class TitleBoxComponent {
  constructor() {}
}
