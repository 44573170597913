import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizeDirective } from './resize';
import { OnlyNumberDirective } from './only-number';
import { HighlightDirective } from './highlight';
import { SpaceTrimDirective } from './spacetrim';
import { LoadingDirective } from '@iz_shared/directives/loading';
import { OnlyIntegerDirective } from './only-integer';
import { CardNumberMaskDirective } from './card-number-mask';
import { CardholderDirective } from './cardholder';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ResizeDirective,
    OnlyNumberDirective,
    HighlightDirective,
    SpaceTrimDirective,
    LoadingDirective,
    OnlyIntegerDirective,
    CardNumberMaskDirective,
    CardholderDirective,
  ],
  exports: [
    ResizeDirective,
    OnlyNumberDirective,
    HighlightDirective,
    SpaceTrimDirective,
    LoadingDirective,
    OnlyIntegerDirective,
    CardNumberMaskDirective,
    CardholderDirective,
  ],
})
export class IzDirectivesModule {}
