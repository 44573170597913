import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface ICalcPercentParams {
  [key: string]: number;
}

@Injectable()
export class UtilsApiService {
  protected http: HttpClient = this.inject.get(HttpClient);
  constructor(private inject: Injector) {}

  /**
   * Эта функция делает запрос на сервер и получает округленные значения в процентах,
   * используется для совпадения процентов на бэке и на фронте
   * @param {ICalcPercentParams} dict - словарь, ключ - строка, значение - число
   * @returns {Observable<ICalcPercentParams>} - ответ в виде переданного словаря
   * округленного в процентах
   */
  public calculateRoundPercent(dict: ICalcPercentParams) {
    return this.http.post<ICalcPercentParams>('/api/utils/percents/', dict);
  }
}
