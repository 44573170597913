import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'd-pair-sub-menu',
  templateUrl: './d-pair-sub-menu.component.html',
  styleUrls: ['./d-pair-sub-menu.component.scss'],
})
export class DPairSubMenuComponent {
  @Output() public save = new EventEmitter<any>();
  @Output() public delete = new EventEmitter<any>();
  @Output() public optional = new EventEmitter<any>();

  constructor() { }

  public emit(elm: any, emitter: EventEmitter<any>) {
    elm.click();
    emitter.emit();
  }
}
