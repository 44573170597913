import { Component } from '@angular/core';
import { Router } from '@angular/router';
/* Services */
import { ProfileService } from '@iz_shared/services/profile';
import { AuthenticationService } from '@iz_shared/services/authentication';
import { ToastService } from '@iz_shared/services/toast';

@Component({
  selector: 'app-parental-consent-wait-step',
  templateUrl: './wait-step.component.html',
  styleUrls: ['./wait-step.component.scss'],
})
export class ParentalConsentWaitStepComponent {
  constructor(private router: Router,
              private profile: ProfileService,
              private auth: AuthenticationService,
              public toast: ToastService) {
  }

  public toLogin() {
    localStorage.clear();
    this.profile.setUser(null);
    this.auth.setToken(null);

    this.router.navigate(['/login']);
    this.toast.setMessage('Parent Consent updated', 'success');
  }
}
