import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingService } from '@iz_shared/components/loading/service';
import { LoadingComponent } from '@iz_shared/components/loading/component';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [LoadingComponent],
  exports: [LoadingComponent],
  providers: [LoadingService],
})
export class LoadingModule { }
