import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISport } from '@iz_commerce/models/Sport';
import { Group } from '@iz_shared/models/Group';
import { GroupFull } from '@iz_shared/models/GroupFull';
import { GroupApiService, IGroupListParams } from '@iz_shared/services/api/group-api';
import { IGroupParams } from '@iz_shared/services/api/group-api';

@Injectable()
export class GroupDataService {
  public _groupData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  public _divData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  public _deptData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  public _workData$: BehaviorSubject<GroupFull | undefined> = new BehaviorSubject<GroupFull | undefined>(void 0);
  protected _list$: BehaviorSubject<Group[]> = new BehaviorSubject<Group[]>([]);
  protected _sports$ = new BehaviorSubject<ISport[]>([]);

  protected groupApi: GroupApiService = this.inject.get(GroupApiService);
  constructor(private inject: Injector) {}

  public fetchGroups(params?: IGroupListParams) {
    return this.groupApi.getList(params).pipe(tap((data) => {
      this._list$.next((data as any).results);
    }));
  }

  public get list$() {
    return this._list$.asObservable();
  }

  public fetchGroup(id: number, params?: IGroupParams, setGroupData = true) {
    return this.groupApi.get(id, params).pipe(tap((data: any) => {
      if (data.roles.length === data.users.length) {
        if (data.level === 'division') {
          this._divData$.next(data);
        } else if (data.level === 'dept') {
          this._deptData$.next(data);
        } else if (data.level === 'workgroup') {
          this._workData$.next(data);
        }
      }
      if (setGroupData) {
        this._groupData$.next(data);
      }
    }));
  }

  public fetchReport(id: number, params?: IGroupParams) {
    return this.groupApi.report(id, params).pipe(tap((data: any) => {
      this._groupData$.next(data);
    }));
  }

  public deleteGroup(id: number) {
    return this.groupApi.delete(id).pipe(tap(() => {
      this._list$.next(this._list$.getValue().filter((el) => el.id !== id));
    }));
  }

  public updateGroup(id: number, group: any, administrators?: any) {
    return this.groupApi.update(id, group, administrators).pipe(tap((data) => {
      this._list$.next(
        this._list$.getValue().map((el) => {
          if (el.id === data.id) {
            return data;
          }
          return el;
        }),
      );
    }));
  }

  public getTemplateFile(id: number) {
    return this.groupApi.getTemplate(id);
  }

  public setUploadExcel(file: any, id: any) {
    return this.groupApi.uploadExcel(file, id);
  }

  public addGroup(group: any, params?: { entity: number, included_fields?: string }) {
    return this.groupApi.create(group, params).pipe(tap((data) => {
      this._list$.next([...this._list$.getValue(), data]);
    }));
  }

  public get groupData$() {
    return this._groupData$.asObservable();
  }


  public divDataValue() {
    return this._divData$.getValue();
  }

  public deptDataValue() {
    return this._deptData$.getValue();
  }

  public workDataValue() {
    return this._workData$.getValue();
  }

  public fetchSports() {
    return this.groupApi.sports().pipe(tap((data) => {
      this._sports$.next(data.results);
    }));
  }

  public get sports$() {
    return this._sports$.asObservable();
  }
}
