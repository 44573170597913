import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
/* Vendors */
import { cloneDeep, get, includes, map as l_map, remove } from 'lodash-es';
import { DPairService } from '@iz_shared/views/dashboard/components/d-pair/service';

import { IDropdownItem } from '@iz_shared/components/smart-dropdown/smart-dropdown.component';
import { UserDataService } from '@iz_shared/views/dashboard/services/user-data';
import { GroupDataService } from '@iz_shared/views/dashboard/services/group-data';
import { IzReportsService } from '@iz_shared/views/dashboard/modules/reports/service';
import { User } from '@iz_shared/models/User';

export enum ChemistryTypes {
  PAIR_CHEMISTRY = 'dashboard.reports.chemistry_types.pair_chemistry',
  TOP_MATHES = 'dashboard.reports.chemistry_types.top_matches',
}

import { ToastService } from '@iz_shared/services/toast';
import { map } from 'rxjs/operators';

@Component({
  selector: 'd-pair',
  templateUrl: './d-pair.component.html',
  styleUrls: ['./d-pair.component.scss'],
})
export class DPairComponent implements OnInit, OnChanges, OnDestroy {
  public links: any = [];
  public moderate: any = [];
  public weakest: any = [];
  public strongest: any = [];
  public usersStayed: any;
  public pair: any = {
    users: [],
    groups: [],
    link: 'base',
  };
  public isDisplayRelation: boolean = false;
  public loadingUpdate: boolean = false;
  public relationDescription: any;
  public lastDropIndex: number;
  public length = {};
  public mockEmptyObject = {
    link: null,
    users: [{
      first_name: false, last_name: false,
    }],
    hideMenu: true,
  };
  public isSavePdfLoading: boolean = false;
  public isRelationLoading: boolean = false;
  public stateLastPair: boolean = false;
  public _selectedChemistry = ChemistryTypes.PAIR_CHEMISTRY;
  @Input() public teams: any;
  @Input() public teamId: any;
  @Input() public title = '';
  @Input() public selectedUsers: number[] = [];
  @Input() public isClosed = false;
  @Input() public useFilter = false;
  @Input() public onlyChemistry = false;
  @Output() public updatePair = new EventEmitter();
  @Output() public openRelation = new EventEmitter();
  @Output() public savePDF = new EventEmitter();
  @Output() public onClose = new EventEmitter();
  public get hasOnlyFirstUser(): boolean {
    return !this.pair.users[1] && this.isChemistry;
  }
  public get hasPairOrTopMatches(): boolean {
    return this.pair.users[0] && !this.hasOnlyFirstUser;
  }
  public get hasBothUsers(): boolean {
    return this.pair.users[0] && this.pair.users[1];
  }
  public get oneUserOrEmptyTopMatches(): boolean {
    return !this.hasBothUsers && this.isChemistry || this.isEmptyTopMatches;
  }
  public get isEmptyTopMatches(): boolean {
    return !this.isChemistry && !this.pair.users[0];
  }
  public get isntEmptyTopMatches(): boolean {
    return !this.isChemistry && this.pair.users[0];
  }
  public get hasntAnyChemistry(): boolean {
    return !this.strongest.length && !this.weakest.length && !this.moderate.length;
  }

  public _chemistryType = [
    {
      value: ChemistryTypes.PAIR_CHEMISTRY,
      name: ChemistryTypes.PAIR_CHEMISTRY,
    },
    {
      value: ChemistryTypes.TOP_MATHES,
      name: ChemistryTypes.TOP_MATHES,
    },
  ] as IDropdownItem[];
  private _selectedUsers: number[] = [];
  private _moderate: any = [];
  private _weakest: any = [];
  private _strongest: any = [];
  private _team: any = [];
  private _pairs: any = [];
  private groupId: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public groupData: GroupDataService,
    private chemistryService: DPairService,
    private userData: UserDataService,
    private reportsService: IzReportsService,
    private toast: ToastService,
  ) {}

  public ngOnInit() {
    this.handleTeam();
    this.setUserByParams(true);
    this.subscriptions.push(
      this.groupData.groupData$.subscribe((g) => {
        this.groupId = g.id;
        const id = get(this.pair, 'users[0].id');

        // update top matches once the group is changed. Need it to support cross-group comparisons
        if (id) {
          this.userData.getTopMatchesList(id, this.groupId).subscribe();
        }
      }),
      this.chemistryService.changeFilter.subscribe((data) => {
        if (data) {
          this.stateLastPair = true;
          this.lastStatePair();
          this.handleTeam();
          if (this.pair.users && (!this.pair.users[0] || !this.pair.users[1])) {
            this._selectedChemistry = ChemistryTypes.PAIR_CHEMISTRY;
            this.updatePair.emit(this.pair.users);
          }
        }
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedUsers) {
      this.setUserByParams();
    }
    if (changes.teams) {
      this.stateLastPair = true;
      this.lastStatePair();
      this.handleTeam();
    }
    if (this.pair.users && !this.pair.users[0]) {
      this._selectedChemistry = ChemistryTypes.PAIR_CHEMISTRY;
    }
  }

  public lastStatePair() {
    this.subscriptions.push(
      this.reportsService.getPair.subscribe((p: any) => {
        if (p && this.stateLastPair) {
          this.pair = p;
          if (this.pair.users[1] && this.pair.users[0]) {
            this.onClear(1);
            this.pair.groups[1] = null;
          }
        }
        this.stateLastPair = false;
      }),
    );
  }

  public toggleChemistryType(type) {
    this._selectedChemistry = type;
    if (!this.pair.users[0] && this.pair.users[1]) {
      this.onSetUser(this.pair.users[1], 0, this.groupId);
      this.userData.getTopMatchesList(this.pair.users[1].id, this.groupId).subscribe();
    }
    this.onClear(1);
  }

  public get isChemistry() {
    return this._selectedChemistry === ChemistryTypes.PAIR_CHEMISTRY;
  }

  public setUserByParams(setLastIndex = false) {
    if (this.selectedUsers.length) {
      this.selectedUsers.forEach((el, id) => {
        if (setLastIndex && el) {
          this.lastDropIndex = id; // FIXME: IN-1297
        }
        this.onSetUser(this.getUser(el), id, this.groupId);
      });
    }
  }

  public clear() {
    this.onSetUser(null, 0, null);
    this.onSetUser(null, 1, null);
  }

  public getUser(id) {
    return this.teams.users.find((el) => String(el.id) === String(id));
  }

  public handleTeam() {
    if (this.teams) {
      this.links = this.teams.links;
      this._team = this.teams.users;
      this._pairs = this.teams.pairs || [];
      this.pair = {
        users: [],
        link: 'base',
      };

      this.moderate = [];
      this.weakest = [];
      this.strongest = [];
      this._pairs.forEach((item) => {
        let _link = this.links.find((link) => {
          return includes(link.users, item[0]) && includes(link.users, item[1]);
        });
        _link = _link ? _link.value || '' : '';
        if (!this[_link]) {
          this[_link] = [];
        }
        this[_link].push({
          link: _link,
          users: [
            this._team.find((people) => {
              return people.id === item[0];
            }),
            this._team.find((people) => {
              return people.id === item[1];
            }),
          ],
        });
      });
      this._moderate = [...this.moderate];
      this._weakest = [...this.weakest];
      this._strongest = [...this.strongest];
      this.length = {
        moderate: this._moderate.length,
        weakest: this._weakest.length,
        strongest: this._strongest.length,
      };
      this.additionalField();
    }

    this.stateLastPair = true;
    this.lastStatePair();
  }

  public findPerfect() {
    if (this.pair.users.filter(Boolean).length === 0) {
      return;
    }

    const id = (this.pair.users[0] && this.pair.users[0].id) || (this.pair.users[1] && this.pair.users[1].id);
    this.userData
      .getTopMatchesList(id, this.groupId)
      .pipe(
        map((data: any) => {
          const result = { ...data };
          if (this.useFilter) {
            const usersMap = this.reportsService.filteredData$
              .getValue()
              .users
              .reduce((res: { [key: number]: boolean }, user) => {
                res[user.id] = true;
                return res;
              }, {});
            result.summary = result.summary ? result.summary.filter((key) => usersMap[key]) : [];
          }

          return result;
        }),
      )
      .subscribe((data) => {
        const id2 = data.summary[0];
        if (id2 === undefined) {
          return;
        }
        const userPair = this.teams.users.filter((user) => user.id === id2)[0] || {};
        const index = this.pair.users[0] ? 1 : 0;
        this.onChangeUser(userPair, index, this.groupId);

        this.pair.link = this.links.find((link) => {
          return includes(link.users, this.pair.users[0].id) && includes(link.users, this.pair.users[1].id);
        }).value;
      });
  }

  public removeUser(arr: string, index: number) {
    this.loadingUpdate = true;
    const i = this._pairs.findIndex((item) => {
      return includes(item, this[arr][index].users[0].id) && includes(item, this[arr][index].users[1].id);
    });
    this._pairs.splice(i, 1);
    this.chemistryService.updatePairs(this._pairs, this.teamId).subscribe(() => {
      this.updatePair.emit(this._pairs);
      this[arr].splice(index, 1);
      this.additionalField(true);
    });
  }

  public revertToList(pair: any) {
    this.pair = {
      ...cloneDeep(pair),
      groups: [this.teamId, this.teamId],
    };
    this.updatePair.emit(this.pair.users);
  }

  public usersStayedEmmit($event) {
    this.usersStayed = $event;
  }

  public saveToPdf(pair) {
    this.isSavePdfLoading = true;
    if (!this.isChemistry && !pair.users[1]) {
      const active = this._selectedUsers;
      this.chemistryService.getTopMatchesPdf(pair.users[0].id, this.groupId, active).subscribe(() => {
        this.isSavePdfLoading = false;
      },
        (error) => {
          this.toast.setMessage(error.statusText, 'danger');
          this.isSavePdfLoading = false;
        });
    } else {
      this.chemistryService.getPairPdf(
        pair.users[0].id,
        pair.groups ? pair.groups[0] : this.teamId,
        pair.users[1].id,
        pair.groups ? pair.groups[1] : this.teamId,
      ).subscribe(
        () => {
          this.isSavePdfLoading = false;
        },
        (error) => {
          this.toast.setMessage(error.statusText, 'danger');
          this.isSavePdfLoading = false;
        });
      this.savePDF.emit(pair);
    }
  }

  public saveToListAvailable() {
    return this.pair.groups[0] === this.pair.groups[1];
  }

  public saveToList() {
    this.loadingUpdate = true;
    let arr;
    const check = this[this.pair.link].some((item) => {
      if (item.users[0].id && item.users[1].id) {
        arr = [item.users[0].id, item.users[1].id];
        return includes(arr, this.pair.users[0].id) && includes(arr, this.pair.users[1].id);
      }
    });

    if (!check) {
      this._pairs.push([this.pair.users[0].id, this.pair.users[1].id]);
      const pair = cloneDeep(this.pair);
      this[this.pair.link].push(pair);
      this['_' + this.pair.link] = cloneDeep(this[this.pair.link]);

      this.chemistryService.updatePairs(this._pairs, this.teamId).subscribe(
        () => {
          this.additionalField(true);
        },
        () => {
          this[this.pair.link].pop();
          this['_' + this.pair.link] = cloneDeep(this[this.pair.link]);
          this.updatePair.emit(this._pairs);
        },
      );
    } else {
      this.loadingUpdate = false;
    }
  }

  public getUserName(user: any = { first_name: '', last_name: '' }) {
    if (user.first_name) {
      return `${user.first_name[0].toUpperCase()}. ${user.last_name}`;
    } else {
      return `${user.first_name[0]}. ${user.last_name}`;
    }
  }

  public onFilter(name: string, arr: string) {
    if (!name) {
      this[arr] = this['_' + arr];
      this.toCorrectLength(arr);
      return this[arr];
    }
    this[arr] = this['_' + arr].filter((item) => {
      const full = `${this.getUserName(item.users[0])} ${this.getUserName(item.users[1])}`;
      return includes(full.toLowerCase(), name.toLowerCase());
    });
    this.toCorrectLength(arr);
  }

  public onSetUser(user: any, index: number, group: number) {
    this.pair.users[index] = user;
    if (!this.pair.groups) {
      this.pair.groups = [];
    }
    this.pair.groups[index] = group;
    if (!this.pair.users[0] || !this.pair.users[1]) {
      this.pair.link = 'base';
    } else {
      const res = this.links.find((link) => {
        return includes(link.users, this.pair.users[0].id) && includes(link.users, this.pair.users[1].id);
      });
      this.pair.link = res && res.value;
    }
    this.reportsService.setPair(this.pair);
  }

  public onChangeUser(user: any, index: number, group: number) {
    this.onSetUser(user, index, group);
    if (!index && user && user.id) {
      this.userData.getTopMatchesList(user.id, this.groupId).subscribe();
    }
    this.updatePair.emit(this.pair.users);
  }

  public onClear(index: number) {
    this.lastDropIndex = index === 1 ? 0 : 1;
    this.isDisplayRelation = false;
    this.onChangeUser(null, index, null);
  }

  public toggleRelation() {
    this.isRelationLoading = true;
    this.isDisplayRelation = !this.isDisplayRelation;

    if (this.isDisplayRelation) {
      this.relationDescription = this.chemistryService.getRelation(this.pair.users[0].id, this.pair.users[1].id);
      this.openRelation.emit([this.pair.users[0].id, this.pair.users[1].id]);
    }
    this.isRelationLoading = false;
  }

  public trackByFn(index) {
    return index;
  }

  public drop(event, index) {
    this.isDisplayRelation = false;
    const user = this.getUser(event.dragData.id);
    this.lastDropIndex = index;
    if (!this.pair.users.filter(Boolean).find((el) => el.id === user.id)) {
      this.onChangeUser(user, index, this.groupId);
    }
  }

  public additionalField(state?) {
    if (state) {
      remove(this.moderate, (currentObject: any) => {
        return currentObject.hideMenu === true;
      });
      remove(this.strongest, (currentObject: any) => {
        return currentObject.hideMenu === true;
      });
      remove(this.weakest, (currentObject: any) => {
        return currentObject.hideMenu === true;
      });
    }
    this.correctListsLength();
    this.loadingUpdate = false;
  }

  public filterUsers(users: User[] = []) {
    this._selectedUsers = l_map(users, (user) => Number(user.id));
  }

  public onClearClick() {
    this._selectedChemistry = ChemistryTypes.PAIR_CHEMISTRY;
  }

  public ngOnDestroy() {
    this.pair = {};
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private toCorrectLength(chemistry: string) {
    let length = this[chemistry].length;
    if (length < 4) {
      length = 4 - length;
      this[chemistry].push(...Array(length).fill(this.mockEmptyObject));
    }
  }

  private correctListsLength() {
    const chemistryKinds = ['strongest', 'moderate', 'weakest'];
    chemistryKinds.forEach((chemistry: string) => {
      this.toCorrectLength(chemistry);
    });
  }
}
