import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastService } from '../../services/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() public message = { body: '', type: '' };
  @Input() public file = { body: '', type: '', size: '', turn: false };

  constructor(private toastService: ToastService) {}

  public cancelRequest() {
    this.toastService.cancelRequest();
  }
}
