import { combineLatest, distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable, Injector } from '@angular/core';
import { Entity } from '@iz_commerce/models/Entity';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '@iz_shared/services/profile';

@Injectable()
export class EntityService {
  protected LS_KEY = 'current-entity';
  protected entity: BehaviorSubject<Entity | undefined>;
  protected entities: BehaviorSubject<Entity[]> = new BehaviorSubject([]);
  protected http: HttpClient = this.inject.get(HttpClient);
  protected profile: ProfileService = this.inject.get(ProfileService);

  constructor(private inject: Injector) {
    this.entity = new BehaviorSubject(this.getFromLocalStorage());
  }

  public setEntity(entity?: Entity) {
    if (entity) {
      this.saveToLocalStorage(entity);
    } else {
      this.removeFromLocalStorage();
    }
    this.entity.next(entity); // Hack to work with
    // FIXME: This crutch is for ensuring the compatibility of unrefined functionality. Remove later!
    /*this.sportsDataService.fire('change-current-entity', entity);*/
  }

  public getEntityObs() {
    return this.entity.asObservable().pipe(distinctUntilChanged<Entity | undefined>((o, n) => o === n));
  }

  public get entityId() {
    return this.getEntityObs().pipe(map<Entity | undefined, number | null>((e) => {
      if (e) {
        return e.id;
      } else {
        return null;
      }
    }));
  }

  public entityOrUserEntity() {
    return this.entityId.pipe(combineLatest(this.profile.getUser()), map(([entityId, user]: any) => {
      return entityId ? entityId : user.entity;
    }));
  }

  public get currentEntity() {
    const entity = this.entity.getValue();
    if (entity) {
      return entity.id;
    } else {
      return void 0;
    }
  }

  public getEntitiesObs() {
    return this.entities.asObservable();
  }

  public fetchEntities() {
    return this.http
      .get('/api/entities/', {
        params: {
          disable_pagination: 'true',
        },
      })
      .subscribe((data) => {
        this.entities.next((data as any).results);
      });
  }

  protected saveToLocalStorage(entity: Entity) {
    localStorage.setItem(this.LS_KEY, JSON.stringify(entity));
  }

  protected getFromLocalStorage() {
    return JSON.parse(localStorage.getItem(this.LS_KEY)) as Entity | null;
  }

  protected removeFromLocalStorage() {
    localStorage.removeItem(this.LS_KEY);
  }
}
