import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
/* Main component */
import { AppComponent } from './app.component';
/* Modules */
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PagesModule } from './pages';
import { IzsCommonModule } from './common';
import { AppRoutingModule } from './app.routing';
import { environment } from '../environments/environment';
import { IzsSharedServicesModule } from '@iz_sports/services';
import { IzCommonModule } from '@iz_shared/iz-common.module';
import { IzServicesModule } from '@iz_shared/services';
import { IzValidatorsModule } from '@iz_shared/validators';
import { IzTranslationModule } from '@iz_shared/iz-translation.module';
import { ENV_CONF_TOKEN } from '@iz_shared/iz-tokens.di';

@NgModule({
  declarations: [AppComponent],
  imports: [
    PagesModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    IzCommonModule,
    IzsCommonModule,
    NgScrollbarModule,
    HttpClientModule,
    AppRoutingModule,
    IzServicesModule,
    IzValidatorsModule,
    IzTranslationModule,
    IzsSharedServicesModule,
    BrowserAnimationsModule,
    AngularSvgIconModule.forRoot(),
    DeviceDetectorModule,
  ],
  providers: [
    {
      provide: ENV_CONF_TOKEN,
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
