import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PortalService } from '../portal.service';
import { Subscription } from 'rxjs/';

@Component({
  selector: 'app-portal-point',
  templateUrl: './portal-point.component.html',
  styleUrls: ['./portal-point.component.scss'],
})
export class PortalPointComponent implements OnInit, OnDestroy {
  @Input() public name: string = '';
  public refList: ElementRef[] = [];
  private subscription: Subscription;

  constructor(private portalService: PortalService) {
  }
  public ngOnInit() {
    if (this.name) {
      this.subscription = this.portalService.getPortalObs(this.name).subscribe((refs) => {
        this.refList = refs;
      });
    }
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
