import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldsType, ModelForm } from '@iz_shared/models/FormModel';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'iz-form-dynamic-content',
  templateUrl: './form-dynamic-content.component.html',
  styleUrls: ['./form-dynamic-content.component.scss'],
  host: {
    '[class]': "''",
  },
})
export class FormDynamicContentComponent implements OnInit {
  @Input() public formModel: ModelForm = { orders: [], fields: [] };
  @Input() public submitted: boolean = false;
  @Input() public form: FormGroup = new FormGroup({});
  @Input() public serverError: { [key: string]: string[] } = {};
  @Input() public class: string = '';
  @Output() public onDropdownChange = new EventEmitter<string>();

  public orderedFields: FieldsType[] = [];

  constructor() {}

  public ngOnInit() {
    this.orderedFields = ModelForm.getOrderedFields(this.formModel);
  }

  public onInput(event: Event, field: FieldsType) {
    const controller = this.form.controls[field.key];
    if (field.setter) {
      field.setter(controller, event);
    }
  }

  public getErrors(field: FieldsType) {
    const serverError = this.serverError[field.key] || [];
    return { ...field.errors, server_error: serverError.join(', ') };
  }
}
