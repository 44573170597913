import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[highlight]',
})
export class HighlightDirective implements AfterViewInit {
  @Input() public highlight: string;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  public ngAfterViewInit() {
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.replace(this.el.nativeElement.innerHTML, this.highlight || ''),
    );
  }

  private replace(txt: string, search: string) {
    const searchRgx = new RegExp('(' + search + ')', 'gi');

    return txt.replace(searchRgx, `<b>$1</b>`);
  }
}
