import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'input[izInput]',
  template: '',
  styleUrls: ['./input.component.scss'],
  host: {
    '[class.default]': 'true',
    '[class.white]': "theme === 'white'",
    '[attr.disabled]': '(disabled || loading) ? true : null',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  @Input() public theme?: string;
  @Input() public loading: boolean = false;
  @Input() public disabled: boolean = false;

  constructor() {}

}
