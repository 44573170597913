import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[izWhitespace][formControlName],[izWhitespace][formControl],[izWhitespace][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => WhitespaceDirective), multi: true }],
})
export class WhitespaceDirective implements Validator {
  constructor() {}

  public registerOnValidatorChange(fn: () => void): void {}

  public validate(c: AbstractControl): ValidationErrors | null {
    return c.value && c.value.toString().trim() === '' ? { whitespace: true } : null;
  }
}
