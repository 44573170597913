import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'iz-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
  @Input() public form: FormGroup = new FormGroup({});
  @Input() public key: string = '';
  @Input() public messages: { [key: string]: string } = {};
  @Input() public useI18n = false;

  @HostBinding('class.info')
  @Input() public onlyInfo = false;

  constructor() {
  }

  public get control() {
    return this.form.controls[this.key];
  }

  public get showError() {
    if (this.control) {
      return !this.control.valid;
    }
    return false;
  }

  public get errorMessages() {
    if (this.control && this.control.errors) {
      const errors = Object.keys(this.control.errors);
      return errors.map((key) => this.messages[key]).filter(Boolean);
    }
    return [];
  }
}
