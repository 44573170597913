import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { IDropdownItem } from '@iz_shared/components/smart-dropdown/smart-dropdown.component';
import * as uuidv1 from 'uuid/v1';

@Component({
  selector: 'iz-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
})
export class ButtonToggleComponent implements OnInit {
  @Input() public items: IDropdownItem[] = [];
  @Input() public value?: any;

  @HostBinding('class.split')
  @Input() public split = false;

  @Output() public onSelect = new EventEmitter<any>();

  public _group_hash = uuidv1();

  constructor() {
  }

  public ngOnInit() {
  }

  public _select(value: any) {
    if (this.value !== value) {
      this.value = value;
      this.onSelect.emit(this.value);
    }
  }

  public _hash(item: IDropdownItem) {
    return `${item.name}`;
  }
}
