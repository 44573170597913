import {FormGroup} from '@angular/forms';
import { Injectable } from "@angular/core";

@Injectable()
export class EqualPasswordsValidator {

  public static validate(firstField, secondField) {
    return (c: FormGroup) => {
      c.controls[secondField].markAsPristine();
      return (c.controls && c.controls[firstField].value === c.controls[secondField].value)
        ? null : {verifyPassword: {valid: false}}
        && c.controls[secondField].setErrors({
        invalidPassword: true,
      });
    };
  }
}
