import { Injectable, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { User } from '@iz_shared/models/User';
import { UserDataService } from '@iz_shared/views/dashboard/services/user-data';
import { ModelForm } from '@iz_shared/models/FormModel';
import { ProfileService } from '@iz_shared/services/profile';
import { Group } from '@iz_commerce/models/Group';
import { orderBy } from 'lodash-es';
import { UserSystemRoles as CommerceUserSystemRoles } from '@iz_commerce/models/User';
import { UserSystemRoles as SportsUserSystemRoles } from '@iz_sports/models/User';

@Injectable()
export class IzUserFormsService {
  public base_error = {
    required: 'This field is required',
    server_error: '',
    minlength: 'login.minlength'
  };

  public password_error = {
    required: 'This field is required',
    server_error: '',
    minlength: 'login.passwordReq.minlength',
    maxlength: 'login.passwordReq.maxlength',
    pattern: 'login.passwordReq.pattern'
  };
  public static projectRoleNameMap: {[key: string]: { displayedRole: string, systemRole: string }} = {
    'superadmin': {
      displayedRole: 'Super Admin',
      systemRole: CommerceUserSystemRoles.SUPER_ADMIN,
    },
    'entityadmin': {
      displayedRole: 'Entity Admin',
      systemRole: CommerceUserSystemRoles.ENTITY_ADMIN,
    },
    'divisionmanager': {
      displayedRole: 'Division Manager',
      systemRole: CommerceUserSystemRoles.DIVISION_MANAGER,
    },
    'departmentmanager': {
      displayedRole: 'Department Manager',
      systemRole: CommerceUserSystemRoles.DEPARTMENT_MANAGER,
    },
    'workgroupmanager': {
      displayedRole: 'Workgroup Manager',
      systemRole: CommerceUserSystemRoles.WORKGROUP_MANAGER,
    },
    'employee': {
      displayedRole: 'Employee',
      systemRole: CommerceUserSystemRoles.USER,
    },
    'applicant': {
      displayedRole: 'Applicant',
      systemRole: CommerceUserSystemRoles.USER,
    },
    'director': {
      displayedRole: 'Director',
      systemRole: SportsUserSystemRoles.DIRECTOR,
    },
    'subdirector': {
      displayedRole: 'Subdirector',
      systemRole: SportsUserSystemRoles.SUBDIRECTOR,
    },
    'headcoach': {
      displayedRole: 'Head Coach',
      systemRole: SportsUserSystemRoles.HEADUSER,
    },
    'coach': {
      displayedRole: 'Coach',
      systemRole: SportsUserSystemRoles.HEADUSER,
    },
    'player': {
      displayedRole: 'Player',
      systemRole: SportsUserSystemRoles.USER,
    }
  };

  protected userData: UserDataService = this.injector.get(UserDataService);
  protected profile: ProfileService = this.injector.get(ProfileService);
  public static regexPassword: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]{8,64}$/; // IZ-103
  // string version of regexPassword if needed
  // tslint:disable-next-line:max-line-length
  // public static regexPassword: string = '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[!\"#$%&\'()*+,\\-./:;<=>?@\\[\\\\\\]^_\`{|}~])[A-Za-z\\d!\"#$%&\'()*+,\\-./:;<=>?@\\[\\\\\\]^_\`{|}~]{8,64}$'; // IZ-103

  protected _base = new ModelForm(
    [
      ModelForm.Field({
        controlType: 'options',
        key: 'title',
        label: 'dashboard.forms.base.title',
        i18n: true,
        validators: [Validators.required],
        options: [
          { name: 'Mr.', value: 'mr' },
          { name: 'Mrs.', value: 'mrs' },
          { name: 'Miss', value: 'miss' },
          { name: 'Ms.', value: 'ms' },
        ],
        errors: this.base_error,
      }),
      ModelForm.Field({
        controlType: 'input',
        type: 'text',
        key: 'first_name',
        label: 'dashboard.forms.base.first_name',
        validators: [Validators.required],
        errors: this.base_error,
        i18n: true,
      }),
      ModelForm.Field({
        controlType: 'input',
        type: 'text',
        key: 'last_name',
        label: 'dashboard.forms.base.last_name',
        validators: [Validators.required],
        errors: this.base_error,
        i18n: true,
      }),
      ModelForm.Field({
        controlType: 'input',
        type: 'email',
        key: 'email',
        label: 'dashboard.forms.base.email',
        validators: [Validators.required, Validators.email],
        errors: {
          ...this.base_error,
          email: 'Please, enter valid email',
        },
        i18n: true,
      }),
      ModelForm.Field({
        controlType: 'input',
        type: 'text',
        key: 'username',
        label: 'dashboard.forms.base.username',
        validators: [Validators.required, Validators.minLength(6)],
        errors: this.base_error,
        i18n: true,
      }),
      ModelForm.Field({
        controlType: 'input',
        type: 'text',
        key: 'password',
        label: 'dashboard.forms.base.password',
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          Validators.pattern(IzUserFormsService.regexPassword)
        ],
        errors: this.password_error,
        i18n: true,
      }),
    ],
    ['title', 'first_name', 'last_name', 'email', 'username', 'password'],
  );

  protected _with_activity = ModelForm.extend(this._base, [
    {
      field: ModelForm.Field({
        controlType: 'toggle',
        key: 'is_active',
        label: 'dashboard.forms.with_activity.is_active',
        value: true,
        errors: this.base_error,
        i18n: true,
      }),
    },
  ]);

  protected _admin = ModelForm.extend(this._with_activity, [
    {
      field: ModelForm.Field({
        controlType: 'options',
        key: 'role',
        i18n: true,
        label: 'dashboard.forms.with_permission.role',
        validators: [Validators.required],
        options: [    // TODO IZ-150
          { name: 'Super Admin', value: 'superadmin'},
          { name: 'Entity Admin', value: 'entityadmin' },
          { name: 'Division Manager', value: 'divisionmanager' },
          { name: 'Department Manager', value: 'departmentmanager' },
          { name: 'Workgroup Manager', value: 'workgroupmanager' },
          { name: 'Employee', value: 'employee' },
          { name: 'Applicant', value: 'applicant' }
        ],
        errors: this.base_error,
        hide: (form) => {
          return this.profile.getUser().pipe(
            share(),
            map((me: User) => {
              return me.username === form.value.username;
            }),
          );
        },
      }),
      afterField: '*',
    },
    {
      field: ModelForm.Field({
        controlType: 'options',
        key: 'division',
        i18n: true,
        label: 'dashboard.forms.with_permission.division',
        validators: [Validators.required],
        options: [],
        errors: this.base_error,
        hide: (form) => {
          return this.profile.getUser().pipe(
            share(),
            map((me: User) => {
              return [
                'entityadmin', IzUserFormsService.projectRoleNameMap.entityadmin.systemRole.toString(),
                'superadmin', IzUserFormsService.projectRoleNameMap.superadmin.systemRole.toString()
                ].includes(form.value.role);
            }),
          );
        },
      }),
      afterField: 'role',
    },
    {
      field: ModelForm.Field({
        controlType: 'options',
        key: 'department',
        i18n: true,
        label: 'dashboard.forms.with_permission.department',
        validators: [Validators.required],
        options: [],
        errors: this.base_error,
        hide: (form) => {
          return this.profile.getUser().pipe(
            share(),
            map((me: User) => {
              return [
                'divisionmanager', IzUserFormsService.projectRoleNameMap.divisionmanager.systemRole.toString(),
                'entityadmin', IzUserFormsService.projectRoleNameMap.entityadmin.systemRole.toString(),
                'superadmin', IzUserFormsService.projectRoleNameMap.superadmin.systemRole.toString()
              ].includes(form.value.role);
            }),
          );
        },
      }),
      afterField: 'division',
    },
    {
      field: ModelForm.Field({
        controlType: 'options',
        key: 'workgroup',
        i18n: true,
        label: 'dashboard.forms.with_permission.workgroup',
        validators: [Validators.required],
        options: [],
        errors: this.base_error,
        hide: (form) => {
          return this.profile.getUser().pipe(
            share(),
            map((me: User) => {
              return [
                'departmentmanager', IzUserFormsService.projectRoleNameMap.departmentmanager.systemRole.toString(),
                'divisionmanager', IzUserFormsService.projectRoleNameMap.divisionmanager.systemRole.toString(),
                'entityadmin', IzUserFormsService.projectRoleNameMap.entityadmin.systemRole.toString(),
                'superadmin', IzUserFormsService.projectRoleNameMap.superadmin.systemRole.toString()
              ].includes(form.value.role);
            }),
          );
        },
      }),
      afterField: 'department',
    },
  ]);

  constructor(protected injector: Injector) {}

  public get base() {
    return this._base;
  }

  public get with_activity() {
    return this._with_activity;
  }

  public get admin() {
    return this._admin;
  }

  public get superAdmin() {
    return this._with_activity;
  }

  public changeToEdit(form: FormGroup, formModel: ModelForm, data: any) {
    ModelForm.changeField(form, formModel, 'password', {
      placeholder: '*******',
      disable_validators: true // IZ-129
    });
    ModelForm.setFormValue(form, formModel, data);
  }

  public populateDropdowns(form: FormGroup, formModel: ModelForm, groups: Group[] = [], level: string) {
    ModelForm.changeField(form, formModel, level, {
      options: orderBy(groups, ['name']).map((g) => ({ value: g.id, name: g.name }))
    });
  }

  public changeToAdd(form: FormGroup, formModel: ModelForm, data: any) {
    ModelForm.changeField(form, formModel, 'password', {
      placeholder: ' ',
      disable_validators: false
    });
    ModelForm.setFormValue(form, formModel, data);
  }

  public changeRoleOptions(form: FormGroup, formModel: ModelForm, data: any, roleOptions: any[]) {
    ModelForm.changeField(form, formModel, 'role', {
      options: roleOptions
    });
    ModelForm.setFormValue(form, formModel, data);
  }

  public setToSingleRole(form: FormGroup, formModel: ModelForm, data: any) {
    ModelForm.changeField(form, formModel, 'role', { hide: () => {
      return of(true);
    }});
    ModelForm.setFormValue(form, formModel, data);
  }

  public hideFields(form: FormGroup, formModel: ModelForm, data: any[]) {
    formModel.fields.forEach((field) => {
      if (data.includes(field.key)) {
        ModelForm.changeField(form, formModel, field.key, { hide: () => {
            return of(true);
        }});
      }
    });
  }

  public disableValidatorsOnHiddenFields(form: FormGroup, formModel: ModelForm, data: any[]) {
    formModel.fields.forEach((field) => {
      if (data.includes(field.key)) {
        field.hide(form).subscribe((hidden: any) => {
          if (hidden === true) {
            form.value[field.key] = null;
            form.controls[field.key].setValue(null);
            ModelForm.changeField(form, formModel, field.key, { disable_validators: true });
          } else {
            ModelForm.changeField(form, formModel, field.key, { disable_validators: false });
          }
        });
      }
    });
  }

  public disableValidatorsOnFields(form: FormGroup, formModel: ModelForm, data: any[], condition: boolean) {
    formModel.fields.forEach((field) => {
      if (data.includes(field.key)) {
        ModelForm.changeField(form, formModel, field.key, { disable_validators: condition });
      }
    });
  }

  public setErrorFromServer(form: FormGroup, error: { [key: string]: string[] }) {
    Object.keys(error).forEach((key) => {
      const control = form.controls[key];
      if (control) {
        control.setErrors({ server_error: true });
      }
    });
  }
}
