import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IProtalsBase<T> {
  [key: string]: T; // BehaviorSubject<ElementRef[]>;
}

@Injectable()
export class PortalService {
  private portalsPointSubject: IProtalsBase<BehaviorSubject<ElementRef[]>> = {};
  private portalsPointList: IProtalsBase<ElementRef[]> = {};

  constructor() {}

  public addRef(pointName: string, ref: ElementRef) {
    const portalSubject = this.getOrCreateSubject(pointName);
    portalSubject.next(this.addAndGetPortalEl(pointName, ref));
  }

  public removerRef(pointName: string, ref: ElementRef) {
    const portalSubject = this.getOrCreateSubject(pointName);
    portalSubject.next(this.removeAndGetPortalEl(pointName, ref));
  }

  public getPortalObs(name: string) {
    return this.getOrCreateSubject(name);
  }

  private getOrCreateSubject(name: string) {
    if (!this.portalsPointSubject[name]) {
      this.portalsPointSubject[name] = new BehaviorSubject<ElementRef[]>([]);
    }
    return this.portalsPointSubject[name];
  }

  private getPortalList(name: string) {
    return this.portalsPointList[name] || [];
  }

  private addAndGetPortalEl(name: string, ref: ElementRef): ElementRef[] {
    this.portalsPointList[name] = [...this.getPortalList(name), ref];
    return this.portalsPointList[name];
  }

  private removeAndGetPortalEl(name: string, ref: ElementRef): ElementRef[] {
    this.portalsPointList[name] = this.getPortalList(name)
      .filter((r) => r.nativeElement !== ref.nativeElement);
    return this.portalsPointList[name];
  }
}
