import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProfileService } from '@iz_shared/services/profile';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent {
  public isConfirmLoading: boolean = false;
  public isLoading: boolean = false;
  public accept: boolean = false;
  public terms: any;
  @Input() public close: boolean = false;
  @Output() public onClose = new EventEmitter();

  constructor(private http: HttpClient,
              private router: Router,
              private service: ProfileService,
              private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe((data) => {
      this.accept = data.accept;
    });

    this.terms = this.http.get('/static/terms_of_service.html', {
      responseType: 'text',
    });
  }

  public onConfirm(confirm) {
    if (confirm) {
      this.isConfirmLoading = true;
      this.router.navigate(['/terms-of-service-confirm']);
      this.isConfirmLoading = false;
    } else {
      this.isLoading = true;
      localStorage.clear();
      this.service.setUser(null);
      this.router.navigate(['/login']);
      this.isLoading = false;
    }
  }
}
