import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from './form-field.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [FormFieldComponent, FormLabelComponent, FormErrorComponent],
  exports: [FormFieldComponent, FormLabelComponent, FormErrorComponent],
})
export class FormFieldModule {}
