import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

/* Vendor */
import { has, map } from 'lodash-es';

@Component({
  selector: 'app-survey-question-choice',
  templateUrl: './survey-question-choice.component.html',
  styleUrls: ['./survey-question-choice.component.scss'],
})
export class SurveyQuestionChoiceComponent implements OnChanges {
  public checkbox: any = 'assets/images/common/empty.svg';
  public nullCheckbox: any = 'assets/images/common/empty.svg';
  @Input() public items: any[] = [];
  @Output() public onSelect = new EventEmitter<string>();
  public selected: boolean = false;

  constructor() {
  }

  public ngOnChanges() {
    this.items = map(this.items, (item) => {
      if (has(item, '_selected')) {
        return item;
      }

      return {
        value: item,
        _selected: false,
      };
    });
  }

  public selectValue(selected) {
    selected._selected = !selected._selected;
    setTimeout(() => {
      this.onSelect.emit(selected.value);
    }, 300);
  }

  public trackByFn(index, _) {
    return index;
  }
}
